import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import EndingComponent from '../Components/EndingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const VirginiaEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Virginia Change Endorsement</Text>
      </View>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS</Text>, Paragraph 8,
            Canceling the Policy, to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>8. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Canceling the Policy</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>You</Text> may cancel <Text style={styles.boldText}>your</Text> policy at any time by telling
                us in writing in advance, through <Text style={styles.boldText}>our Aviation Managers</Text>, of the date{' '}
                <Text style={styles.boldText}>you</Text> want <Text style={styles.boldText}>your</Text> coverage to end. If{' '}
                <Text style={styles.boldText}>you</Text> cancel the policy, <Text style={styles.boldText}>we</Text> will return 90% of the
                unearned premium <Text style={styles.boldText}>you</Text> have paid.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> or <Text style={styles.boldText}>our Aviation Managers</Text> can cancel this policy
                by mailing or delivering to you written notice of cancellation, stating the reason for cancellation, at least:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>15 days before the effective date of cancellation if we cancel for nonpayment of premium; or</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>45 days before the effective date of cancellation if we cancel for any other reason.</Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will send written notice by registered or certified mail or deliver written notice
                to <Text style={styles.boldText}>your</Text> last mailing address known to us. If notice is mailed, proof of mailing will be
                sufficient proof of notice.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>Notice of cancellation will state the effective date of cancellation. The policy period will end on that date.</Text>
            </View>
            <EndingComponent extra={false} />
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>Nonrenewal or Material Change</Text>
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> elect not to renew this policy, make material change in any policy condition or
                limit of coverage, <Text style={styles.boldText}>we</Text> or our <Text style={styles.boldText}>our Aviation Managers</Text>{' '}
                will mail or deliver written notice of non-renewal to the first Named Insured stating the reason for nonrenewal, at least:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>15 days before the expiration date if the non renewal is due to nonpayment of premium; or</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>45 days before the expiration date if the non-renewal is for any other reason.</Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> or our <Text style={styles.boldText}>our Aviation Managers</Text> will send written
                notice by registered or certified mail or deliver written notice of nonrenewal to <Text style={styles.boldText}>your</Text>{' '}
                last mailing address known to us.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                The proof of mailing or delivering notice of nonrenewal, cancellation or change by us or{' '}
                <Text style={styles.boldText}>our Aviation Managers</Text> to the first named insured shown on the Coverage Identification
                Page will be sufficient proof of notice to all persons or organizations covered by this policy.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={1} form={formNumber}/>
    </Page>
  </>
);

export default VirginiaEndorsement;
