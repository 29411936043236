import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
// import EndingComponent from '../Components/EndingComponent';
import BeginningComponent from '../Components/BeginingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const LouisianaEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Louisiana Change Endorsement</Text>
      </View>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS,</Text> Paragraph 2, Our
            Obligations and <Text style={styles.boldText}>Your</Text> Duties, to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>2. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                <Text style={styles.boldText}>Our</Text> Obligations and <Text style={styles.boldText}>Your</Text> Duties
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> agree to provide coverage to <Text style={styles.boldText}>you</Text> and
                <Text style={styles.boldText}>someone we protect</Text> if <Text style={styles.boldText}>you</Text> pay the premium and
                comply with the policy requirements. <Text style={styles.boldText}>Your</Text> insolvency or bankruptcy will not affect{' '}
                <Text style={styles.boldText}>our</Text>
                obligation to pay damage claims for <Text style={styles.boldText}>bodily injury</Text> or{' '}
                <Text style={styles.boldText}>property damage</Text> which would otherwise be covered by{' '}
                <Text style={styles.boldText}>your</Text> policy.
              </Text>
            </View>
          </View>
        </View>
        <BeginningComponent intro={false} />
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Cancellation of policies in effect for fewer than 60 days which are not renewals</Text>
                </View>
                <View style={styles.listItemParagraph}>
                  <Text>
                    If this policy has been in effect for fewer than 60 days and is not a renewal of a policy{' '}
                    <Text style={styles.boldText}>we</Text> issued, <Text style={styles.boldText}>we</Text> may cancel this policy for any
                    reason, subject to the following:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Cancellation for nonpayment of premium</Text>
                    </View>
                    <View style={styles.listItemParagraph}>
                      <Text>
                        <Text style={styles.boldText}>We</Text> may cancel this policy by mailing or delivering to the first Named Insured
                        written notice of cancellation at least 10 days before the effective date of cancellation.
                      </Text>
                    </View>
                  </View>
                  <View>
                    <View>
                      <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                        <Text>Cancellation for any other reason</Text>
                      </View>
                      <View style={styles.listItemParagraph}>
                        <Text>
                          <Text style={styles.boldText}>We</Text> may cancel this policy by mailing or delivering to the first Named Insured
                          written notice of cancellation at least 60 days before the effective date of cancellation.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Cancellation of renewal policies and new policies in effect for 60 days or more</Text>
                </View>
                <View style={styles.listItemParagraph}>
                  <Text>
                    If this policy has been in effect for 60 days or more, or is a renewal of a policy{' '}
                    <Text style={styles.boldText}>we</Text> issued, <Text style={styles.boldText}>we</Text> may cancel only for one or more
                    of the following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Nonpayment of premium;</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Fraud or material misrepresentation made by <Text style={styles.boldText}>you</Text> or with{' '}
                        <Text style={styles.boldText}>your</Text> knowledge with the intent to deceieve in obtaining the policy, continuing
                        the policy, or in presenting a claim under the policy;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Activities or omissions by <Text style={styles.boldText}>you</Text> which change or increase any hazard insured
                        against;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Change in teh risk which increases the risk of loss after <Text style={styles.boldText}>we</Text> issued or renewed
                        this policy including an increase in exposure due to regulation, legislation, or court decision;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(5) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Determination by the Commissioner of Insurance that the continuation of this policy would jeopardize{' '}
                        <Text style={styles.boldText}>our</Text> solvency or would place <Text style={styles.boldText}>us</Text> in
                        violation of the insurance laws of this or any other state;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(6) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>The insured`s violation or breach of any policy terms or conditions; or</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(7) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Any other reasons that are approved by the Commissioner of Insurance.</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will mail or deliver written notice of cancellation to the first Named Insured and
                to <Text style={styles.boldText}>someone we protect</Text> at least:
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={3} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    10 days before the effective date of cancellation if <Text style={styles.boldText}>we</Text> cancel for nonpayment of
                    premium; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    30 days before the effective date of cancellation if <Text style={styles.boldText}>we</Text> cancel for a reason
                    described in Paragraphs b. (2) through (7) above.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Proof of mailing or delivery of the notice is sufficient proof of notice. <Text style={styles.boldText}>We</Text> will
                compute the premium earned by <Text style={styles.boldText}>us</Text> based on teh percentage of the original policy period
                that <Text style={styles.boldText}>we</Text> provided coverage.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will return to <Text style={styles.boldText}>you</Text> any premium that{' '}
                <Text style={styles.boldText}>you</Text> have paid that <Text style={styles.boldText}>we</Text> have not earned, bu making
                the refund is not a condition of cancellation.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text style={styles.boldText}>Non-Payment of Premium</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If this policy is cancelled, <Text style={styles.boldText}>we</Text> will return any premium refund due, subject to the
                following. Cancellation will be effective even if <Text style={styles.boldText}>we</Text> have not made or offered a refund.
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If <Text style={styles.boldText}>your</Text> premium check or other negotiable instrument is returned to{' '}
                    <Text style={styles.boldText}>us</Text> or <Text style={styles.boldText}>our</Text> agent or a premium finance company
                    because it is uncollectible for any reason, <Text style={styles.boldText}>we</Text> may cancel the policy subject to
                    Paragraphs a.(1) and b.(1).
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>We</Text> may cancel the policy effective from the date the premium payment was due, by
                    sending <Text style={styles.boldText}>you</Text> written notice by certified mail or delivering such notice to{' '}
                    <Text style={styles.boldText}>you</Text> within 10 days of the date that <Text style={styles.boldText}>we</Text> recieve
                    notice of the returned check or negotiable instrument.
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    The cancellation notice will also advise <Text style={styles.boldText}>you</Text> that the policy will be reinstated
                    effective from the date the premium payment was due, if <Text style={styles.boldText}>you</Text> present to{' '}
                    <Text style={styles.boldText}>us</Text> a cashier`s check or money order for the full amount of the returned check or
                    other negotiable instrument within 10 days of the date that the cancellation notice was mailed.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text style={styles.boldText}>Premium Refund</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If this policy is cancelled, <Text style={styles.boldText}>we</Text> will return any premium refund due, subject to the
                following. The cancellation will be effective event if <Text style={styles.boldText}>we</Text> have not made or offered a
                refund.
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If <Text style={styles.boldText}>we</Text> cancel, the refund will be pro rata.
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If the first Named Insured cancels, the refund may be less than pro rata, and will be returned within 30 days after the
                    effective date of cancellation.
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>We</Text> will send the refund to the first Named Insured unless Paragraph d. or e.
                    applies.
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>d. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If <Text style={styles.boldText}>we</Text> cancel based on this endorsement, <Text style={styles.boldText}>we</Text>{' '}
                    will return the premium due, if any, within 10 days after the expiration of the 10-day period. If the policy was
                    financed by a premium finance company, or if payment was advanced by the insurance agent,{' '}
                    <Text style={styles.boldText}>we</Text> will send the return premium directly to such payor.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text style={styles.boldText}>Nonrenewal</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> decide not to renew this policy, <Text style={styles.boldText}>we</Text> will
                mail or deliver written notice of nonrenewal to the first nonrenewal to the first Named Insured, at least 60 days before its
                expiration date, or its anniversary date if it is a policy written for a term of more than one year or with no fixed
                expiration date.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> decide to increase any rate, change deductible, reduce limits or reduce coverage
                a 45 days notice of this change <Text style={styles.boldText}>we</Text>
                will mail or deliver written notice to the first Named Insured, at least 30 days before its expiration date, or its
                anniversary date if its is a policy written for a term of more than one year or with no expiration date.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> need not mail or deliver this notice if:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>We</Text> or another company within <Text style={styles.boldText}>our</Text> insurance
                    group have offered to issue a renewal policy; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>You</Text> have obtained replacement coverage or have agreed in writing to obtain
                    replacement coverage.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Any notice of nonrenewal or change in coverage will be mailed or delivered to the first Named Insured at the last mailing
                address known to <Text style={styles.boldText}>us</Text>. If notice is mailed, proof of mailing will be sufficient proof of
                notice. Such notice to the insured shall include the insured`s loss run information for the period the policy has been in
                force within, but not to exceed, the last three years of coverage.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement changes Part One. GENERAL PROVISIONS AND CONDITIONS, Paragraph 13, Legal Action Against{' '}
            <Text style={styles.boldText}>Us</Text>, to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>13. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                Legal Action Against <Text style={styles.boldText}>us</Text>
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={3} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.listItemParagraph}>
              <Text>
                A person or organization may bring a suit against <Text style={styles.boldText}>us</Text> including, but not limited to, a
                suit to recover on an agreed settlement or on a dinal judgment against an insured; but{' '}
                <Text style={styles.boldText}>we</Text> will not be liable for damages that are not payable under the terms of this Policy
                or that are ine excess of the applicable limit of insurance. An agreed settlement means a settlement and release of
                liability signed by <Text style={styles.boldText}>us</Text>, the insured and the claimants`s legal representative.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>This endorsement adds Part One. GENERAL PROVISIONS AND CONDITIONS, PARAGRAPH 16, Arbitration to the policy:</Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>16. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Arbitration</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> and the insured do not agree whether coverage is provided under this Policy for a
                claim made against the insured, both parties may, by mutual consent, agree in writing to arbitration of the disagreement. In
                this event, each party will select an arbitrator. The two arbitrations will select a third. If they cannot agree within 30
                days, both parties must request that selection be made by a judge of a court having jurisdiction.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>Each party will:</Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Pay the expenses it incurs; and</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Bear the expenses of the third arbitrator equally.</Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Unless both parties agree otherwise, arbitration will take place in teh parish in which the address shown in the Coverage
                Indetification Page is located. Local rules of law as to produce nad evidence will apply.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS,</Text> Paragraph 1, What{' '}
            <Text style={styles.boldText}>We</Text> Cover to add the following:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>1. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                What <Text style={styles.boldText}>We</Text> Cover
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will pay for the damages that <Text style={styles.boldText}>you</Text>, or
                <Text style={styles.boldText}>someone we protect,</Text> are legally required to pay for{' '}
                <Text style={styles.boldText}>bodily injury</Text> or <Text style={styles.boldText}>property damage</Text>
                to <Text style={styles.boldText}>others</Text> caused by an <Text style={styles.boldText}>occurence</Text>.{' '}
                <Text style={styles.boldText}>We</Text> will pay these damages within 30 days after written agreement of settlement.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part three. LIABILITY TO OTHERS,</Text> Paragraph 4, What is Not Covered,
            j. Noise, Pollution, Electrical Or Other Interference to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>j. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text style={{ fontStyle: 'italic' }}>Noise, Pollution, Electrical Or Other Interference</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>Bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> that is directly
                or indirectly caused by or arises out of:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Noise vibration or sonic boom;</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text style={styles.boldText}>Pollution incident</Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>Pollution incident</Text> means emission, discharge, release, or escape of pollutants into or
                upon land, the atmosphere, or any watercourse or body water provided that such emission, discharge, release or escape
                results in <Text style={styles.boldText}>environmental damage</Text>. Pollutants mean any solid, liquid, gaseous or thermal
                irritant or contaminant, including smoke, vapor, soot, fumes, acids, alkalis, chemicals and waste.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>Environmental damage</Text> means the injurious presence in or upon the land, the atmosphere
                or any watercourse or body of water of (1) solid liquid, gaseous or thermal irritant or contaminant, irritants, or
                pollutants or (2) electrical and/or magnectic interference that causes interference with the use of property unless by or
                resulting from an <Text style={styles.boldText}>occurence</Text>.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={3} endPage={3} form={formNumber}/>
    </Page>
  </>
);

export default LouisianaEndorsement;
