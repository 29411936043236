import { Page, View, Text, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import { PageFooter } from '../../PageFooter';
import { styles } from '../../Styles';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' }
  ]
});

export const Page2 = ({formNumber}) => (
  <Page size="letter" style={styles.page}>
    <View style={styles.content}>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                incurred in operating the <Text style={styles.boldText}>aircraft</Text> provided such reimbursement is limited to expenses
                allowable, if any, to a Private Pilot under Part 61 of the <Text style={styles.boldText}>FAA</Text> regulations.
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                s.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Instruction and/or Rental</Text> means use of the{' '}
                <Text style={styles.boldText}>aircraft</Text> by <Text style={styles.boldText}>you</Text> for the commercial instruction of
                or rental to <Text style={styles.boldText}>others</Text> for their
                <Text style={styles.boldText}>pleasure and business</Text> purposes. <Text style={styles.boldText}>You</Text> or{' '}
                <Text style={styles.boldText}>someone we protect</Text> may also use the <Text style={styles.boldText}>aircraft</Text> for{' '}
                <Text style={styles.boldText}>pleasure and business</Text>
                purposes.
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                t.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Charter/Air Taxi</Text> means use of the <Text style={styles.boldText}>aircraft</Text> by{' '}
                <Text style={styles.boldText}>you</Text> for transporting passengers and/or freight for hire, and use by{' '}
                <Text style={styles.boldText}>you</Text> or
                <Text style={styles.boldText}>someone we protect</Text> for <Text style={styles.boldText}>pleasure and business</Text>{' '}
                purposes.
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                u.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Flying Club</Text> means use of the <Text style={styles.boldText}>aircraft</Text> by{' '}
                <Text style={styles.boldText}>your</Text> members for their <Text style={styles.boldText}>pleasure and business</Text>{' '}
                purposes. A member is any person having an ownership interest in the <Text style={styles.boldText}>aircraft</Text> or the
                organization shown in Item 1 of the Coverage Identification Page. <Text style={styles.boldText}>You</Text>
                may charge membership fees and dues and <Text style={styles.boldText}>you</Text> may also charge the members fees for use of
                the <Text style={styles.boldText}>aircraft.</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            2.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'} <Text style={styles.boldText}>Our</Text> Obligations and <Text style={styles.boldText}>Your</Text> Duties
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> agree to provide coverage to <Text style={styles.boldText}>you</Text> and{' '}
            <Text style={styles.boldText}>someone we protect</Text> if <Text style={styles.boldText}>you</Text> pay the premium and comply
            fully with the policy requirements, but if <Text style={styles.boldText}>you</Text> do not, or{' '}
            <Text style={styles.boldText}>someone we protect</Text> does not, then <Text style={styles.boldText}>we</Text> are not obligated
            either to <Text style={styles.boldText}>you</Text> or to <Text style={styles.boldText}>someone we protect. We</Text> have the
            right to deduct any premium or other monetary obligations owed to <Text style={styles.boldText}>us</Text> from any payment{' '}
            <Text style={styles.boldText}>we</Text> make.
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            3.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'} Requirements for the Pilot Flying the <Text style={styles.boldText}>Aircraft</Text>
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>You</Text> must make certain that the pilot operating the{' '}
            <Text style={styles.boldText}>aircraft in flight</Text> meets the requirements shown in Item 9 of the Coverage Identification
            Page. There is no coverage under the policy for any <Text style={styles.boldText}>accident</Text> or{' '}
            <Text style={styles.boldText}>occurance</Text> involving operation of the{' '}
            <Text style={styles.boldText}>aircraft in flight</Text> if the pilot does not meet these requirements.
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            4.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'} The Use of the <Text style={styles.boldText}>Aircraft</Text>
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>You</Text> must make certain that the <Text style={styles.boldText}>aircraft</Text> is used for
            the purposes stated in Item 10 of the Coverage Identification Page. There is no coverage under the policy if the{' '}
            <Text style={styles.boldText}>aircraft:</Text>
          </Text>
          <View style={[styles.nestedListContainer]}>
            <View>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                is used for any purpose not stated in Item 10 of the Coverage Identification Page;
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>is used for any unlawful purpose;</Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                c.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                use requires a special permit or waiver from the <Text style={styles.boldText}>FAA;</Text> or
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                d.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                airworthiness certificate is not in full force and effect or has been converted to a restricted or experimental certificate
                unless stated in Item 5 of the Coverage Identification Page.
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            5.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'} When and Where the Policy Provides Coverage
          </Text>
          <Text style={styles.listItemParagraph}>
            This policy provides coverage during the policy period shown in Item 3 of the Coverage Identification Page while the{' '}
            <Text style={styles.boldText}>aircraft</Text> is within the United States (excluding Alaska), Canada, Mexico, and the Islands of
            the Bahamas or while en route between these territories.
          </Text>
        </View>
        <View style={[styles.listItem, {marginBottom: '-20px'}]}>
          <Text style={styles.listItemHeading}>
            6.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'} If there is an <Text style={styles.boldText}>Accident</Text> or <Text style={styles.boldText}>Occurrence</Text>
          </Text>
          <Text style={styles.listItemParagraph}>
            This policy provides coverage during the policy period shown in Item 3 of the Coverage Identification Page while the{' '}
            <Text style={styles.boldText}>aircraft</Text> is within the United States (excluding Alaska), Canada, Mexico, and the Islands of
            the Bahamas or while en route between these territories.
          </Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.listItemParagraph, styles.nestedListItemParagraph]}>
                In the event of an <Text style={styles.boldText}>accident</Text> or <Text style={styles.boldText}>occurance, you</Text> you
                or <Text style={styles.boldText}>someone we protect</Text> must:
              </Text>
              <View style={[styles.nestedListContainer, { marginTop: '-10px' }]}>
                <View>
                  <Text style={styles.listItemHeading}>
                    i.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.listItemParagraph, styles.nestedListItemParagraph]}>
                    promptly notify <Text style={styles.boldText}>us</Text> or <Text style={styles.boldText}>our Aviation Managers</Text>{' '}
                    and describe how, when and where the <Text style={styles.boldText}>accident</Text> or{' '}
                    <Text style={styles.boldText}>occurance</Text>
                    happened and give the names and addresses of witnesses, injured persons and all persons onboard the{' '}
                    <Text style={styles.boldText}>aircraft.</Text>
                  </Text>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>
                    ii.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.listItemParagraph, styles.nestedListItemParagraph]}>
                    cooperate with <Text style={styles.boldText}>us</Text> in the investigation, settlement or defense of any claim;
                  </Text>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>
                    iii.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.listItemParagraph, styles.nestedListItemParagraph]}>
                    answer under oath questions asked by <Text style={styles.boldText}>us</Text> or anyone{' '}
                    <Text style={styles.boldText}>we</Text> designate;
                  </Text>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>
                    iV.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.listItemParagraph, styles.nestedListItemParagraph]}>
                    promptly send <Text style={styles.boldText}>us</Text> copies of any notices or legal papers received relating to the{' '}
                    <Text style={styles.boldText}>accident</Text> or <Text style={styles.boldText}>occurance;</Text>
                  </Text>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>
                    V.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.listItemParagraph, styles.nestedListItemParagraph]}>
                    help <Text style={styles.boldText}>us</Text> in obtaining and giving evidence, attending hearings and trials, and
                    getting witnesses to testify; and
                  </Text>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>
                    Vi.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.listItemParagraph, styles.nestedListItemParagraph]}>
                    promptly notify the police if <Text style={styles.boldText}>your aircraft</Text> or any part of it is stolen or
                    vandalized
                  </Text>
                </View>
              </View>
              <View style={{marginBottom: '-30px'}}>
                <Text style={styles.listItemHeading}>
                  b.{'\u00A0'}
                  {'\u00A0'}
                  {'\u00A0'}
                </Text>
                <Text style={[styles.listItemParagraph, styles.nestedListItemParagraph]}>
                  In the event of an <Text style={styles.boldText}>accident</Text> or <Text style={styles.boldText}>occurance, you</Text>{' '}
                  and <Text style={styles.boldText}>someone we protect</Text> must not:
                </Text>
                <View style={[styles.nestedListContainer, { marginTop: '-10px'}]}>
                  <View>
                    <Text style={styles.listItemHeading}>
                      i.{'\u00A0'}
                      {'\u00A0'}
                      {'\u00A0'}
                    </Text>
                    <Text style={[styles.listItemParagraph, styles.nestedListItemParagraph]}>
                      make any statement about the <Text style={styles.boldText}>accident</Text> or{' '}
                      <Text style={styles.boldText}>occurance</Text> to <Text style={styles.boldText}>others</Text> without{' '}
                      <Text style={styles.boldText}>our</Text> permission, except to government authorities making an official
                      investigation; or
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>
                      ii.{'\u00A0'}
                      {'\u00A0'}
                      {'\u00A0'}
                    </Text>
                    <Text style={[styles.listItemParagraph, styles.nestedListItemParagraph]}>
                      make any voluntary payments, assume any obligation or incur any expense without{' '}
                      <Text style={styles.boldText}>our</Text> permission, except for emergency first aid to{' '}
                      <Text style={styles.boldText}>others</Text> or for protection of the <Text style={styles.boldText}>aircraft</Text>{' '}
                      from further loss.
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
    <PageFooter form={formNumber} number={4} />
  </Page>
);
