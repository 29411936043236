import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import { FooterPDF } from 'views/PDFComponents/Footer';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15,
    marginTop: '-15px'
  },
  itemParagraph: {
    marginTop: '0px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const GeneralEndorsement = ({aircraft, formNumber}) => (
  <Page size="Letter" style={styles.page}>
    <View style={[styles.header, { fontSize: 8 }]}>
      <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
    </View>
    <View style={styles.header}>
      <Text style={styles.boldText}>General Change Endorsement</Text>
    </View>
    <View style={styles.content}>
      <View>
        <Text>
          This Endorsement only applies to the following <Text style={styles.boldText}>aircraft: {aircraft?.registrationNo}</Text>
        </Text>
      </View>
      <View style={styles.centeredText}>
        <Text style={{ fontStyle: 'italic' }}>
          If no entry is made this endorsement applies to all <Text style={styles.boldText}>aircraft</Text> covered by{' '}
          <Text style={styles.boldText}>your</Text> policy.
        </Text>
      </View>
      <View>
        <Text>
          Various provisions of this endorsement extend or restrict coverage. Read <Text style={styles.boldText}>your</Text> entire policy
          to determine your rights and obligations and what is and is not covered.
        </Text>
      </View>
      <View>
        <Text>
          While the <Text style={styles.boldText}>aircraft</Text> is being used for <Text style={styles.boldText}>Formation Flights,</Text>{' '}
          the liability limit shown on Form GA102, Coverage Identification Page, item 6 is changed to read:
        </Text>
      </View>
      <View>
        <Text>
          <Text style={styles.boldText}>$1,000,000</Text> single limit combined bodily injury and property damage liability with bodily
          injury limited to <Text style={styles.boldText}>$100,000</Text> for any one person.
        </Text>
      </View>
      <View>
        <Text>
          <Text style={styles.boldText}>Formation Flights</Text> means any flight involving two or more{' '}
          <Text style={styles.boldText}>aircraft</Text> where the intentional vertical or horizontal seperation between
          <Text style={styles.boldText}>aircraft</Text> is less than 500 feet.
        </Text>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber}/>
  </Page>
);

export default GeneralEndorsement;
