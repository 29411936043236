import { StyleSheet } from '@react-pdf/renderer';
export const styles = StyleSheet.create({
  header: {
    fontSize: 10,
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  contentDivider: {
    borderTop: '1px solid black'
  },
  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 5
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  content: {
    fontSize: 8,
    gap: 5
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },

  nestedListContainer: {
    paddingLeft: 15,
    gap: '-6px' // Indent nested items
  },
  nestedListItemParagraph: {
    top: '-12px'
  }
});
