import CustomTable from 'component/CustomTable';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';

import { Chip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import LoadingOverlay from 'component/LoadingOverlay';
import AgencyDialog from 'component/AgencyDialog';

const agencyColumns = [
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'code',
    label: 'Code'
  },
  {
    id: 'companyName',
    label: 'Company Name'
  }
];

const AgencyQuote = ({ companyId, activeStep, selectedAgency, emitSelectedAgency }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [actionType, setActionType] = useState('');
  const [agency, setAgency] = useState({});
  const [agencyId, setAgencyId] = useState(null);
  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setAgency({});
  };

  const handleEdit = (event, row) => {
    event.stopPropagation();
    // eslint-disable-next-line no-unused-vars
    const { id, Company, ...rest } = row;
    setAgencyId(id);
    setActionType('Edit');
    setAgency({ ...rest });
    setOpen(true);
  };

  const handleSave = async (agencyDialog) => {
    setIsLoading(true);
    let response;
    switch(actionType){
      case 'Create': {
        response = await axios.post(`/api/agency/companyId/${companyId}`, {
          agency: {
            ...agencyDialog,
            idCompany: +companyId
          }
        });
        emitSelectedAgency(activeStep, response.data);
        break;
      }
      case 'Edit': {
        response = await axios.put(`/api/agency/${agencyId}/companyId/${companyId}`, {
          agency: {
            ...agencyDialog,
          }
        });
        const tempArray = [...agencies];
        const index = tempArray.findIndex((item) => item.id === agencyId);
        tempArray[index] = response.data;
        setAgencies(tempArray);
      }
    }
    handleClosedialog();
    setIsLoading(false);
  };

  const handleAgencySelect = (row) => {
    emitSelectedAgency(activeStep, row);
  };

  useEffect(() => {
    const getAgencies = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/agency/companyId/${companyId}`);
      setAgencies(result.data);
      setIsLoading(false);
    };

    getAgencies();
  }, [companyId]);

  return (
    <>
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        isLoading={isLoading}
        columns={agencyColumns}
        data={agencies}
        hasClickableRows={true}
        selectedRows={[selectedAgency]}
        emitClickedRow={handleAgencySelect}
        title="Agency"
        isSearch={true}
        hasPagination={false}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <>
            <Chip
              label="Select"
              color="primary"
              variant={selectedAgency.id === row.id ? 'filled' : 'outlined'}
              sx={{ color: selectedAgency.id === row.id ? '#fff' : '#000', marginRight: 1 }}
              clickable
            />
            <Chip
              label="Edit"
              color="primary"
              variant="filled"
              sx={{ color: '#fff', width: '40%' }}
              clickable
              onClick={(e) => handleEdit(e, row)}
            />
          </>
        )}
      </CustomTable>
      <AgencyDialog
        actionType={actionType}
        open={open}
        handleClosedialog={handleClosedialog}
        handleAgencySave={handleSave}
        agency={agency}
      />
    </>
  );
};

export default AgencyQuote;
