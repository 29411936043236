import React, { useEffect, useState } from 'react';

// material-ui
import { Chip } from '@mui/material';

// project import
import initializeAxios from 'utils/axios';

// assets
import useAuth from 'hooks/useAuth';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import CustomerOrgDialog from 'component/CustomerOrgDialog';

// ==============================|| CONTACT LIST ||============================== //

const AircraftQuoteCustomerOrg = ({ companyId, activeStep, selectedCustomerOrg, emitSelectedCustomerOrg }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const columns = [
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'type',
      label: 'Type'
    }
  ];

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerOrgs, setCustomerOrgs] = useState([]);
  const [actionType, setActionType] = useState('');
  const [customerOrg, setCustomerOrg] = useState({});
  const [customerId, setCustomerId] = useState(null);
  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setCustomerOrg({});
  };

  const handleCustomerOrgSelect = (row) => {
    emitSelectedCustomerOrg(activeStep, row);
  };

  const handleEdit = (event, row) => {
    event.stopPropagation();
    // eslint-disable-next-line no-unused-vars
    const { id, Company, Options, ...rest } = row;
    setCustomerId(id);
    setActionType('Edit');
    setCustomerOrg({ ...rest });
    setOpen(true);
  };

  const handleSave = async (customerDialog) => {
    setIsLoading(true);
    let response;

    switch (actionType) {
      case 'Create': {
        response = await axios.post(`/api/customer-org/companyId/${companyId}`, {
          customer: {
            ...customerDialog,
            idCompany: +companyId
          }
        });
        emitSelectedCustomerOrg(activeStep, response.data);
        break;
      }
      case 'Edit': {
        response = await axios.put(`/api/customer-org/${customerId}/companyId/${companyId}`, {
          customer: {
            ...customerDialog,
            idCompany: +companyId
          }
        });
        const tempArray = [...customerOrgs];
        const index = tempArray.findIndex((item) => item.id === customerId);
        tempArray[index] = response.data;
        setCustomerOrgs(tempArray);
      }
    }
    handleClosedialog();
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/api/customer-org/companyId/${companyId}`)
      .then((response) => {
        setCustomerOrgs(response.data);
      })
      .then(() => setIsLoading(false));
  }, [companyId]);

  return (
    <>
      <CustomerOrgDialog
        companyId={companyId}
        open={open}
        handleClosedialog={handleClosedialog}
        handleCustomerSave={handleSave}
        actionType={actionType}
        customer={customerOrg}
      />
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        isLoading={isLoading}
        data={customerOrgs}
        columns={columns}
        title="Named Insured"
        hasCreateButton={true}
        isSearch={true}
        selectedRows={[selectedCustomerOrg]}
        hasClickableRows={true}
        emitClickedRow={handleCustomerOrgSelect}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <>
            <Chip
              label="Select"
              color="primary"
              variant={selectedCustomerOrg.id === row.id ? 'filled' : 'outlined'}
              sx={{ color: selectedCustomerOrg.id === row.id ? '#fff' : '#000', marginRight: 1 }}
              clickable
            />
            <Chip
              label="Edit"
              color="primary"
              variant="filled"
              sx={{ color: '#fff', width: '40%' }}
              clickable
              onClick={(e) => handleEdit(e, row)}
            />
          </>
        )}
      </CustomTable>
    </>
  );
};

export default AircraftQuoteCustomerOrg;
