import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import EndorsementHeader from 'views/PDFComponents/EndorsementHeader';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15,
    marginTop: '-16px'
  },

  nestedListContainer: {
    paddingLeft: 15,
    gap: 5 // Indent nested items
  },
  nestedListItemParagraph: {
    top: '-12px'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginLeft: '10px'
  },
  columnLarge: {
    flex: 3, // Takes up two-thirds of the space
    padding: 4
  },
  columnSmall: {
    flex: 1, // Each takes up one-third of the remaining space
    padding: 4
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const RenterPilotEndorsement = ({formNumber}) => (
  <Page size="LETTER" style={styles.page}>
    <EndorsementHeader title="Renter Pilot Liability Coverage Extension" partTwo="other" />
    <View style={styles.content}>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>1. &nbsp; &nbsp; &nbsp;</Text>
          <View style={styles.listItemParagraph}>
            <Text>
              This endorsement extends <Text style={styles.boldText}>your</Text> coverage under Part Three (LIABILITY TO OTHERS) to include
              a <Text style={styles.boldText}>renter pilot</Text> (and the employer of a<Text style={styles.boldText}>renter pilot</Text> if
              the <Text style={styles.boldText}>aircraft</Text> is operated on the employer’s behalf for{' '}
              <Text style={styles.boldText}>pleasure and business</Text> purposes) as{' '}
              <Text style={styles.boldText}>someone we protect,</Text>
              subject to the additional provisions, conditions or terms stated below.
            </Text>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>2. &nbsp; &nbsp; &nbsp;</Text>
          <View style={styles.listItemParagraph}>
            <Text>
              Coverage DL is applicable to this <Text style={styles.boldText}>renter pilor</Text> liability coverage extension subject to
              the following limits of coverage:
            </Text>
          </View>
          <View style={styles.container}>
            <View style={styles.columnLarge} />
            <View style={styles.columnSmall}>
              <Text style={styles.boldText}>Each Occurrence</Text>
            </View>
            <View style={styles.columnSmall}>
              <Text style={styles.boldText}>Each Person</Text>
            </View>
          </View>
          <View style={styles.container}>
            <View style={styles.columnLarge}>
              <Text>
                Single Limit <Text style={styles.boldText}>bodily injury</Text> and <Text style={styles.boldText}>property damage</Text>{' '}
                coverage
              </Text>
            </View>
            <View style={styles.columnSmall}>
              <Text style={styles.boldText}>$100,000</Text>
            </View>
            <View style={styles.columnSmall}>
              <Text style={styles.boldText}>$100,000</Text>
            </View>
          </View>
          <View style={[styles.listItemParagraph, { marginTop: '5px' }]}>
            <Text>
              The limit(s) provided by this coverage extension are a part of <Text style={styles.boldText}>your</Text> coverage limit(s) and
              not in addition to <Text style={styles.boldText}>your</Text> coverage limit(s). The limits of coverage do not increase
              regardless of the number of persons or organizations protected.
            </Text>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>3. &nbsp; &nbsp; &nbsp;</Text>
          <View style={styles.listItemParagraph}>
            <Text>
              If there is other coverage protecting the <Text style={styles.boldText}>renter pilot,</Text> or the{' '}
              <Text style={styles.boldText}>renter pilot`s</Text> employer, for <Text style={styles.boldText}>bodily injury</Text> or{' '}
              <Text style={styles.boldText}>property damage</Text>
              arising out of an <Text style={styles.boldText}>occurence,</Text> the protection provided by{' '}
              <Text style={styles.boldText}>us</Text> in this endorsement shall be excess coverage and shall be effective only after all
              other coverage available for the <Text style={styles.boldText}>occurence</Text> has been paid.
            </Text>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>4. &nbsp; &nbsp; &nbsp;</Text>
          <View style={styles.listItemParagraph}>
            <Text>
              In addition to What Is Not Covered under Part Three (LIABILITY TO OTHERS), <Text style={styles.boldText}>we</Text> also do not
              cover any <Text style={styles.boldText}>renter pilot:</Text>
            </Text>
          </View>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.listItemParagraph, { marginTop: '-10px' }]}>
                <Text>
                  Who operates the <Text style={styles.boldText}>aircraft</Text> or permits the{' '}
                  <Text style={styles.boldText}>aircraft</Text> to be operated:
                </Text>
              </View>
              <View style={styles.nestedListContainer}>
                <View>
                  <Text style={styles.listItemHeading}>i. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.listItemParagraph, { marginTop: '-12px' }]}>
                    <Text>
                      in violation of the <Text style={styles.boldText}>FAA</Text> regulations applicable to aerobatic flight or minimum
                      safe altitudes; or
                    </Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>ii. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.listItemParagraph, { marginTop: '-12px' }]}>
                    <Text>
                      in violation of <Text style={styles.boldText}>your</Text> written rules and regulations for rental of the{' '}
                      <Text style={styles.boldText}>aircraft;</Text> or
                    </Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>iii. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.listItemParagraph, { marginTop: '-12px' }]}>
                    <Text>for any unlawful purpose; or</Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>iv. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.listItemParagraph, { marginTop: '-12px' }]}>
                    <Text>
                      for any purpose or use for which a charge is made to <Text style={styles.boldText}>anyone</Text> by the{' '}
                      <Text style={styles.boldText}>renter pilot;</Text> or
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View>
              <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.listItemParagraph, { marginTop: '-10px' }]}>
                <Text>
                  Who lands the <Text style={styles.boldText}>aircraft</Text> on or takes off from a road, field, beach or other area not
                  designated, maintained and customarily used as a private or public use airport, except for emergency reasons; or
                </Text>
              </View>
            </View>
            <View>
              <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.listItemParagraph, { marginTop: '-10px' }]}>
                <Text>
                  Who permits anyone else to operate the <Text style={styles.boldText}>aircraft</Text> except a{' '}
                  <Text style={styles.boldText}>FAA</Text> Certified Flight Instructor, employed by or under contract with{' '}
                  <Text style={styles.boldText}>you,</Text> meeting the requirements of Item 9 of the Coverage Identification Page while
                  giving flight instruction or check out to the <Text style={styles.boldText}>renter pilot</Text> in the{' '}
                  <Text style={styles.boldText}>aircraft.</Text>
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber}/>
  </Page>
);

export default RenterPilotEndorsement;
