export const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];

export const stateEndorsementDic = [  
  { code: 'AZ', state: 'Arizona Change Endorsement' },
  { code: 'AR', state: 'Arkansas Change Endorsement'  },
  { code: 'CO', state: 'Colorado Change Endorsement' },
  { code: 'FL', state: 'Florida Change Endorsement' },
  { code: 'GA', state: 'Georgia Change Endorsement' },
  { code: 'ID', state: 'Idaho Change Endorsement'},
  { code: 'IL', state: 'Illinois Change Endorsement' },
  { code: 'IN', state: 'Indiana Change Endorsement' },
  { code: 'IA', state: 'Iowa Change Endorsement'},
  { code: 'KS', state: 'Kansas Change Endorsement'},
  { code: 'KY', state: 'Kentucky Change Endorsement' },
  { code: 'MD', state: 'Maryland Change Endorsement' },
  { code: 'MI', state: 'Michigan Change Endorsement'},
  { code: 'MN', state: 'Minnesota Change Endorsement'},
  { code: 'NM', state: 'New Mexico Change Endorsement' },
  { code: 'NC', state: 'North Carolina Change Endorsement' },
  { code: 'PA', state: 'Pennsylvania Change Endorsement' },
  { code: 'SC', state: 'South Carolina Change Endorsement' },
  { code: 'SD', state: 'South Dakota Change Endorsement' },
  { code: 'WA', state: 'Washington Change Endorsement' },
  { code: 'TX', state: 'Texas Change Endorsement', state2: 'Texas Information and Complaint Notice'},
  { code: 'UT', state: 'Utah Change Endorsement' },
  { code: 'WI', state: 'Wisconsin Change Endorsement' },
  { code: 'VA', state: 'Virginia Change Endorsement' },
  { code: 'TN', state: 'Tennessee Change Endorsement' },
  { code: 'OH', state: 'Ohio Change Endorsement' },
  { code: 'OR', state: 'Oregan Change Endorsement' },
  { code: 'WV', state: 'West Virginia Change Endorsement' },
  { code: 'WY', state: 'Wyoming Change Endorsement' },
  { code: 'LA', state: 'Louisiana Change Endorsement' },
  { code: 'MO', state: 'Missouri Change Endorsement' },
  { code: 'OK', state: 'Oklahoma Change Endorsement', state2: 'Oklahoma Information Notice'},
  { code: 'NH', state: 'New Hampshire Change Endorsement'}]
