import React, { useEffect, useState } from 'react';
import initializeAxios from 'utils/axios';

// material-ui
import { Chip } from '@mui/material';
import useAuth from 'hooks/useAuth';
import CustomTable from 'component/CustomTable';

// project import

// assets
import LoadingOverlay from 'component/LoadingOverlay';
import LienholderDialog from 'component/Lienholder';

// ==============================|| lienHolder LIST ||============================== //

const AircraftQuoteLienHolder = ({ companyId, emitSelectedInterest, selectedInterests, activeStep, currentAircraft }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const columns = [
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'address1',
      label: 'Address 1'
    }
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lienHolders, setLienHolders] = useState([]);
  const [actionType, setActionType] = useState('');
  const [lienholder, setLienholder] = useState({});
  const [lienholderId, setLienholderId] = useState(null);
  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setLienholder({});
  };

  const handleEdit = (event, row) => {
    event.stopPropagation();
    // eslint-disable-next-line no-unused-vars
    const { id, ...rest } = row;
    setLienholderId(id);
    setActionType('Edit');
    setLienholder({ ...rest });
    setOpen(true);
  };

  const handleSave = async (lienHolderDialog) => {
    setIsLoading(true);
    let response;
    switch (actionType) {
      case 'Create': {
        response = await axios.post(`/api/lienholder/companyId/${companyId}`, {
          ...lienHolderDialog
        });
        setLienHolders([...lienHolders, response.data])
        emitSelectedInterest(activeStep, response.data);
        break;
      }

      case 'Edit': {
        response = await axios.put(`/api/lienholder/${lienholderId}/companyId/${companyId}`, {
          ...lienHolderDialog
        });
        const tempArray = [...lienHolders];
        const index = tempArray.findIndex((item) => item.id === lienholderId);
        tempArray[index] = response.data;
        setLienHolders(tempArray);
      }
    }

    handleClosedialog();
    setIsLoading(false);
  };

  const handleSelectInterest = (row) => {
    emitSelectedInterest(activeStep, row);
  };

  useEffect(() => {
    const getCustomerOrg = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/lienHolder/companyId/${companyId}/workflow/aircraftId/${currentAircraft.id}`);
      setLienHolders([...result.data]);
      setIsLoading(false);
    };

    getCustomerOrg();
  }, [companyId]);

  return (
    <>
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        title="Other Interests"
        isLoading={isLoading}
        data={lienHolders}
        columns={columns}
        isSearch={true}
        selectedRows={[...selectedInterests]}
        hasClickableRows={true}
        handleOpenCreate={handleClickOpendialog}
        hasCreateButton={true}
        emitClickedRow={handleSelectInterest}
      >
        {(row) => (
          <>
            <Chip
              label={selectedInterests.find((item) => item.id === row.id) ? 'Unselect' : 'Select'}
              color="primary"
              variant={selectedInterests.find((item) => item.id === row.id) ? 'filled' : 'outlined'}
              sx={{ color: selectedInterests.find((item) => item.id === row.id) ? '#fff' : '#000', marginRight: 1 }}
              clickable
              onClick={() => handleSelectInterest(row)}
            />
            <Chip
              label="Edit"
              color="primary"
              variant="filled"
              sx={{ color: '#fff', width: '40%' }}
              clickable
              onClick={(e) => handleEdit(e, row)}
            />
          </>
        )}
      </CustomTable>
      <LienholderDialog
        open={open}
        handleClosedialog={handleClosedialog}
        lienholderState={lienholder}
        handleLienholderSave={handleSave}
        actionType={actionType}
      />
    </>
  );
};

export default AircraftQuoteLienHolder;
