import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 4
  },
  bullet: {
    width: 10,
    textAlign: 'center'
  },
  listText: {
    marginLeft: 4
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const FloodStormDeductible = ({aircraft={}, formNumber}) => (
  <Page size="LETTER" style={styles.page}>
    <View style={[styles.header, { fontSize: 8 }]}>
      <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
    </View>
    <View style={styles.header}>
      <Text style={styles.boldText}>Named Storm, Flooding or Hurricane Deductible</Text>
    </View>
    <View style={styles.content}>
      <View>
        <Text>
          This Endorsement only applies to the following <Text style={styles.boldText}>aircraft: {aircraft?.registrationNo}</Text>
        </Text>
      </View>
      <View style={styles.centeredText}>
        <Text style={{ fontStyle: 'italic' }}>
          If no entry is made this endorsement applies to all <Text style={styles.boldText}>aircraft</Text> covered by{' '}
          <Text style={styles.boldText}>your</Text> policy.
        </Text>
      </View>
      <View>
        <Text>
          Various provisions of this endorsement extend or restrict coverage. Read <Text style={styles.boldText}>your</Text> entire policy
          to determine <Text style={styles.boldText}>your</Text> rights and obligations and what is and is not covered. This endorsement
          changes the provisions of <Text style={styles.boldText}>your</Text> policy under the following coverage parts:
        </Text>
      </View>
      <View style={styles.centeredText}>
        <Text style={styles.boldText}>Part Two. Aircraft Physical Damage Coverage</Text>
      </View>
      <View>
        <Text>
          <Text style={styles.boldText}>You</Text> and <Text style={styles.boldText}>we</Text> agree that the deductible amount shown below
          will apply to any loss or damage under Coverage F caused by flooding or wind (including rainwater damage resulting from wind)
          arising out of a storm or hurricane named by the U.S. National Weather Service:
        </Text>
      </View>
      <View>
        <Text>
          Deductible Amount Each <Text style={styles.boldText}>Aircraft:</Text> __% of the aircraft’s agreed value
        </Text>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber}/>
  </Page>
);

export default FloodStormDeductible;
