import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  Typography,
  Checkbox,
  Select,
  MenuItem,
  Button
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import LoadingOverlay from 'component/LoadingOverlay';

const data = [
  [
    { title: 'General Change Endorsement', checked: false, hasAircraft: true, idAircraft: '', id: -1 },
    { title: 'Passenger Settlement Endorsement', checked: false, hasAircraft: false, idAircraft: '', id: -1 },
    { title: 'Canopy Deductible Endorsement', checked: false, hasAircraft: true, idAircraft: '', id: -1 },
    { title: 'Liability Coverage Extension & Waiver', checked: false, hasAircraft: true, idAircraft: '', id: -1 },
    { title: 'Liability Coverage Extension - General', checked: false, hasAircraft: true, idAircraft: '', id: -1 },
    { title: 'Cap on Losses from Certified Acts of Terrorism', checked: false, hasAircraft: false, idAircraft: '', id: -1 },
    { title: 'Exclusion of Certified Acts of Terrorism', checked: false, hasAircraft: false, idAircraft: '' }
  ],
  [
    { title: 'Gear Up / Gear Collapse Endorsement', checked: false, hasAircraft: true, idAircraft: '', id: -1 },
    { title: 'Seaplane and Amphibian Endorsement I', checked: false, hasAircraft: true, idAircraft: '', id: -1 },
    { title: 'War-Confiscation Coverage', checked: false, hasAircraft: true, idAircraft: '', id: -1 },
    { title: 'Helicopter Endorsement', checked: false, hasAircraft: true, idAircraft: '', id: -1 },
    { title: 'Special Use Endorsement', checked: false, hasAircraft: true, idAircraft: '', id: -1 },
    { title: 'Named Storm, Flooding or Hurricane Deductible', checked: false, hasAircraft: true, idAircraft: '', id: -1 },
    { title: 'Financed Endorsement', checked: false, hasAircraft: false, idAircraft: '', id: -1 }
  ],
  [
    { title: 'Damaged Aircraft Endorsement', checked: false, hasAircraft: true, idAircraft: '', id: -1 },
    { title: 'Non-Ownership Liability Coverage', checked: false, hasAircraft: true, idAircraft: '', id: -1 },
    { title: 'Turbine Aircraft Endorsement', checked: false, hasAircraft: false, idAircraft: '', id: -1 },
    {
      title: 'Special Use, Coverage and Pilots Limitations Endorsement',
      checked: false,
      hasAircraft: false,
      idAircraft: '',
      id: -1
    },
    { title: 'Renter Pilot Liability Coverage Extension', checked: false, hasAircraft: false, idAircraft: '', id: -1 },
    { title: 'Liability Coverage Extension - FLIGHT INSTRUCTION', checked: false, hasAircraft: true, idAircraft: '', id: -1 }
  ]
];

const PDFSelections = ({ open, handleClose, aircrafts, policySelections, idPolicy, idCompany, handleSelections }) => {
  // Mock data for the columns
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const [selections, setSelections] = useState(data);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    const updatedSelections = selections.flat().map((item) => ({
      id: item?.id || -1,
      selectionName: item.title,
      selected: item.checked || false,
      idAircraft: item.idAircraft || null,
      idPolicy,
      idCompany: +idCompany
    }));
    const response = await axios.put(`/api/pdfSelections`, {
      selections: [...updatedSelections]
    });
    handleSelections(response.data);
    setIsLoading(false);
    handleClose();
  };
  const handleCheckboxChange = (colIndex, rowIndex) => {
    setSelections((prevData) =>
      prevData.map((col, cIdx) =>
        cIdx === colIndex ? col.map((item, rIdx) => (rIdx === rowIndex ? { ...item, checked: !item.checked, idAircraft: '' } : item)) : col
      )
    );
  };

  const handleSelectorChange = (colIndex, rowIndex, value) => {
    setSelections((prevData) =>
      prevData.map((col, cIdx) =>
        cIdx === colIndex ? col.map((item, rIdx) => (rIdx === rowIndex ? { ...item, idAircraft: value } : item)) : col
      )
    );
  };

  useEffect(() => {
    if (policySelections?.length > 0) {
      const updatedData = selections.map((column) =>
        column.map((item) => {
          const match = policySelections.find((selection) => selection.selectionName === item.title);
          return match ? { ...item, checked: match.selected, idAircraft: match.idAircraft, id: match?.id } : item;
        })
      );
      setSelections(updatedData);
    }
  }, [policySelections]);

  return (
    <>
      <LoadingOverlay loading={isLoading} />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: { borderRadius: '12px', padding: '16px' }
        }}
      >
        <DialogTitle textAlign="center" fontSize={20}>
          PDF Selections
        </DialogTitle>
        <DialogTitle textAlign="center" fontSize={8} sx={{ marginTop: '-35px' }}>
          If no aircraft is selected all aircrafts are chosesn for the given selection
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {selections.map((column, colIndex) => (
              <Grid item xs={4} key={`column-${colIndex}`}>
                {column.map((row, rowIndex) => (
                  <div
                    key={`row-${colIndex}-${rowIndex}`}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '16px'
                    }}
                  >
                    <Typography sx={{ marginRight: 1 }}>{row.title}</Typography>
                    <Checkbox size="large" checked={row.checked || false} onChange={() => handleCheckboxChange(colIndex, rowIndex)} />
                    {row.hasAircraft && (
                      <Select
                        defaultValue=""
                        style={{ marginLeft: '8px', minWidth: '100px' }}
                        value={row.idAircraft || ''}
                        onChange={(e) => handleSelectorChange(colIndex, rowIndex, e.target.value)}
                        disabled={!row.checked}
                      >
                        {aircrafts?.length > 0
                          ? aircrafts?.map((item) => (
                              <MenuItem key={item?.idAircraft} value={item?.idAircraft}>
                                {item?.Aircraft?.registrationNo}
                              </MenuItem>
                            ))
                          : []}
                      </Select>
                    )}
                  </div>
                ))}
              </Grid>
            ))}
          </Grid>
        </DialogContent>

        <Divider />

        <DialogActions style={{ justifyContent: 'space-between', padding: '16px' }}>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PDFSelections;
