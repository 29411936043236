// react Imports
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// Mui Imports
import { Grid, Typography, Card, CardContent, Divider, Box, TextField, Tab, Tabs, Button, Autocomplete, MenuItem } from '@mui/material';
import Breadcrumb from 'component/Breadcrumb';
import { styled } from '@mui/material/styles';

// Local Imports
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { gridSpacing } from 'config.js';
import { validRegex, phoneNumberRegex, formatDate } from 'utils/FormatUtils';

// Icons
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoadingOverlay from 'component/LoadingOverlay';
import { deactiveArrayUtil } from 'utils/deactiveArrayUtil';

import PilotOptions from '../PilotOptions';

const AccountTabs = styled((props) => <Tabs {...props} />)(() => ({
  marginBottom: '24px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
  '.MuiButtonBase-root': {
    minWidth: '120px',
    minHeight: 'auto',
    '.MuiTouchRipple-root': {
      flexDirection: 'row',
      '.MuiSvgIcon-root': {
        marginRight: '10px',
        marginBottom: '0 !important'
      }
    }
  }
}));

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const PilotDetails = () => {
  // Initialize Axios
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [value, setValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const { pilotId, companyId } = useParams();
  const [pilotDetails, setPilotDetails] = useState({});

  const [pilotOptions, setPilotOptions] = useState([]);

  const [customerOrg, setCustomerOrg] = useState([]);
  const [pilotCerts, setPilotCerts] = useState([]);
  const [pilotRatings, setPilotRatings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    const response = await axios.put(`/api/pilots/${pilotId}/companyId/${companyId}`, {
      pilot: {
        firstname: pilotDetails?.firstname,
        lastname: pilotDetails?.lastname,
        email: pilotDetails?.email,
        phone: pilotDetails?.phone,
        cellphone: pilotDetails?.cellphone,
        dateofbirth: pilotDetails?.dateofbirth,
        age: +pilotDetails?.age,
        totalHours: +pilotDetails?.totalHours,
        lastYearTotal: +pilotDetails?.lastYearTotal,
        multiEngine: +pilotDetails?.multiEngine,
        retractGear: +pilotDetails?.retractGear,
        makeAndModel: +pilotDetails?.makeAndModel,
        riskState: pilotDetails?.riskState,
        idCustomerOrganization: pilotDetails.idCustomerOrganization
      },
      pilotCerts: pilotDetails?.PilotCerts,
      pilotRatings: pilotDetails?.pilotRatings
    });
    const newPilotDetails = response.data;
    setPilotDetails({
      ...newPilotDetails,
      PilotCerts: newPilotDetails?.PilotCerts?.map((cert) => cert.Options.id),
      pilotRatings: newPilotDetails?.PilotRating?.map((rate) => rate.Options.id)
    });
    setIsLoading(false);
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setPilotDetails({
      ...pilotDetails,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    setPilotDetails({
      ...pilotDetails,
      dateofbirth: date !== '' ? date : null
    });
  };

  const updatePilotOption = (item, actionType) => {
    if (actionType !== 'Create') {
      setPilotOptions(item);
    } else {
      setPilotOptions([...pilotOptions, item]);
    }
  };

  useEffect(() => {
    const getPilot = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/pilots/${pilotId}/companyId/${companyId}`);
      setPilotDetails({
        ...response.data,
        PilotCerts: response.data.PilotCerts.map((cert) => cert.Options.id),
        pilotRatings: response.data.PilotRating.map((rate) => rate.Options.id)
      });
      setPilotOptions([...response.data.PilotOption]);
      setIsLoading(false);
    };

    const getPilotCerts = async () => {
      const response = await axios.get(`/api/options/companyId/${companyId}?category=PilotCertType`);
      setPilotCerts([...response.data]);
    };
    const getPilotRatings = async () => {
      const response = await axios.get(`/api/options/companyId/${companyId}?category=PilotRating`);
      setPilotRatings([...response.data]);
    };
    const getCustomerOrgs = async () => {
      const result = await axios.get(`/api/customer-org/companyId/${companyId}`);
      setCustomerOrg(result.data);
    };

    getPilot();
    getPilotCerts();
    getPilotRatings();
    getCustomerOrgs();
  }, []);
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb title="Pilot Details" divider={false} sx={{ mt: { lg: 0, xs: 2 } }}>
            <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
              Home
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/pilots/`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              Pilots
            </Typography>
            <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
              Details
            </Typography>
          </Breadcrumb>
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <Grid item lg={8} xs={12}>
        <Grid container spacing={gridSpacing} xs={12} item sx={{ flexWrap: { lg: 'nowrap', sm: 'wrap' } }} className="grid-container">
          <Grid item xs={12} sx={{ mt: 1 }}>
            <AccountTabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              <Tab icon={<DescriptionTwoToneIcon />} iconPosition="start" label="Pilot" {...a11yProps(0)} />
            </AccountTabs>
            <TabPanel value={value} index={0}>
              <Card>
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item sm zeroMinWidth>
                      <Typography component="div" variant="h5">
                        Client Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          {customerOrg?.length && (
                            <Typography variant="subtitle2">
                              <Autocomplete
                                sx={{ mt: 1 }}
                                fullWidth
                                options={deactiveArrayUtil(customerOrg, {
                                  id: pilotDetails?.idCustomerOrganization,
                                  name: pilotDetails?.CustomerOrganization?.name
                                })}
                                getOptionLabel={(option) => ` ${option.id}: ${option.name}`}
                                value={
                                  deactiveArrayUtil(customerOrg, {
                                    id: pilotDetails?.idCustomerOrganization,
                                    name: pilotDetails?.CustomerOrganization?.name
                                  }).find((pil) => pil.id === pilotDetails.idCustomerOrganization) || null
                                }
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(_, newValue) => {
                                  const selectedCustomerOrgId = newValue === undefined ? null : newValue?.id;
                                  handleChange({
                                    target: { name: 'idCustomerOrganization', value: selectedCustomerOrgId }
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    error={!pilotDetails?.idCustomerOrganization}
                                    helperText={!pilotDetails?.idCustomerOrganization ? 'Must not be Emoty' : ''}
                                    {...params}
                                    label="Customer Organization*"
                                  />
                                )}
                              />
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography component="div" variant="h5">
                            Pilot Information Details
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            First Name : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="firstname"
                                value={pilotDetails?.firstname || ''}
                                error={!pilotDetails?.firstname?.trim()}
                                helperText={!pilotDetails?.firstname?.trim() ? 'Last Name cannot be empty' : ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Last Name : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="lastname"
                                value={pilotDetails?.lastname || ''}
                                error={!pilotDetails?.lastname?.trim()}
                                helperText={!pilotDetails?.lastname?.trim() ? 'Last Name cannot be empty' : ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Email : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="email"
                                value={pilotDetails?.email || ''}
                                error={pilotDetails?.email?.length > 0 ? !pilotDetails?.email?.match(validRegex) : false}
                                helperText={
                                  pilotDetails?.email?.length > 0 ? (!pilotDetails?.email?.match(validRegex) ? 'Invalid Emial' : '') : ''
                                }
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Cell Phone : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="cellphone"
                                value={pilotDetails?.cellphone || ''}
                                error={pilotDetails?.cellphone?.length > 0 ? !pilotDetails?.cellphone?.match(phoneNumberRegex) : false}
                                helperText={
                                  pilotDetails?.cellphone?.length > 0
                                    ? !pilotDetails?.cellphone?.match(phoneNumberRegex)
                                      ? 'Invalid Phone Number'
                                      : ''
                                    : ''
                                }
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Phone Number: &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="phone"
                                value={pilotDetails?.phone || ''}
                                error={pilotDetails?.phone?.length > 0 ? !pilotDetails?.phone?.match(phoneNumberRegex) : false}
                                helperText={
                                  pilotDetails?.phone?.length > 0
                                    ? !pilotDetails?.phone?.match(phoneNumberRegex)
                                      ? 'Invalid Phone Number'
                                      : ''
                                    : ''
                                }
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Typography component="div" variant="subtitle1" sx={{ mt: 1 }}>
                            Date of Birth: &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <DatePicker
                                disableToolbar
                                variant="inline"
                                inputFormat="MM/dd/yyyy"
                                margin="normal"
                                slotProps={{
                                  textField: {
                                    fullWidth: true
                                  }
                                }}
                                value={formatDate(pilotDetails?.dateofbirth) || null}
                                onChange={handleDateChange}
                                readOnly={!auth.writePrivledge(auth.user, 'Pilot_Adm')}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date'
                                }}
                                keyboardIcon={<EventTwoToneIcon />}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Age (years): &nbsp;
                            <Typography component="div" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                type="number"
                                name="age"
                                value={pilotDetails?.age || ''}
                                variant="outlined"
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography component="div" variant="h5">
                            Pilot Certifications
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography component="div" variant="subtitle1">
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                fullWidth
                                SelectProps={{
                                  multiple: true
                                }}
                                select
                                name="PilotCerts"
                                label="Pilot Certifications"
                                error={!pilotDetails?.PilotCerts?.length}
                                helperText={!pilotDetails?.PilotCerts?.length && 'Cannot Be Empty'}
                                value={pilotDetails?.PilotCerts || []}
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                              >
                                {pilotCerts.map((cert) => (
                                  <MenuItem key={cert.id} value={cert.id}>
                                    {cert.val}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography component="div" variant="subtitle1">
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                fullWidth
                                SelectProps={{
                                  multiple: true
                                }}
                                select
                                name="pilotRatings"
                                label="Pilot Ratings"
                                value={pilotDetails?.pilotRatings || []}
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                              >
                                {pilotRatings.map((cert) => (
                                  <MenuItem key={cert.id} value={cert.id}>
                                    {cert.val}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography component="div" variant="h5">
                            Pilot Requirements - Total Hours
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Total Hours: &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                fullWidth
                                sx={{ mt: 1 }}
                                type="number"
                                name="totalHours"
                                value={pilotDetails?.totalHours || ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Multi-Engine: &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                fullWidth
                                sx={{ mt: 1 }}
                                type="number"
                                name="multiEngine"
                                value={pilotDetails?.multiEngine || ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Retract Gear: &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                fullWidth
                                sx={{ mt: 1 }}
                                type="number"
                                name="retractGear"
                                value={pilotDetails?.retractGear || ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Make and Model: &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                fullWidth
                                sx={{ mt: 1 }}
                                type="number"
                                name="makeAndModel"
                                value={pilotDetails?.makeAndModel || ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Seaplane: &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                fullWidth
                                sx={{ mt: 1 }}
                                type="number"
                                name="seaplaneTime"
                                value={pilotDetails?.seaplaneTime || ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Hours Flown in the last year: &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                fullWidth
                                sx={{ mt: 1 }}
                                type="number"
                                name="lastYearTotal"
                                value={pilotDetails?.lastYearTotal || ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Risk State: &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                fullWidth
                                sx={{ mt: 1 }}
                                name="riskState"
                                value={pilotDetails?.riskState || ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Pilot_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item sm={6} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            disabled={
                              !pilotDetails?.firstname?.trim() ||
                              !pilotDetails?.lastname?.trim() ||
                              (pilotDetails?.cellphone?.length > 0 ? !pilotDetails?.cellphone?.match(phoneNumberRegex) : false) ||
                              (pilotDetails?.phone?.length > 0 ? !pilotDetails?.phone?.match(phoneNumberRegex) : false) ||
                              (pilotDetails?.email?.length > 0 ? !pilotDetails?.email?.match(validRegex) : false) ||
                              ((pilotDetails?.dateofbirth ? !formatDate(pilotDetails?.dateofbirth).isValid() : true) &&
                                !pilotDetails?.age) ||
                              !pilotDetails?.PilotCerts?.length ||
                              !auth.writePrivledge(auth.user, 'Pilot_Adm')
                            }
                          >
                            Save Changes
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <PilotOptions
                    isLoading={isLoading}
                    data={pilotOptions}
                    pilotId={pilotId}
                    updatePilotOption={updatePilotOption}
                    companyId={companyId}
                    totalHours={pilotDetails?.totalHours}
                  />
                </CardContent>
              </Card>
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PilotDetails;
