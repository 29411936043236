import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { formatDate } from 'utils/FormatUtils';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 5
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  titleHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'OpenSans'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15,
    marginTop: '-16px'
  },

  nestedListContainer: {
    paddingLeft: 15,
    gap: 5 // Indent nested items
  },
  nestedListItemParagraph: {
    top: '-12px'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 7,
    width: '100%'
  },
  columnLarge: {
    flex: 2, // Takes up two-thirds of the space
    textAlign: 'center',
    padding: 4
  },
  columnSmall: {
    flex: 1, // Each takes up one-third of the remaining space
    textAlign: 'center',
    padding: 4
  },

  contentContainer: { flexDirection: 'row', marginBottom: 10 },
  headerRow: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 0 },
  listHeader: { fontWeight: 'bold' },
  itemNumber: { fontWeight: 'bold', width: 20, textAlign: 'right', marginRight: 10 },
  itemColumn: { flexDirection: 'row', width: '60%' },
  addressColumn: { flexDirection: 'column', width: '40%', paddingLeft: 10 },
  description: { marginLeft: 15 },
  signatureContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20
  },
  leftSection: {
    width: '50%'
  },
  companyName: {
    fontWeight: 'bold',
    fontSize: 8.5
  },
  subText: {
    fontSize: 7,
    marginVertical: 2
  },
  dateIssued: {
    fontSize: 8.5,
    marginTop: 5
  },
  rightSection: {
    width: '50%',
    alignItems: 'flex-end'
  },
  signatureLine: {
    fontSize: 8.5,
    marginTop: 20,
    width: '100%',
    borderBottom: '1px solid black'
  },
  authorizedRep: {
    fontWeight: 'bold',
    fontSize: 8.5,
    marginTop: 5,
    textAlign: 'center',
    width: '100%'
  },
  forCompanyUseContainer: {
    alignItems: 'flex-end',
    paddingHorizontal: 40,
    marginTop: 20
  },
  forCompanyUse: {
    flexDirection: 'row',
    width: '100%', // Adjust the width as needed
    justifyContent: 'space-between'
  },
  boxedText: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    padding: '0 10px',
    marginRight: 1,
    textAlign: 'center',
    width: '100%'
  },
  emptyBox: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    textAlign: 'center',
    padding: '0 10px',
    marginRight: 1,
    width: '100%'
  },
  footerText: {
    fontSize: 8,
    textAlign: 'center',
    marginTop: 5,
    width: '100%' // Matches the width of the boxed row above
  },
  dataInfo: {
    color: 'red'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const CoverageIdentificationPage = ({ policy, formNumber, formIds, aircraft }) => {
  let beginDate;
  let endDate;
  if (Object.keys(policy)?.length !== 0) {
    beginDate = formatDate(policy.beginDate);
    endDate = formatDate(policy.endDate);
    return (
      <>
        <Page size="LETTER" style={styles.page}>
          <View style={{ marginRight: '100px' }}>
            <View style={styles.header}>
              <Text style={[styles.boldText, { fontSize: 24, color: 'blue' }]}>Imperium Insurance Company</Text>
            </View>
            <View style={styles.header}>
              <Text style={[styles.boldText, { fontSize: 10, color: 'blue', opacity: 0.5, marginTop: '-10px' }]}>
                A Subsidiary of Skyward Specialty Insurance
              </Text>
            </View>
          </View>
          <View style={styles.content}>
            <View style={styles.titleHeader}>
              <View>
                <Text>800 Gessner Rd. Suite 600</Text>
                <Text>Houston, TX 77024</Text>
              </View>
              <View>
                <Text>Coverage Identification Page</Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end', // Align the entire block to the right
                width: '100%'
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between', // Ensure space between elements
                  alignItems: 'center', // Align items vertically
                  width: '100%', // Parent container needs space to distribute
                  maxWidth: 200 // Optional: Restrict the row's width
                }}
              >
                <Text style={{ marginRight: 16 }}>POLICY NO.</Text>
                <Text style={[styles.boldText, styles.dataInfo]}>{policy?.policyNumber}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between', // Ensure space between elements
                  alignItems: 'center', // Align items vertically
                  width: '100%', // Parent container needs space to distribute
                  maxWidth: 200 // Optional: Restrict the row's width
                }}
              >
                <Text style={{ marginRight: 16 }}>PRIOR POLICY NO.</Text>
                <Text style={styles.dataInfo}>{policy?.Policy?.id ? policy?.Policy?.policyNumber : 'NEW'}</Text>
              </View>
            </View>

            {/* Header Section */}
            <View style={styles.headerRow}>
              <Text style={[styles.listHeader, { marginLeft: '5px' }]}>Item</Text>
              <Text style={{ marginRight: '45px' }}>Company Agent`s Name and Address</Text>
            </View>

            {/* Main Content Section */}
            <View style={[styles.contentContainer, { marginTop: '-10px' }]}>
              {/* Left Column (Numbered List) */}
              <View style={[styles.itemColumn]}>
                <View style={{ flexDirection: 'column', gap: '10px', padding: '0 70px 0 0' }}>
                  {/* Item 1 */}
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.itemNumber}>1.</Text>
                    <View style={{ marginRight: 10 }}>
                      <Text>NAMED</Text>
                      <Text>INSURED</Text>
                    </View>
                    <Text style={[styles.description, styles.dataInfo]}>{policy?.clientNameInsured}</Text>
                  </View>

                  {/* Item 2 */}
                  <View style={{ flexDirection: 'row', paddingRight: 15 }}>
                    <Text style={styles.itemNumber}>2.</Text>
                    <View style={{ marginRight: 10 }}>
                      <Text>YOUR</Text>
                      <Text>ADDRESS:</Text>
                    </View>
                    <Text style={[styles.description, styles.dataInfo]}>
                      {`${[
                        policy?.customerOrganizationAddress1 || '',
                        policy?.customerOrganizationCity || '',
                        policy?.customerOrganizationState || '',
                        policy?.customerOrganizationZip || ''
                      ]
                        .filter(Boolean)
                        .join(', ')}`}
                    </Text>
                  </View>
                </View>
              </View>

              {/* Right Column (Address) */}
              <View style={styles.addressColumn}>
                <Text style={[styles.boldText, styles.dataInfo, { fontSize: 8 }]}>{policy?.agencyName}</Text>
                <Text style={styles.dataInfo}>{policy?.agencyAddress1}</Text>
                <Text style={styles.dataInfo}>
                  {`${[policy?.agencyCity || '', policy?.agencyState || '', policy?.agencyZip || ''].filter(Boolean).join(', ')}`}
                </Text>
              </View>
            </View>
            <View style={{ gap: '10px', width: '100%', flexDirection: 'column' }}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.itemNumber}>3.</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text style={{ marginRight: 10 }}>
                    <Text style={styles.boldText}>POLICY PERIOD: </Text>
                    <Text style={[styles.dataInfo, { fontSize: 7 }]}>
                      {`${beginDate?.format('hh:mm A')}`} STANDARD TIME AT YOUR ADDRESS FROM:
                    </Text>
                  </Text>
                  <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
                    <View style={{ marginRight: 10 }}>
                      <Text>
                        <Text style={[styles.boldText, styles.dataInfo]}>{beginDate?.format('MM/DD/YYYY')}</Text> TO{' '}
                        <Text style={[styles.boldText, styles.dataInfo]}>{endDate?.format('MM/DD/YYYY')}</Text>
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.dataInfo}>{`${endDate?.format('hh:mm A')}`} STANDARD TIME</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.itemNumber}>4.</Text>
                <View>
                  <Text style={{ marginRight: 10 }}>
                    <Text style={styles.boldText}>LOCATION OF AIRCRAFT: </Text>The Aircraft will be based principally at the following
                    airport:
                  </Text>
                </View>
              </View>
              <View style={styles.centeredText}>
                <Text style={[styles.boldText, styles.dataInfo]}>
                  {policy.hangered ? 'Hangered' : 'Tied Out'} at{' '}
                  {aircraft?.PolicyAircraftDetails[0]?.AircraftDetails?.airportName
                    ? `${aircraft?.PolicyAircraftDetails[0]?.AircraftDetails?.airportName},`
                    : ''}{' '}
                  {aircraft?.PolicyAircraftDetails[0]?.AircraftDetails?.cityName
                    ? `${aircraft?.PolicyAircraftDetails[0]?.AircraftDetails?.cityName},`
                    : ''}{' '}
                  {aircraft?.PolicyAircraftDetails[0]?.AircraftDetails?.stateCode
                    ? `${aircraft?.PolicyAircraftDetails[0]?.AircraftDetails?.stateCode}`
                    : ''}
                </Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.itemNumber}>5.</Text>
                <View>
                  <Text style={{ marginRight: 10, fontSize: 7 }}>
                    <Text style={styles.boldText}>DESCRIPTION OF AIRCRAFT AND AIRCRAFT PHYSICAL DAMAGE COVERAGE:</Text>&nbsp; &nbsp; You
                    have told us that each of the aircraft below (1) is a fixed wing land aircraft with an FAA Standard Airworthiness
                    Certificate unless noted in the AIRCRAFT DESCRIPTION below; and (2) is solely and unconditionally owned by you unless
                    noted differently in Item 1 or endorsements we issue. IF NO AGREED VALUE AMOUNT IS SHOWN NO AIRCRAFT PHYSICAL DAMAGE
                    COVERAGE IS PROVIDED.
                  </Text>
                </View>
              </View>
              <View style={[styles.container, { paddingLeft: 20, fontSize: 7 }]}>
                <View style={styles.columnSmall}>
                  <Text>FAA Registration</Text>
                  <Text>Number</Text>
                </View>
                <View style={styles.columnLarge}>
                  <Text>AIRCRAFT DESCRIPTION</Text>
                </View>
                <View style={styles.columnSmall}>
                  <Text>TOTAL SEATS</Text>
                </View>
                <View style={styles.columnLarge}>
                  <Text>AIRCRAFT PHYSICAL DAMAGE COVERAGE</Text>
                </View>
              </View>
              <View style={[styles.container, { marginTop: '-20px', paddingLeft: 20 }]}>
                <View style={styles.columnSmall} />
                <View style={styles.columnLarge}>
                  <Text>WITH STANDARD AIRWORTHINESS CERTIFICATE, IF APPLICABLE</Text>
                </View>
                <View style={styles.columnSmall} />
                <View style={styles.columnLarge}>
                  <View style={[styles.container, { marginRight: 10 }]}>
                    <View style={styles.columnSmall}>
                      <Text>AGREED</Text>
                      <Text>VALUE</Text>
                    </View>
                    <View style={styles.columnSmall}>
                      <Text style={{ fontSize: 5 }}>F. Not in Motion</Text>
                      <Text>DEDUCTIBLE</Text>
                    </View>
                    <View style={styles.columnSmall}>
                      <Text style={{ fontSize: 5 }}>G. In Motion</Text>
                      <Text>DEDUCTIBLE</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={[styles.container, { marginTop: '-20px', paddingLeft: 20 }]}>
                {policy?.PolicyAircraft?.length === 1 ? (
                  <>
                    <View style={styles.columnSmall}>
                      <Text style={[styles.boldText, styles.dataInfo]}>
                        {aircraft?.PolicyAircraftDetails[0]?.AircraftDetails?.registrationNo || ''}
                      </Text>
                    </View>
                    <View style={styles.columnLarge}>
                      <Text style={[styles.boldText, styles.dataInfo]}>
                        {`${aircraft?.PolicyAircraftDetails[0]?.AircraftDetails?.year} ${aircraft?.PolicyAircraftDetails[0]?.AircraftDetails?.make}`}
                      </Text>
                    </View>
                    <View style={styles.columnSmall}>
                      <Text style={[styles.boldText, styles.dataInfo]}>
                        {aircraft?.PolicyAircraftDetails[0]?.AircraftDetails?.seats || 0}
                      </Text>
                    </View>
                    <View style={styles.columnLarge}>
                      <View style={[styles.container, { marginRight: 10 }]}>
                        <View style={styles.columnSmall}>
                          <Text style={[styles.boldText, styles.dataInfo]}>{`$ ${aircraft?.idHullAmount || 0}`}</Text>
                        </View>
                        <View style={styles.columnSmall}>
                          <Text style={[styles.boldText, styles.dataInfo]}>{`$  ${aircraft?.idHullNIMDeductible || 0}`}</Text>
                        </View>
                        <View style={styles.columnSmall}>
                          <Text style={[styles.boldText, styles.dataInfo]}>{`$  ${aircraft?.idHullIMDeductible || 0}`}</Text>
                        </View>
                      </View>
                    </View>
                  </>
                ) : (
                  <>
                    <View style={styles.columnSmall}>
                      <Text style={styles.boldText}></Text>
                    </View>
                    <View style={styles.columnLarge}>
                      <Text style={styles.boldText}>SEE FORM SKWD GA 12 40 09 23 </Text>
                    </View>
                    <View style={styles.columnSmall}>
                      <Text style={styles.boldText}></Text>
                    </View>
                    <View style={styles.columnLarge}>
                      <View style={[styles.container, { marginRight: 10 }]}>
                        <View style={styles.columnSmall}>
                          <Text style={styles.boldText}></Text>
                        </View>
                        <View style={styles.columnSmall}>
                          <Text style={styles.boldText}></Text>
                        </View>
                        <View style={styles.columnSmall}>
                          <Text style={styles.boldText}></Text>
                        </View>
                      </View>
                    </View>
                  </>
                )}
              </View>
              <View style={[styles.container, { marginTop: '-15px' }]}>
                <View style={{ marginLeft: '400px' }}>
                  <Text>For additional deductibles see</Text>
                  <Text>endorsement no.</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.itemNumber}>6.</Text>
                <View>
                  <Text style={{ marginRight: 10, fontSize: 7 }}>
                    <Text style={styles.boldText}>LIABILITY AND MEDICAL PAYMENTS COVERAGE AND LIMITS OF COVERAGE:</Text>&nbsp; &nbsp;
                    Subject to the limitations and conditions described in your policy, the most we will pay under each coverage we provide
                    is shown below for each aircraft. WHERE NO AMOUNT IS SHOWN NO COVERAGE IS PROVIDED BY YOUR POLICY.
                  </Text>
                </View>
              </View>
              <View style={{ gap: '-2px' }}>
                <View style={styles.container}>
                  <View style={styles.columnSmall} />
                  <View style={styles.columnLarge}>
                    <Text>D. SINGLE LIMIT BODILY INJURY</Text>
                    <Text>AND PROPERTY DAMAGE</Text>
                  </View>
                  <View style={styles.columnLarge}>
                    <Text>DL. SINGLE LIMIT BODILY INJURY AND</Text>
                    <Text>PROPERTY DAMAGE</Text>
                  </View>
                  <View style={styles.columnLarge}>
                    <Text>E. MEDICAL EXPENSE COVERAGE</Text>
                  </View>
                </View>
                <View style={[styles.container, { marginTop: '-5px', fontSize: 7 }]}>
                  <View style={styles.columnSmall}>
                    <Text>Limit of Coverage</Text>
                  </View>
                  <View style={styles.columnLarge}>
                    <Text>__CLUDING PASSENGERS</Text>
                  </View>
                  <View style={styles.columnLarge}>
                    <Text>LIMITED PASSENGER COVERAGE</Text>
                  </View>
                  <View style={styles.columnLarge} />
                </View>
                <View style={[styles.container, { marginTop: '-5px' }]}>
                  <View style={styles.columnSmall}>
                    <Text>Each Person</Text>
                  </View>
                  <View style={[styles.columnLarge, { height: '5px', backgroundColor: 'black', marginTop: '5px' }]} />
                  <View style={[styles.columnLarge, { justifyContent: 'space-between', flexDirection: 'row' }]}>
                    <Text style={styles.boldText}>$</Text>
                    <Text style={[styles.boldText, styles.dataInfo]}>{`${aircraft?.PolicyOptionAircraftLiability?.perPerson || 0}`}</Text>
                  </View>
                  <View style={[styles.columnLarge, { justifyContent: 'space-between', flexDirection: 'row' }]}>
                    <Text style={styles.boldText}>$</Text>
                    <Text style={[styles.boldText, styles.dataInfo]}>{`${aircraft?.PolicyOptionAircraftMedical?.perPerson || 0}`}</Text>
                  </View>
                </View>
                <View style={[styles.container, { marginTop: '-5px' }]}>
                  <View style={styles.columnSmall}>
                    <Text>Each Occurence</Text>
                  </View>
                  <View style={[styles.columnLarge, { height: '5px', marginTop: '5px' }]} />
                  <View style={[styles.columnLarge, { justifyContent: 'space-between', flexDirection: 'row' }]}>
                    <Text style={styles.boldText}>$</Text>
                    <Text style={[styles.boldText, styles.dataInfo]}>{`${
                      aircraft?.PolicyOptionAircraftLiability?.perOccurence || 0
                    }`}</Text>
                  </View>
                  <View style={[styles.columnLarge, { justifyContent: 'space-between', flexDirection: 'row' }]}>
                    <Text style={styles.boldText}>$</Text>
                    <Text style={styles.boldText}>{`${aircraft?.PolicyOptionAircraftMedical?.perPerson || 0}`}</Text>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.itemNumber}>7.</Text>
                <View>
                  <Text style={{ marginRight: 10, fontSize: 7 }}>
                    <Text style={styles.boldText}>PREMIUMS:</Text>&nbsp; &nbsp; Your cost for each coverage we provide is shown below. WHERE
                    NO AMOUNT IS SHOWN NO COVERAGE IS PROVIDED BY THE POLICY.
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', alignItems: 'flex-end' }}>
                <View style={[styles.container, { width: '70%' }]}>
                  <View style={{ padding: 3, flex: 1 }}>
                    <Text>COVERAGE D</Text>
                  </View>
                  <View style={{ padding: 3, flex: 1 }}>
                    <Text>COVERAGE DL</Text>
                  </View>
                  <View style={{ padding: 3, flex: 1 }}>
                    <Text>COVERAGE E</Text>
                  </View>
                  <View style={{ padding: 3, flex: 1 }}>
                    <Text>COVERAGE F</Text>
                  </View>
                  <View style={{ padding: 3, flex: 1 }}>
                    <Text>COVERAGE G</Text>
                  </View>
                  <View style={{ padding: 3, flex: 1 }}>
                    <Text>TOTAL FOR</Text>
                    <Text>AIRCRAFT</Text>
                  </View>
                </View>
                <View style={[styles.container, { width: '70%' }]}>
                  <View style={{ padding: 3, flex: 1 }}>
                    <Text></Text>
                  </View>
                  <View style={{ padding: 3, flex: 1 }}>
                    <Text style={styles.boldText}>$863.00</Text>
                  </View>
                  <View style={{ padding: 3, flex: 1 }}>
                    <Text style={styles.boldText}>$65.00</Text>
                  </View>
                  <View style={{ padding: 3, flex: 1 }}>
                    <Text style={styles.boldText}>$1,140.00</Text>
                  </View>
                  <View style={{ padding: 3, flex: 1 }}>
                    <Text style={styles.boldText}>$1,710.00</Text>
                  </View>
                  <View style={{ padding: 3, flex: 1 }}>
                    <Text style={styles.boldText}>$3,778.00</Text>
                  </View>
                </View>
              </View>
              <View style={[styles.contentContainer, { marginTop: '-10px' }]}>
                {/* Left Column (Numbered List) */}
                <View style={[styles.itemColumn, { width: '70%' }]}>
                  <View style={{ flexDirection: 'column', padding: '0 70px 0 0' }}>
                    {/* Item 1 */}
                    <View style={{ flexDirection: 'row' }}>
                      <Text style={styles.itemNumber}>8.</Text>
                      <View style={{ marginLeft: 5 }}>
                        <Text style={styles.boldText}>ENDORSEMENTS & FORMS ATTACHED WHEN POLICY ISSUED</Text>
                      </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginLeft: 35 }}>
                      <Text style={{ marginRight: 15 }}>ENDT NOS:</Text>
                      <Text style={styles.boldText}>2</Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginLeft: 35 }}>
                      <Text style={{ marginRight: 30 }}>FORMS:</Text>
                      <Text style={styles.boldText}>{formIds.join(';')}</Text>
                    </View>
                  </View>
                </View>

                {/* Right Column (Address) */}
                <View style={[styles.addressColumn, { width: '35%', fontSize: 7 }]}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View>
                      <Text>COVERAGE ID</Text>
                      <Text>CONTINUATION PAGE(S):</Text>
                    </View>
                    <Text style={[styles.boldText, { marginTop: 10 }]}>$100,000</Text>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>ENDT PREMS.</Text>
                    <Text style={[styles.boldText]}>$15.14</Text>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={{ marginBottom: 5 }}>ENDT PREMS.</Text>
                    <Text style={[styles.boldText]}>$100</Text>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={{ marginBottom: 5 }}>Taxes</Text>
                    <Text style={[styles.boldText, styles.dataInfo]}>{aircraft?.taxesFees}</Text>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={[styles.boldText, { fontSize: 8 }]}>TOTAL POLICY PREMIUM</Text>
                    <Text style={styles.boldText}>$3,055.20</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <FooterPDF sub={true} page={1} endPage={2} form={formNumber} />
        </Page>
        <Page size="LETTER" style={styles.page}>
          <View style={styles.content}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.itemNumber}>9.</Text>
              <View>
                <Text style={{ marginRight: 10, fontSize: 7 }}>
                  <Text style={styles.boldText}>REQUIREMENTS FOR THE PILOT FLYING THE AIRCRAFT:</Text>&nbsp; &nbsp; The Aircraft must be
                  operated in flight only by a person having the minimum qualifications shown below. The pilot must have a current and valid
                  (1) medical certificate, (2) flight review and (3) pilot certificate with necessary ratings, each as required by the FAA
                  for each flight. THERE IS NO COVERAGE IF THE PILOT DOES NOT MEET THESE REQUIREMENTS.
                </Text>
              </View>
            </View>
            <View style={styles.centeredText}>
              <Text style={styles.boldText}>As Endorsed</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.itemNumber}>10.</Text>
              <View style={{ gap: 5 }}>
                <View style={{ marginRight: 10, fontSize: 7 }}>
                  <Text style={styles.boldText}>THE USE OF THE AIRCRAFT:</Text>
                </View>
                <View>
                  <Text>
                    The aircraft will be used for your pleasure and business related purposes where no charge is made for such use and also
                    may be used for Other Uses described below:
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ marginRight: 15 }}>Other Uses:</Text>
                  <Text style={styles.boldText}>NO EXCEPTIONS</Text>
                </View>
              </View>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.itemNumber}>11.</Text>
              <View>
                <Text style={{ marginRight: 10, fontSize: 7 }}>
                  <Text style={styles.boldText}>ADDITIONAL INTERESTS:</Text>&nbsp; &nbsp; Payment for Aircraft Physical Damage or Loss under
                  Coverage F or G will be made to you and the following lienholder:
                </Text>
              </View>
            </View>
            <View style={{ gap: 2, marginTop: '-10px' }}>
              <View style={[styles.container]}>
                <View style={styles.columnLarge} />
                <View style={styles.columnLarge}>
                  <Text>LIENHOLDER INFORMATION</Text>
                </View>
                <View style={styles.columnSmall}>
                  <Text>Lienholder</Text>
                  <Text>Interest Cov.</Text>
                </View>
                <View style={styles.columnSmall}>
                  <Text>Loan</Text>
                  <Text>Balance</Text>
                </View>
              </View>
              <View style={[styles.container, { marginTop: '-10px' }]}>
                <View style={styles.columnLarge}>
                  <Text>Name</Text>
                  <Text>Address</Text>
                </View>
                <View style={[styles.columnLarge, { textAlign: 'left' }]}>
                  <Text style={[styles.boldText, styles.dataInfo]}>
                    {aircraft?.AircraftDetailsLienholder[0]?.LienholderDetails?.name || ''}
                  </Text>
                </View>
                <View style={styles.columnSmall}>
                  <Text style={styles.boldText}>yes</Text>
                </View>
                <View style={styles.columnSmall}>
                  <Text style={styles.boldText}>yes</Text>
                </View>
              </View>
              <View style={[styles.container, { marginTop: '-10px' }]}>
                <View style={styles.columnLarge} />
                <View style={styles.columnLarge}>
                  <Text style={[styles.dataInfo]}>
                    {`${[
                      aircraft?.AircraftDetailsLienholder[0]?.LienholderDetails?.address1 || '',
                      aircraft?.AircraftDetailsLienholder[0]?.LienholderDetails?.address2 || '',
                      aircraft?.AircraftDetailsLienholder[0]?.LienholderDetails?.city || '',
                      aircraft?.AircraftDetailsLienholder[0]?.LienholderDetails?.state || '',
                      aircraft?.AircraftDetailsLienholder[0]?.LienholderDetails?.zip || ''
                    ]
                      .filter(Boolean)
                      .join(', ')} `}
                  </Text>
                </View>
                <View style={styles.columnSmall} />
                <View style={styles.columnSmall} />
              </View>
            </View>
            <View style={styles.signatureContainer}>
              {/* Left Section */}
              <View style={styles.leftSection}>
                <Text style={styles.companyName}>Acceleration Aviation Underwriters, Inc.</Text>
                <Text style={styles.subText}>Aviation Managers</Text>
                <Text style={styles.dateIssued}>Date Issued: {formatDate().format('MM/DD/YYYY')}</Text>
              </View>

              {/* Right Section - Signature */}
              <View style={styles.rightSection}>
                <Text style={styles.signatureLine} />
                <Text style={styles.authorizedRep}>Authorized Representative</Text>
                <View style={[styles.forCompanyUse, { marginTop: 30 }]}>
                  <Text style={[styles.boxedText, styles.dataInfo]}>{policy?.agencyCode}</Text>
                  <Text style={styles.boxedText}>13.00</Text>
                  <Text style={styles.emptyBox}></Text>
                  <Text style={styles.emptyBox}></Text>
                </View>
                <Text style={styles.footerText}>FOR COMPANY USE ONLY</Text>
              </View>
            </View>

            {/* Bottom Section - For Company Use Only */}
          </View>
          <FooterPDF sub={true} page={2} endPage={2} form={formNumber} />
        </Page>
      </>
    );
  } else {
    return null;
  }
};

export default CoverageIdentificationPage;
