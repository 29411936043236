import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },
  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },

  nestedListContainer: {
    paddingLeft: 15,
    gap: '-6px' // Indent nested items
  },
  nestedListItemParagraph: {
    top: '-12px'
  },
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' }
  ]
});

const TerrorismCoverage = ({ exclusion = false, formNumber }) => (
  <Page size="letter" style={styles.page}>
    <View style={[styles.header, { fontSize: 8 }]}>
      <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
    </View>
    <View style={styles.header}>
      <Text style={styles.boldText}>{`${exclusion ? 'Exclusion of ' : 'Cap on Losses from '} Certified Acts of Terrorism`} </Text>
    </View>
    <View style={styles.content}>
      <View>
        <Text>
          The following is added to <Text style={styles.boldText}>your</Text> Policy:
        </Text>
      </View>
      <View style={styles.listContainer}>
        <View style={{ marginBottom: 5, fontSize: 9 }}>
          <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS</Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            1.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'} Words and Phrases
          </Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>
                W.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Certified Act of Terrorism.</Text> A{' '}
                <Text style={styles.boldText}>Certified Act of Terrorism</Text> means an act that is certified by the Secretary of the
                Treasury, in concurrence with the Secretary of State and the Attorney General of the United States, to be an act of
                terrorism pursuant to the Federal Terrorism Risk Insurance Act. The criteria contained in the Federal Terrorism Risk
                Insurance Act for a <Text style={styles.boldText}>Certified Act of Terrorism</Text> includes the following:
              </Text>
              <View style={[styles.nestedListContainer, { marginTop: '-10px' }]}>
                <View>
                  <Text style={styles.listItemHeading}>
                    i.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.listItemParagraph, styles.nestedListItemParagraph]}>
                    The act resulted in insured losses in excess of $5 million in the aggregate, attributable to all types of insurance
                    subject to the Federal Terrorism Risk Insurance Act; and
                  </Text>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>
                    ii.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.listItemParagraph, styles.nestedListItemParagraph]}>
                    The act is a violent act or an act that is dangerous to human life, property or infrastructure and is committed by an
                    individual or individuals as part of an effort to coerce the civilian population of the United States or to influence
                    the policy or affect the conduct of the United States Government by coercion.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        {!exclusion ? (
          <>
            <View style={[styles.boldText, { marginBottom: 5, fontSize: 9 }]}>
              <Text>Part Three. LIABILITY TO OTHERS</Text>
            </View>
            <View>
              <Text>
                If aggregate insured losses attributable to <Text style={styles.boldText}>Certified Act(s) of Terrorism</Text> $100 billion
                in a Program Year (January 1 through December 31) and <Text style={styles.boldText}>we</Text> have met{' '}
                <Text style={styles.boldText}>our</Text> insurer deductible under the Federal Terrorism Risk Insurance Act,{' '}
                <Text style={styles.boldText}>we</Text> shall not be liable for the payment of any portion of the amount of such losses that
                exceeds $100 billion, and in such case insured losses up to that amount are subject to pro rata allocation in accordance
                with procedures established by the Secretary of the Treasury.
              </Text>
            </View>
          </>
        ) : (
          <>
            <View style={{ marginBottom: 5, fontSize: 9 }}>
              <Text>Part Two AIRCRAFT PHYSICAL DAMAGE COVERAGE:</Text>
            </View>
            <View style={styles.listItem}>
              <Text style={styles.listItemHeading}>
                4.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'} What <Text style={styles.boldText}>We</Text> Will Not Pay
              </Text>
              <View style={styles.nestedListContainer}>
                <View>
                  <Text style={styles.listItemHeading}>
                    i.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    any direct physical loss or damage arising, directly or indirectly, out of a{' '}
                    <Text style={styles.boldText}>certified act of terrorism.</Text>
                  </Text>
                </View>
              </View>
            </View>
          </>
        )}
      </View>
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber}/>
  </Page>
);

export default TerrorismCoverage;
