import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import EndingComponent from '../Components/EndingComponent';
import BeginningComponent from '../Components/BeginingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const TennesseeEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <BeginningComponent state="Tennessee" />
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text>
                  This policy can be canceled at any time for nonpayment of premium by mailing or delivering a notice of cancellation to the
                  first Named Insured at least 10 days before the effective date of the cancellation;
                </Text>
              </View>
            </View>
            <View>
              <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text>
                  If this policy has been in effect less than 60 days and is not a renewal we may cancel at any time by mailing or
                  delivering a notice of cancellation to the first Named Insured at least 30 days before the effective date of cancellation;
                  or
                </Text>
              </View>
            </View>
            <View>
              <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text>
                  If this policy has been in effect for 60 days or more, or if this is a renewal of a policy we issued, we may cancel at any
                  time by mailing or delivering a notice of cancellation to the first Named Insured at least 30 days before the effective
                  date of cancellation, but only for one or more of the following reasons:
                </Text>
              </View>
              <View style={[styles.nestedListContainer, { gap: 1 }]}>
                <View>
                  <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>
                      <Text style={styles.boldText}>You</Text> are convicted of a crime increasing any hazard insured against;
                    </Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>
                      Discovery of fraud or material misrepresentation by <Text style={styles.boldText}>you</Text> or{' '}
                      <Text style={styles.boldText}>your</Text> representative in obtaining this insurance or in pursuing a claim under this
                      policy;
                    </Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>Failure to comply with written loss control recommendations;</Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>
                      Material change in the risk which increases the risk of loss after <Text style={styles.boldText}>we</Text> issued or
                      renewed the insurance coverage;
                    </Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>(5) &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>
                      Determination by the Insurance Commissioner of the State of Tennessee that the continuation of the policy would
                      jeopardize <Text style={styles.boldText}>our</Text> solvency or would place <Text style={styles.boldText}>us</Text> in
                      violation of the insurance laws of the State of Tennessee.
                    </Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>(6) &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>
                      <Text style={styles.boldText}>Your</Text> violation or breach any of the policy terms or conditions; or
                    </Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>(7) &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>Other reasons that are approved by the Insurance Commissioner of the State of Tennessee.</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>Notice of cancellation will state the reason for cancellation.</Text>
          </View>
          <EndingComponent extra={false} />
          <View style={styles.listItemParagraph}>
            <Text>
              Whenever an insurance policy, which is financed with a premium finance company, is cancelled, the{' '}
              <Text style={styles.boldText}>we</Text> shall return, within 30 days after the effective date of the cancellation, whatever
              gross unearned premiums are due under the insurance policy directly to the premium finance company for the account of the
              first Named Insured.
            </Text>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>
              If <Text style={styles.boldText}>we</Text> decide not to renew this policy <Text style={styles.boldText}>we</Text> will mail
              or deliver written notice of nonrenewal to the first Named Insured and agent at least 60 days before the expiration date
              unless:
            </Text>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>We offered to issue a renewal policy; or</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>You have obtained replacement coverage or have agreed in writing to obtain replacement coverage.</Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Any notice will be mailed or delivered to the first Named Insured`s last known mailing address known to{' '}
                <Text style={styles.boldText}>us</Text>. If notice is mailed, proof of mailing is sufficient proof of notice.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} form={formNumber}/>
    </Page>
  </>
);

export default TennesseeEndorsement;
