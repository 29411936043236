import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const WestVirginiaEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>West Virginia Change Endorsement</Text>
      </View>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE,</Text> Paragraph 6, When{' '}
            <Text style={styles.boldText}>We</Text> Will pay, to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>6. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                When <Text style={styles.boldText}>we</Text> Will Pay
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will pay for loss or damage to the <Text style={styles.boldText}>aircraft</Text>{' '}
                covered by <Text style={styles.boldText}>your</Text> policy.
              </Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <View style={[styles.listItemParagraph, { marginBottom: '-5px' }]}>
                  <Text style={{ fontStyle: 'italic' }}>Damaged Aircraft</Text>
                </View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    within 15 days after <Text style={styles.boldText}>you</Text> have given <Text style={styles.boldText}>us</Text> a sworn
                    Proof of Loss statement and <Text style={styles.boldText}>you</Text> and <Text style={styles.boldText}>we</Text> agree
                    on the amount.
                  </Text>
                </View>
              </View>
              <View>
                <View style={[styles.listItemParagraph, { marginBottom: '-5px' }]}>
                  <Text style={{ fontStyle: 'italic' }}>Destroyed Aircraft</Text>
                </View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    within 15 days after <Text style={styles.boldText}>you</Text> have given <Text style={styles.boldText}>us</Text> a sworn
                    Proof of Loss statement and <Text style={styles.boldText}>you</Text> have delivered the{' '}
                    <Text style={styles.boldText}>aircraft</Text> log books, airworthiness certificate and a bill of sale transferring clear
                    title to the <Text style={styles.boldText}>aircraft.</Text>
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={1} form={formNumber}/>
    </Page>
  </>
);

export default WestVirginiaEndorsement;
