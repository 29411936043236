import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
// import EndingComponent from '../Components/EndingComponent';
import BeginningComponent from '../Components/BeginingComponent';
import EndingComponent from '../Components/EndingComponent';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },
  itemParagraph: {
    marginTop: '0px'
  },
  nestedListContainer: {
    paddingLeft: 15,
    gap: 10 // Indent nested items
  },
  nestedListItemParagraph: {
    marginLeft: 15,
    marginTop: '-11px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const IowaEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <BeginningComponent state="Iowa" />
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={[styles.nestedListContainer, { marginTop: '-10px' }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any time for nonpayment of premium by mailing or delivering a notice of cancellation to
                    the first Named Insured at least 10 days before the effective date of the cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect for less than 60 days it may be canceled at any time by mailing or delivering a notice
                    of cancellation to the first Named Insured with at least 30 days notice for any other reason before the effective date
                    of cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If the policy has been in effect 60 days or more, it may be canceled by mailing or delivering a notice of cancellation
                    to the first Named Insured with at least 30 days notice for any other reason before the effective date of cancellation
                    and only for one of the following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        This policy was issued because of material misrepresentation or fraud made by{' '}
                        <Text style={styles.boldText}>you</Text> or with <Text style={styles.boldText}>your</Text> knowledge in obtaining
                        the policy, when renewing the policy, or in presenting a claim under the policy;
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                        <Text>
                          Acts or omissions by <Text style={styles.boldText}>you</Text> that substantially change or increase the risk
                          insured;
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                        <Text>
                          Determination by the Commissioner of Insurance of the State of Iowa that the continuation of the policy would
                          jeopardize <Text style={styles.boldText}>our</Text> solvency or would place us in violation of the insurance laws
                          of the State of Iowa;
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                        <Text>
                          <Text style={styles.boldText}>You</Text> have acted in a manner <Text style={styles.boldText}>you</Text> knew or
                          should havae known was in violation or breach of a policy term or condition; or
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>(5) &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                        <Text>
                          Loss of reinsurance which provides coverage to us for a significant portion of the underlying risk insured, but
                          only if the Commissioner of the State of Iowa determines that such cancellation is justified.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will compute the premium earned by us based on the percentage of the original policy
                period that <Text style={styles.boldText}>we</Text> provided coverage.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> cancel <Text style={styles.boldText}>your</Text> policy,{' '}
                <Text style={styles.boldText}>we</Text> will return any premium <Text style={styles.boldText}>you</Text> have paid that{' '}
                <Text style={styles.boldText}>we</Text> have not earned, but making a refund is not a condition of cancellation.
              </Text>
            </View>
            <EndingComponent />
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> decide not to renew this policy, increase in premium or deductible of 25% or
                more, or a material reduction in limits of coverage, <Text style={styles.boldText}>we</Text> will mail or deliver written
                notice of non-renewal, stating the reasons for non-renewal, to the first Named Insured at least 45 days prior to the
                expiration of the policy, except if:
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>We</Text> have offered to issue a renewal policy; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>You</Text> have failed to pay a premium due to any advance premium required by us for
                    renewal.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Any notice will be mailed or delivered to the first named Insured`s last mailing address known to{' '}
                <Text style={styles.boldText}>us.</Text> If notice is mailed, proof of mailing will be sufficient proof of notice.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} form={formNumber}/>
    </Page>
  </>
);

export default IowaEndorsement;
