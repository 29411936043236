import { CardContent, Grid, Typography, TextField, Divider } from '@mui/material';
import { gridSpacing } from 'config.js';
const PolicyDuplicateDetails = ({ policy }) => {
  return (
    <>
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item sm zeroMinWidth>
            <Typography component="div" variant="h5">
              Named Insured Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={policy?.customerOrganizationName || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Insured Type"
                    variant="outlined"
                    value={policy?.['Options_Policy_customerOrganizationOrgTypeToOptions']?.val || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Address 1"
                    variant="outlined"
                    value={policy?.customerOrganizationAddress1 || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Address 2"
                    variant="outlined"
                    value={policy?.customerOrganizationAddress2 || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={4} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="City"
                    variant="outlined"
                    value={policy?.customerOrganizationCity || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={4} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="State"
                    variant="outlined"
                    value={policy?.customerOrganizationState || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={4} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Zipcode"
                    variant="outlined"
                    value={policy?.customerOrganizationZip || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item sm zeroMinWidth>
            <Typography component="div" variant="h5">
              Customer Contact
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={policy?.customerContactName || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Title"
                    variant="outlined"
                    value={policy?.customerContactTitle || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={policy?.customerContactEmail || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Phone"
                    variant="outlined"
                    value={policy?.customerContactPhone || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item sm zeroMinWidth>
            <Typography component="div" variant="h5">
              Agency
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Address 1"
                    variant="outlined"
                    value={policy?.agencyAddress1 || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Address 2"
                    variant="outlined"
                    value={policy?.agencyAddress2 || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="CIty"
                    variant="outlined"
                    value={policy?.agencyCity || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="State"
                    variant="outlined"
                    value={policy?.agencyState || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Zipcode"
                    variant="outlined"
                    value={policy?.agencyZip || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item sm zeroMinWidth>
            <Typography component="div" variant="h5">
              Agent
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={policy?.agentDisplayName || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={policy?.agentEmail || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    value={policy?.agentFirstName || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography variant="subtitle2">
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    value={policy?.agentLastName || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default PolicyDuplicateDetails;
