import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import BeginningComponent from '../Components/BeginingComponent';
import EndingComponent from '../Components/EndingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const NorthCarolinaEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <BeginningComponent state="North Carolina" />
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If the policy has been in effect for less than 60 days, <Text style={styles.boldText}>we</Text> may cancel this policy
                    by mailing or delivering to the first Named Insured wrtitten notice of cancellation at least:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        15 days before the effective date of cancellation if <Text style={styles.boldText}>we</Text> cancel for nonpayment
                        of premium; or
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        30 days before the effective date of cancellation if <Text style={styles.boldText}>we</Text> cancel for any other
                        reason.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>We</Text> will mail or deliver <Text style={styles.boldText}>our</Text> notice to the
                    first Named Insured and agent at the last address known to <Text style={styles.boldText}>us.</Text>
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect for 60 days or more, or is a renewal or continuation of a policy{' '}
                    <Text style={styles.boldText}>we</Text> issued, <Text style={styles.boldText}>we</Text> may cancel this policy prior to
                    the expiration of the policy term or anniverary date stated in the policy only for one or more of the following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Nonpayment of premium;</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        An act or omission by the insured or his or her representative that constitutes material misrepresentation or
                        nondisclosure of a material fact in obtaining this policy, continuing this policy or presentign a claim under this
                        policy;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Increase hazard or material change in the risk assumed that could not have been reasonably contemplated by the
                        parties at the time of assumption of the risk;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Substantial breach of contractual duties, conditions or warranties that materially affects the insurabillity of the
                        risk;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(5) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        A fraudulent act against <Text style={styles.boldText}>us</Text> by the insured or his or her representative that
                        materially affects the insurabillity of the risk;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(6) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Willful failure by the insured or his or her representative to institute reasonable loss control measures that
                        materially affect the insurabillity of the risk after written notice by <Text style={styles.boldText}>us;</Text>
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(7) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Loss of facultative reinsurance, or loss of or substantial changes in applicable reinsurance as provided in G.S.
                        58-41-30;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(8) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Conviction of the insured of a crime arising out of acts that materially affect the insurabillity of the risk;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(9) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        A determination by the Commissioner of Insurance that the continuation of the policy would place{' '}
                        <Text style={styles.boldText}>us</Text> in violation of the laws of North Carolina; or
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(10) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        <Text style={styles.boldText}>We</Text> fail to meet the requirements contained in our corporate charter, articles
                        of incorporation or by-laws when <Text style={styles.boldText}>we</Text>
                        are a company organized for the sole purpose of providing members of an organization with insurance coverage in
                        North Carolina.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will mail or deliver written notice of cancellation to the first Named Insured at
                least:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    15 days before the effective date of cancellation if <Text style={styles.boldText}>we</Text> cancel for nonpayment of
                    premium; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    30 days before the effective date of cancellation if <Text style={styles.boldText}>we</Text> cancel for any other
                    reason.
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    Cancellation for nonpayment of premium will not become effective if <Text style={styles.boldText}>you</Text> pay the
                    premium amount due before the effective date of cancellation.
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>d. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>We</Text> may also cancel this policy for any reason not stated above provided{' '}
                    <Text style={styles.boldText}>we</Text> obtain <Text style={styles.boldText}>your</Text> prior written consent.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>Any notice of cancellation will state the precise reason for cancellation.</Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <EndingComponent extra={true} />
            <View style={styles.listItemParagraph}>
              <Text style={styles.boldText}>Nonrenewal</Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 5 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If <Text style={styles.boldText}>we</Text> elect not to renew this policy, decrease coverage, increase deductibles, or
                    increase premium, <Text style={styles.boldText}>we</Text> will mail or deliver to the first Named Insured shown in the
                    Coverage Indetification Page written notice of nonrenewal at least 45 days prior to the:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Expiration of the policy if this policy has been written for one year or less; or</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Anniversary date of the policy if this policy has been written for more than one year or for an indefinite term.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>We</Text> need not mail or deliver the notice of nonrenewal if{' '}
                    <Text style={styles.boldText}>you</Text> have:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Insured property covered under this policy under any other insurance policy;</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Accepted replacement coverage; or</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Requested or agreed to nonrenewal of this policy.</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>If notie is mailed, proof of mailing will be sufficient proof of notice.</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>d. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>The written notice of nonrenewal will:</Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Be mailed or delivered to the first Named Insured and any designated mortgagee or loss payee at their addresses
                        shown in the policy, or if not indicated in the policy, at their lst known addresses; and
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>State the reason or reasons for nonrenewal</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} form={formNumber}/>
    </Page>
  </>
);

export default NorthCarolinaEndorsement;
