import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },
  itemParagraph: {
    marginTop: '0px'
  },
  nestedListContainer: {
    paddingLeft: 15,
    gap: 10 // Indent nested items
  },
  nestedListItemParagraph: {
    marginLeft: 15,
    marginTop: '-11px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const FloridaEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Florida Change Endorsement</Text>
      </View>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement adds the following to <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS,</Text>{' '}
            Paragraph 8, Canceling the Policy, to read as follows.
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>8. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Canceling the Policy</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>You</Text> may cancel <Text style={styles.boldText}>your</Text> policy at ay time by telling{' '}
                <Text style={styles.boldText}>us</Text> in writing in advance, through{' '}
                <Text style={styles.boldText}>our Aviation Managers,</Text> of the date <Text style={styles.boldText}>you</Text>
                want <Text style={styles.boldText}>your</Text> coverage to end. If <Text style={styles.boldText}>you</Text> cancel the
                policy, <Text style={styles.boldText}>we</Text> will return 90% of the unearned premium{' '}
                <Text style={styles.boldText}>you</Text> have paid.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> or <Text style={styles.boldText}>our Aviation Managers</Text> can cancel this policy
                as follows:
              </Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    The policy can be canceled at any time for nonpayment of premium by mailing or delivering a notice of cancellation to
                    the first Named Insured at least 10 days for non payment of premium or 30 days for all other reasons before the
                    effective date of teh cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If the policy has been in effect less than 90 days, it may be canceled at any time by mailing or delivering a notice of
                    cancellation to the first Insured at least 10 days for non payment of premium or 30 days for all other reasons before
                    the effective date of cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If the policy has been in effect 90 days or more, it may be cancelled at any time by mailing or delivering a notice of
                    cancellation to the first Named Insured at least 45 days before the effective date of cancellation and only for one of
                    the following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>The policy was issued because of material misrepresentation of fact.</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Failure to comply with underwriting requirements within 90 days of the effective date.</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>A substantial change in the risk which increases the risk of loss during the policy period.</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>All insureds under this Airrcraft Policy for a given class of insureds are being cancelled.</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Proof of mailing or delivery of the notice is sufficient proof of notice. <Text style={styles.boldText}>We</Text> will
                compute the premium earned by <Text style={styles.boldText}>us</Text> based on the percentage of the original policy that{' '}
                <Text style={styles.boldText}>we</Text> provided coverage.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will return to <Text style={styles.boldText}>you</Text> any premium that{' '}
                <Text style={styles.boldText}>you</Text> have paid that <Text style={styles.boldText}>we</Text> have not earned, but making
                the refund is not a condition of cancellation.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                However, if <Text style={styles.boldText}>we</Text> pay or have paid the <Text style={styles.boldText}>agreed value</Text>{' '}
                of the <Text style={styles.boldText}>aircraft,</Text> less any applicable deductible under the Aircraft Physical Damage
                coverage, <Text style={styles.boldText}>we</Text> are entitled to the total Aircraft Physical Damage premium shown on the
                Coverage Identification Page for the <Text style={styles.boldText}>aircraft</Text> on which{' '}
                <Text style={styles.boldText}>we</Text> made or make payment. <Text style={styles.boldText}>You</Text> agree to pay any
                premium that may be due or permit <Text style={styles.boldText}>us</Text> to deduct such premium from{' '}
                <Text style={styles.boldText}>our</Text> loss payment.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                No notice of non-renewal or renewal with less favorable terms shall be effective unless mailed or delivered by{' '}
                <Text style={styles.boldText}>us</Text> to <Text style={styles.boldText}>you</Text> at least 45 days prior to the date of
                the proposed action.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Any notice will be mailed or delivered to the first Named Insured`s last mailing address known to{' '}
                <Text style={styles.boldText}>us.</Text> If notice is mailed, proof of mailing will be sufficient proof of notice.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={1} form={formNumber}/>
    </Page>
  </>
);

export default FloridaEndorsement;
