import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import BeginningComponent from '../Components/BeginingComponent';
import EndingComponent from '../Components/EndingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const OreganEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <BeginningComponent state="Oregan" />
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any time for nonpayment of premium by mailing or delivering a notice of cancellation to
                    the first Named Insured at least 10 working days before the effective date of the cancellation;
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect less than 60 days <Text style={styles.boldText}>we</Text> may cancel at any time by
                    mailing or delivering a notice of cancellation to the first Named Insured at least 30 days before the effective date of
                    cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If the P?licy has been in effect for 60 days or more, it may be canceled by mailing or delivering a notice of
                    cancellation to the first Named Insured with at least 10 days notice for nonpayment of premium, or with at least 30 days
                    notice for any for one or more of the following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>The policy was obtained by material misrepresentation.</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        There has been a substantial change in the risk that we originally assumed, except to the extent that we should have
                        foreseen the change or considered the risk in writing this policy.
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>There have been substantial breaches of contractual duties, conditions or warranties.</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        If the Insurance Commission determines that the continuation of the policy would place the insurer in violation of
                        any state`s insurance laws or would jeopardize <Text style={styles.boldText}>our</Text> solvency.
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(5) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Loss of or decrease in <Text style={styles.boldText}>our</Text> reinsurance.
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(6) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Any other reason, as approved by the Commissioner.</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <EndingComponent extra={false} />
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> elect not to renew this policy, <Text style={styles.boldText}>we</Text> will mail
                or deliver written notice of nonrenewal to the first Named Insured at least 45 days before the expiration date of the
                policy.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Any notice will be mailed or delivered to the first Named Insured`s last known mailing address known to us. Any notice of
                cancellation shall state the actual reason for the action taken. If notice is mailed, proof of mailing or delivery of any
                notice is sufficient proof of notice.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>13. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Legal Action Against Us</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                No legal action shall be brought against us until the policy provisions have been complied with fully. No one shall have the
                right to:
              </Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>join us as a party to any legal action brought against you or someone we protect; or</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>bring us into any legal action to determine your liability or the liability of someone we protect.</Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text style={{ color: 'red', textDecoration: 'underline' }}>
                Any legal action brought against us for aircraft physical damage resulting from fire must be initiated with 24 months after
                the loss.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part Three. LIABLITY TO OTHERS</Text>, Paragraph 5, Additional Protection
            b. Pay Expenses, to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Pay Expenses</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Pay the expenses of court costs incurred in, claims or legal actions <Text style={styles.boldText}>we</Text> defend. If
                settlement is not made within six months from the date proof of loss is filed with us and an action is brought in any court
                of Oregon upon this policy and the plaintiff`s recovery exceeds the amount of any tender made by the defendant in such
                action, a reasonable amount to be fixed by the court as attorney fees shall be taxed as part of the costs of the action and
                any appeal unless the <Text style={styles.boldText}>you</Text> and <Text style={styles.boldText}>us</Text> agree to binding
                arbitration. If arbitration occurs, it will occur in the State of Oregon.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part Three. LIABLITY TO OTHERS</Text>, Paragraph 5, Additional Protection
            b.g. <Text style={{ fontStyle: 'italic' }}>Bankruptcy of the Insured, </Text>
            is added:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>g. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text style={{ fontStyle: 'italic' }}>Bankruptcy of the Insured,</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Bankruptcy or insolvency of the insured shall not relieve us of any of our obligations under this policy. If any person or
                legal representative of the person shall obtain final judgment against the insured because of any such injuries, and
                execution thereon is returned unsatisfied by reason of bankruptcy, insolvency or any other cause, or if such judgment is not
                satisfied within 30 days after it is rendered, then such person or legal representatives of the person may proceed against
                us to recover the amount of such judgment, either at law or in equity, but not exceeding the limit of this policy applicable
                thereto.
              </Text>
            </View>
          </View>
        </View>
        <Text>
          This endorsement changes <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE</Text>, Paragraph 6, When{' '}
          <Text style={styles.boldText}>We</Text> Will Pay, to read as follows:
        </Text>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>6. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                When <Text style={styles.boldText}>We</Text> Will Pay
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will pay for loss of or damage to the <Text style={styles.boldText}>aircraft</Text>{' '}
                covered by your policy:
              </Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <View style={[styles.listItemParagraph, { marginBottom: '-5px' }]}>
                  <Text style={{ fontStyle: 'italic' }}>Damaged Aircraft</Text>
                </View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    within 15 days after <Text style={styles.boldText}>you</Text> have given us a sworn Proof of Loss statement and{' '}
                    <Text style={styles.boldText}>you</Text> and <Text style={styles.boldText}>we</Text> agree on the amount.
                  </Text>
                </View>
              </View>
              <View>
                <View style={[styles.listItemParagraph, { marginBottom: '-5px' }]}>
                  <Text style={{ fontStyle: 'italic' }}>Destroyed Aircraft</Text>
                </View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    within 15 days after <Text style={styles.boldText}>you</Text> have given us a sworn Proof of Loss statement and{' '}
                    <Text style={styles.boldText}>you</Text> have delivered the <Text style={styles.boldText}>aircraft</Text> log books,
                    airworthiness certificate and a bill of sale transferring clear title to the{' '}
                    <Text style={styles.boldText}>aircraft</Text>.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} form={formNumber}/>
    </Page>
  </>
);

export default OreganEndorsement;
