import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import EndingComponent from '../Components/EndingComponent';
import BeginningComponent from '../Components/BeginingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const MichiganEndorsement = ({formNumber}) => (
  <Page style={styles.page} size="LETTER">
    <BeginningComponent state="Michigan" />
    <View style={styles.content}>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <View style={[styles.nestedListContainer, {marginTop: '-10px'}]}>
            <View>
              <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text>
                  This policy can be canceled at any time for nonpayment of premium or delivering a notice of cancellation to the first
                  Named Insured at least 10 days before the effective date of cancellation; or
                </Text>
              </View>
            </View>
            <View>
              <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text>
                  This policy can be canceled, for reasons other than nonpayment of premium, by mailing or delivering a notice of
                  cancellation to the first Named Insured at least 30 days before the effective date of cancellation;
                </Text>
              </View>
            </View>
          </View>
          <EndingComponent extra={false} />
          <View style={styles.listItemParagraph}>
            <Text>
              If <Text style={styles.boldText}>we</Text> choose not to renew this policy, <Text style={styles.boldText}>we</Text> will mail
              or deliver a notice of nonrenewal to the first Named Insured at least 45 days before the effective date of cancellation.
            </Text>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>
              Any notice will be mailed or delivered to the first Named Insured at the address last known to{' '}
              <Text style={styles.boldText}>us</Text> or <Text style={styles.boldText}>our</Text> authorized agent. If notice is mailed,
              proof of mailing will be sufficient proof of notice.
            </Text>
          </View>
        </View>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber}/>
  </Page>
);

export default MichiganEndorsement;
