import { CoverPDF } from './Cover';
import { TableOfContents } from './TableOfContents';
import { Part1PDF } from './Part1';
import { Part2PDF } from './Part2';
import { Part3PDF } from './Part3';
import { Part4PDF } from './Part4';
import { Part5PDF } from './Part5';
const InsurancePolicyPDF = ({formNumber}) => {
  return (
    <>
      <CoverPDF formNumber={formNumber}/>
      <TableOfContents formNumber={formNumber}/>
      <Part1PDF formNumber={formNumber}/>
      <Part2PDF formNumber={formNumber}/>
      <Part3PDF formNumber={formNumber}/>
      <Part4PDF formNumber={formNumber}/>
      <Part5PDF formNumber={formNumber}/>
    </>
  );
};

export default InsurancePolicyPDF;
