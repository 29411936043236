import { Dialog, DialogTitle, DialogContent, Grid, TextField, DialogContentText, DialogActions, Button, Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { gridSpacing } from 'config.js';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const PolicyFormDialog = ({ actionType, open, handleClosedialog, formSave, policyForm }) => {
  const [newPolicyForm, setNewPolicyForm] = useState({});

  const handleChange = (event) => {
    const { value, name } = event.target;

    setNewPolicyForm({
      ...newPolicyForm,
      [name]: value
    });
  };

  const handleClose = () => {
    setNewPolicyForm({});
    handleClosedialog();
  };

  const handleSave = () => {
    formSave(newPolicyForm);
  };
  const saveInput = React.createRef();

  useEffect(() => {
    if (Object.values(policyForm).length > 0) {
      const propPolicyForm = { ...policyForm };
      setNewPolicyForm(propPolicyForm);
    }
  }, [policyForm]);
  return (
    <Dialog
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          saveInput.current.click();
        }
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
          '& .MuiDialog-paper': {
            m: 0,
            borderRadius: 0,
            maxWidth: 450,
            maxHeight: '100%',
            height: '100vh',
            width: { xs: '100vw' }
          }
        }
      }}
    >
      <DialogTitle>
        {actionType === 'Create'
          ? 'New Policy Form'
          : actionType === 'Edit'
          ? 'Edit Policy Form'
          : actionType === 'Delete'
          ? 'Delete Policy Form'
          : ''}
      </DialogTitle>
      <DialogContent>
        {actionType !== 'Delete' ? (
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                name="formTitle"
                label="Policy Form Name"
                variant="outlined"
                value={newPolicyForm.formTitle || ''}
                error={!newPolicyForm?.formTitle}
                onChange={handleChange}
                helperText={!newPolicyForm?.formTitle ? 'Cannot Be Empty' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                name="formCode"
                label="Policy Form Code"
                variant="outlined"
                value={newPolicyForm?.formCode || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                type="number"
                label="Number of Pages"
                name="numOfPages"
                variant="outlined"
                value={newPolicyForm.numOfPages || ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        ) : (
          <DialogContentText>Confirm if you want to delete this Policy Form</DialogContentText>
        )}
      </DialogContent>
      <DialogActions className="actions">
        <Button
          ref={saveInput}
          variant="contained"
          color="primary"
          disabled={actionType !== 'Delete' ? !newPolicyForm?.formTitle || !newPolicyForm?.formCode || !newPolicyForm?.numOfPages : false}
          onClick={handleSave}
        >
          {actionType !== 'Delete' ? 'Save' : 'Delete'}
        </Button>
        <Button variant="text" onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PolicyFormDialog;
