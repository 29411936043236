import CustomTable from 'component/CustomTable';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';

import { Chip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import LoadingOverlay from 'component/LoadingOverlay';
import AircraftDialog from 'component/AircraftDialog';

const columns = [
  {
    id: 'registrationNo',
    label: 'Registration Number'
  },
  {
    id: 'airportCode',
    label: 'Airport Code'
  }
];

const AircraftQuoteComponent = ({
  companyId,
  activeStep,
  selectedAircraft,
  selectedCustomerOrg,
  selectedCustomerContact,
  emitSelectedAircraft
}) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [aircrafts, setAircrafts] = useState([]);
  const [actionType, setActionType] = useState('');
  const [aircraft, setAircraft] = useState({});
  const [aircraftId, setAircraftId] = useState(null);
  const handleClickOpendialog = () => {
    setActionType('CreateWorkflow');
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setAircraft({});
  };

  const handleEdit = (event, row) => {
    event.stopPropagation();
    // eslint-disable-next-line no-unused-vars
    const { id, ...rest } = row;
    setAircraftId(id);
    setActionType('EditWorkflow');
    setAircraft({ ...rest });
    setOpen(true);
  };

  const handleSave = async (aircraftDialog) => {
    setIsLoading(true);
    const aircraft = { ...aircraftDialog.aircraft, idCustomerOrganization: +selectedCustomerOrg.id };
    let response;
    switch (actionType) {
      case 'CreateWorkflow': {
        response = await axios.post(`/api/aircraft/companyId/${companyId}`, {
          aircraft,
          detailsFlag: false
        });
        emitSelectedAircraft(activeStep, response.data);
        break;
      }
      case 'EditWorkflow': {
        response = await axios.put(`/api/aircraft/${aircraftId}/companyId/${companyId}`, {
          aircraft,
          detailsFlag: false
        });
        const tempArray = [...aircrafts];
        const index = tempArray.findIndex((item) => item.id === aircraftId);
        tempArray[index] = response.data;
        setAircrafts(tempArray);
      }
    }
    handleClosedialog();
    setIsLoading(false);
  };

  const handleAircraftSelect = (row) => {
    emitSelectedAircraft(activeStep, row);
  };

  useEffect(() => {
    const getAircrafts = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/aircraft/companyId/${companyId}/customerOrgId/${selectedCustomerOrg.id}`);
      setAircrafts(result.data);
      setIsLoading(false);
    };

    getAircrafts();
  }, [companyId]);

  return (
    <>
      <LoadingOverlay loading={isLoading} />
      <AircraftDialog
        open={open}
        handleAircraftSave={handleSave}
        handleClosedialog={handleClosedialog}
        aircraft={aircraft}
        actionType={actionType}
        selectedCustomerOrg={selectedCustomerOrg}
        selectedContact={selectedCustomerContact}
      />
      <CustomTable
        isLoading={isLoading}
        columns={columns}
        data={aircrafts}
        isSearch={true}
        hasClickableRows={true}
        emitClickedRow={handleAircraftSelect}
        title="Aircrafts"
        hasPagination={false}
        selectedRows={[...selectedAircraft]}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <>
            <Chip
              label={selectedAircraft.find((item) => item.id === row.id) ? 'Unselect' : 'Select'}
              color="primary"
              variant={selectedAircraft.find((item) => item.id === row.id) ? 'filled' : 'outlined'}
              sx={{ color: selectedAircraft.find((item) => item.id === row.id) ? '#fff' : '#000', marginRight: 1 }}
              clickable
              onClick={() => handleAircraftSelect(row)}
            />
            <Chip
              label="Edit"
              color="primary"
              variant="filled"
              sx={{ color: '#fff', width: '40%' }}
              clickable
              onClick={(e) => handleEdit(e, row)}
            />
          </>
        )}
      </CustomTable>
    </>
  );
};

export default AircraftQuoteComponent;
