import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },
  itemParagraph: {
    marginTop: '0px'
  },
  nestedListContainer: {
    paddingLeft: 15,
    gap: 10 // Indent nested items
  },
  nestedListItemParagraph: {
    marginLeft: 15,
    marginTop: '-11px'
  },
  // Texas information
  texasContent: {
    flexDirection: 'row',
    padding: 20,
    fontSize: 8
  },
  column: {
    flex: 1, // Each column takes up 50% of the page
    paddingHorizontal: 10,
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: 5,
    fontSize: 9,
    textAlign: 'center'
  },
  text: {
    marginBottom: 5,
  },
  centeredBold: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 5
  }
});