import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  CardContent,
  Grid,
  Typography,
  TextField,
  Divider,
  DialogActions,
  Button,
  MenuItem
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';

import { EndDateSelections } from 'utils/endDateSelection';
import { formatDate } from 'utils/FormatUtils';

const PolicyBindModal = ({ open, handleCloseDialog, handleSave }) => {
  const [modalData, setModalData] = useState({});
  const [endDateSelection, setEndDateSelection] = useState(-1);
  const saveInput = React.useRef();
  const gridSpacing = 3;

  const handleChange = (event) => {
    const { value } = event.target;
    setModalData({
      ...modalData,
      policyNumber: value
    });
  };

  const handleBeginDateChange = (date) => {
    let newDate;
    if (date) {
      newDate = formatDate(date);
    } else {
      newDate = null;
    }

    setModalData({
      ...modalData,
      beginDate: newDate
    });
  };

  const handleEndDateChange = (date) => {
    let newDate;
    if (date) {
      newDate = formatDate(date);
    } else {
      newDate = null;
    }

    setModalData({
      ...modalData,
      endDate: newDate
    });
  };

  const handleEndDateSelection = (event) => {
    const { value } = event.target;
    setEndDateSelection(value);
    const beginDate = formatDate(modalData?.beginDate);
    const endDate = beginDate.clone();
    if (value !== 0 && value !== -1) {
      let newEndDate;
      if (value !== 12) {
        newEndDate = endDate.add(value, 'month');
      } else {
        newEndDate = endDate.add(1, 'year');
      }
      setModalData({
        ...modalData,
        endDate: newEndDate
      });
    }
  };

  const handleModalSave = () => {
    handleSave(modalData);
    handleCloseDialog();
  };

  const handleClose = () => {
    setEndDateSelection(-1);
    setModalData({});
    handleCloseDialog();
  };

  return (
    <>
      <Dialog
        open={open}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            saveInput.current.click();
          }
        }}
        keepMounted
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: '12px', padding: '16px' }
        }}
      >
        <DialogTitle textAlign="center" fontSize={20}>
          Bind Policy
        </DialogTitle>
        <DialogTitle textAlign="center" fontSize={8} sx={{ marginTop: '-24px' }}>
          currently policy number is not being generated.
        </DialogTitle>
        <DialogContent>
          <CardContent>
            <Grid container spacing={gridSpacing}>
              <Grid item sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography component="div" variant="h5">
                      Policy Info
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography component="div" variant="subtitle1">
                          Policy Number : &nbsp;
                          <Typography component="span" variant="subtitle2">
                            <TextField
                              sx={{ mt: 1 }}
                              fullWidth
                              name="policyNumber"
                              variant="outlined"
                              value={modalData?.policyNumber || ''}
                              error={!modalData?.policyNumber}
                              helperText={!modalData?.policyNumber ? 'Cannot Be Empty' : ''}
                              onChange={handleChange}
                            />
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} />
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography component="div" variant="h6">
                          Policy Date Range
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Begin Date : &nbsp;
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography component="span" variant="subtitle2">
                            <DateTimePicker
                              disableToolbar
                              variant="inline"
                              inputFormat="MM/dd/yyyy"
                              margin="normal"
                              value={modalData?.beginDate ? formatDate(modalData?.beginDate) : null}
                              slotProps={{
                                textField: {
                                  error: !modalData?.beginDate,
                                  helperText: !modalData?.beginDate ? 'Cannot Be Empty' : '',
                                  fullWidth: true
                                }
                              }}
                              onChange={handleBeginDateChange}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                              keyboardIcon={<EventTwoToneIcon />}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <Typography component="div" variant="subtitle1">
                            End Date : &nbsp;
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: 1 }}>
                          <Typography component="span" variant="subtitle2">
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                select
                                value={endDateSelection}
                                onChange={handleEndDateSelection}
                                disabled={!modalData.beginDate}
                                error={endDateSelection === -1}
                                helperText={endDateSelection === -1 ? 'Please assign a Begin date' : ''}
                              >
                                {EndDateSelections.map((item) => (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography component="span" variant="subtitle2">
                            <DateTimePicker
                              disableToolbar
                              variant="inline"
                              inputFormat="MM/dd/yyyy"
                              margin="normal"
                              disabled={endDateSelection !== 0}
                              value={modalData?.endDate ? formatDate(modalData?.endDate) : null}
                              slotProps={{
                                textField: {
                                  error: !modalData?.endDate,
                                  helperText: !modalData?.endDate ? 'Cannot Be Empty' : '',
                                  fullWidth: true
                                }
                              }}
                              onChange={handleEndDateChange}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                              keyboardIcon={<EventTwoToneIcon />}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </DialogContent>
        <Divider />
        <DialogActions style={{ justifyContent: 'space-between', padding: '16px' }}>
          <Button
            onClick={handleModalSave}
            disabled={!modalData?.beginDate || !modalData?.endDate || !modalData?.policyNumber}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PolicyBindModal;
