import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import BeginningComponent from '../Components/BeginingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const MinnesotaEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Minnesota Change Endorsement</Text>
      </View>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement changes Part One. GENERAL PROVISIONS AND CONDITIONS, Paragraph 6(a)(i) of Paragraph 6, if there is an Accident
            Occurence, to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>6. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>If there is an Accident or Occurrence</Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>In the event of an accident or occurence, you or someone we protect must:</Text>
                </View>
                <View style={styles.nestedListContainer}>
                  <View>
                    <Text style={styles.listItemHeading}>i. &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        promptly notify us, our Aviation Managers or your agent and describe how, when and where the accident or occurence
                        happened and give the names and addresses of witnesses, injured persons and all persons onboard the aircraft.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <BeginningComponent intro={false} />
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={[styles.nestedListContainer, { marginTop: '-10px' }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    The policy can be canceled at any time for nonpayment of premium by providing a notice of cancellation to the first
                    Named Insured at least 10 days before the effective date of the cancellation.
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect less than 90 days it may be canceled at any time by providing a notice of cancellatio
                    to the first Named Insured at least 30 days before the effective date of cancellation.
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect for 90 days or more, or if this policy is a renewal of a policy{' '}
                    <Text style={styles.boldText}>we</Text> issued, it may be canceled at any time by providing a notice of cancellation to
                    the first Named Insured at least 60 days before the effective date of cancellation and only for one of the following
                    reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Mirepresentation or fraud made by <Text style={styles.boldText}>you</Text> or with{' '}
                        <Text style={styles.boldText}>your</Text> knowledge in obtaining the policy or in pursuing a claim under the policy;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Actions by <Text style={styles.boldText}>you</Text> that have substantially increased or substanially changed the
                        risk insured;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        <Text style={styles.boldText}>Your</Text> refusal to eliminate known conditions that increase the potential for loss
                        after notification by <Text style={styles.boldText}>us</Text> that the condition must be removed;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Substantial change in the risk assured, except to the extent that <Text style={styles.boldText}>we</Text> should
                        reasonably have foreseen the change or contemplated the risk in writing the contract;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(5) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Loss of reinsurance by <Text style={styles.boldText}>us</Text> which provided coverage to the insurer for a
                        significant amount of the underlying risk insured. Cancellation notice pursuant to the ground shall contain the
                        information which address that <Text style={styles.boldText}>you</Text> have the right to appeal the cancellation to
                        the Commissioner of Commerce of the State of Minnesota within 10 days from the date of receipt of the notice; or
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(6) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        A determination by the Commissioner of the State of Minnesota that the continuation of the policy could place{' '}
                        <Text style={styles.boldText}>us</Text> in violation of the Minnesota insurance laws.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Proof of mailing or delivering of the notice is sufficient proof of notice. <Text style={styles.boldText}>We</Text> will
                compute the premium earned by us based on the percentage of the original policy period that{' '}
                <Text style={styles.boldText}>we</Text> provided coverage.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                We will return to <Text style={styles.boldText}>you</Text> any premium that <Text style={styles.boldText}>you</Text> have
                paid that <Text style={styles.boldText}>we</Text> have not earned, but making the refund is not a condition of cancellation
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={3} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.listItemParagraph}>
              <Text>
                However, if <Text style={styles.boldText}>we</Text> pay or have paid the <Text style={styles.boldText}>agreed value</Text>{' '}
                of the <Text style={styles.boldText}>aircraft,</Text> less any applicable deductible under the Aircraft Physical Damage
                coverage, <Text style={styles.boldText}>we</Text> are entitled to the total Aircraft Physical Damage premium shown on the
                Coverage Identification Page for the
                <Text style={styles.boldText}>aircraft</Text> on which <Text style={styles.boldText}>we</Text> made or make payment.{' '}
                <Text style={styles.boldText}>You</Text> agree to pay any premium that may be due or permit{' '}
                <Text style={styles.boldText}>us</Text> to deduct such premium from our loss payment.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> decide not to renew this policy <Text style={styles.boldText}>we</Text> will
                provide written notice of non-renewal, stating the reasons for non-renewal, to
                <Text style={styles.boldText}>you</Text> at least 60 days prior to the expiration of this policy except if{' '}
                <Text style={styles.boldText}>you</Text> have insured elsewhere, or accepted replacement coverage, or requested or agreed
                not to renew this policy.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Any notice will be provided to the first Named Insured`s last mailing address known to us. Any notice will state{' '}
                <Text style={styles.boldText}>our</Text> actual reason for the action taken. If notice is mailed, proof of mailing will be
                sufficient proof of notice.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement makes the following changes to <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS</Text>{' '}
            of your policy:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>1. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Paragraph 4.b. is deleted;</Text>
            </View>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>2. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                Paragraph 11, <Text style={styles.boldText}>Our</Text> Right of Recovery, to include the following provision:
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>Our</Text> rights of recovery does not apply against any person or organization insured under
                this policy or any other policy <Text style={styles.boldText}>we</Text>
                issue with respect to the same occurence or loss if the loss or occurence arose out of non-intentional acts of such person.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE,</Text> Paragraph 4, What{' '}
            <Text style={styles.boldText}>We</Text> Will Not Pay - f. War-Confiscation, to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>
              f. &nbsp; &nbsp; &nbsp; <Text style={{ fontStyle: 'italic' }}>War-Confiscation</Text>
            </Text>
            <View style={[styles.listItemParagraph, { marginTop: '-5px' }]}>
              <Text>
                Caused by arrest, restrint, seizure, confiscation, detention by or at the direction of any government; or caused by decalred
                or undeclared war;
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement makes the following changes to{' '}
            <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE,</Text> Paragraph 6, When{' '}
            <Text style={styles.boldText}>We</Text> Will Pay to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItem}>6. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-20px' }]}>
              <Text>When We Will Pay</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will pay for loss of or damage to the <Text style={styles.boldText}>aircraft</Text>{' '}
                covered by <Text style={styles.boldText}>your</Text> policy:
              </Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>
                  a. &nbsp; &nbsp; &nbsp; <Text style={{ fontStyle: 'italic' }}>Damaged Aircraft</Text>
                </Text>
                <View style={[styles.listItemParagraph]}>
                  <Text>
                    within 5 days after <Text style={styles.boldText}>you</Text> have given <Text style={styles.boldText}>us</Text> a sworn
                    Proof of Loss statement and <Text style={styles.boldText}>you</Text> and <Text style={styles.boldText}>we</Text> agree
                    on the amount; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>
                  b. &nbsp; &nbsp; &nbsp; <Text style={{ fontStyle: 'italic' }}>Destroyed Aircraft</Text>
                </Text>
                <View style={[styles.listItemParagraph]}>
                  <Text>
                    within 5 days after <Text style={styles.boldText}>you</Text> haven given <Text style={styles.boldText}>us</Text> a sworn
                    Proof of Loss Statement and <Text style={styles.boldText}>you</Text> have delivered the{' '}
                    <Text style={styles.boldText}>aircraft</Text> log books, airworthiness certificate and a bill of sale transferring clear
                    title to the <Text style={styles.boldText}>aircraft.</Text>
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This edorsement changes <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS</Text> Paragraph 4.j. Noise, Pollution,
            Electrical or Other Interference to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>j. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text style={{ fontStyle: 'italic' }}>Noise, Pollution, Electrical or Other Interference</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>Bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> that is directly
                or indirectly caused by or arises out of:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Noise, vibration or sonic boom;</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    Pollution and/or contamination of any kind except for <Text style={styles.boldText}>bodily injury</Text> or{' '}
                    <Text style={styles.boldText}>property damage</Text> arising out of heat smoke, or fumes from a hostile fire;
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Electrical and/or magnetic interference; or</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Interference with the use of property;</Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                unless caused by or resulting from an <Text style={styles.boldText}>occurence.</Text>
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={3} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement also changes <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS</Text> Paragraph 5.d,{' '}
            <Text style={{ fontStyle: 'italic' }}>Pay Interest</Text> to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>d. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text style={{ fontStyle: 'italic' }}>Pay Interest</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                In addition to the applicable limit coverage, <Text style={styles.boldText}>we</Text> will pay prejudgement and post
                judgement interest on the part of a judgement against <Text style={styles.boldText}>you</Text> or{' '}
                <Text style={styles.boldText}>someone we protect</Text> that <Text style={styles.boldText}>we</Text> are obligated to pay
                until <Text style={styles.boldText}>we</Text> have made payment or tendered or deposited it in court;
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement also changes <Text style={styles.boldText}>your</Text> policy to delete Paragraph 4e of{' '}
            <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE,</Text> and Paragraph 4h of{' '}
            <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS,</Text> from the policy.
          </Text>
        </View>
        <View>
          <Text>
            This endorsement also changes the paragraph titled &quot;Validation&quot; on the cover page of{' '}
            <Text style={styles.boldText}>your</Text> policy to read as follow:
          </Text>
        </View>
        <View>
          <Text>
            &quot;<Text style={styles.boldText}>Your</Text> policy is comprised of the Coverage Indentification Page, Parts One through Five
            and any endorsements <Text style={styles.boldText}>we</Text> issue. The signatures of <Text style={styles.boldText}>our</Text>{' '}
            President and Secretary validate <Text style={styles.boldText}>your</Text> policy.&quot;
          </Text>
        </View>
      </View>
      <FooterPDF sub={true} page={3} endPage={3} form={formNumber}/>
    </Page>
  </>
);

export default MinnesotaEndorsement;
