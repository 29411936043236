import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { Typography, IconButton, Tooltip } from '@mui/material';

// project import
import Breadcrumb from 'component/Breadcrumb';
import initializeAxios from 'utils/axios';
// assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import '../../assets/scss/actions.scss';
import useAuth from 'hooks/useAuth';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import PolicyFormDialog from 'component/PolicyFormDialog';

// ==============================|| CONTACT LIST ||============================== //

const PolicyForms = () => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const columns = [
    {
      id: 'formTitle',
      label: 'Form Title'
    },
    {
      id: 'formCode',
      label: 'Form Id'
    },
    {
      id: 'numOfPages',
      label: 'Number of Pages'
    }
  ];

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [policyForm, setPolicyForm] = useState({});
  const [policyForms, setPolicyForms] = useState([]);
  const [actionType, setActionType] = useState('');
  const [policyFormId, setPolicyFormId] = useState(null);

  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setPolicyForm({});
  };

  const handleSave = async (form) => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/policyForm`, {
          policyForm: {
            ...form
          }
        });
        const newForm = { ...response.data };
        setPolicyForms([...policyForms, newForm]);
        break;
      }
      case 'Edit': {
        const response = await axios.put(`/api/policyForm/${policyFormId}`, {
          policyForm: {
            ...form
          }
        });
        const tempArray = [...policyForms];
        const index = tempArray.findIndex((e) => e.id === policyFormId);
        tempArray[index] = {
          ...response.data
        };
        setPolicyForms(tempArray);
        break;
      }

      case 'Delete': {
        const response = await axios.delete(`/api/policyForm/${policyFormId}`);
        const tempArray = [...policyForms];
        const index = tempArray.findIndex((e) => e.id === response.data.id);
        tempArray.splice(index, 1);
        setPolicyForms(tempArray);
        break;
      }
    }

    handleClosedialog();
  };

  const handleEdit = (form) => {
    setPolicyForm({
      formTitle: form.formTitle,
      formCode: form.formCode,
      numOfPages: form.numOfPages
    });
    setPolicyFormId(form.id);
    setActionType('Edit');
    setOpen(true);
  };

  const handleDelete = (form) => {
    setPolicyFormId(form.id);
    setActionType('Delete');
    setOpen(true);
  };

  useEffect(() => {
    const getPolicyForms = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/policyForm`);
      setPolicyForms([...response.data]);
      setIsLoading(false);
    };
    getPolicyForms();
  }, []);

  return (
    <>
      <Breadcrumb title="Policy Forms" sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Policy Forms
        </Typography>
      </Breadcrumb>
      <LoadingOverlay loading={isLoading} />
      <PolicyFormDialog
        actionType={actionType}
        open={open}
        handleClosedialog={handleClosedialog}
        formSave={handleSave}
        policyForm={policyForm}
      />
      <CustomTable
        isLoading={isLoading}
        data={policyForms}
        columns={columns}
        title="Policy Forms"
        isSearch={true}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
        isDisabled={!auth.writePrivledge(auth.user, 'SuperAdmin')}
      >
        {(row) => (
          <>
            <Tooltip title="Edit Policy Form" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'SuperAdmin')}
                  onClick={() => handleEdit(row)}
                >
                  <EditTwoToneIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Policy Form" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'SuperAdmin')}
                  onClick={() => handleDelete(row)}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default PolicyForms;
