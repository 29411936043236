import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});
const TexasInformationandComplaintNotice = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.boldText}>Texas Information and Complaint Notice</Text>
      </View>
      <View style={styles.texasContent}>
        <View style={styles.column}>
          <Text style={styles.sectionTitle}>IMPORTANT NOTICE</Text>
          <Text style={styles.text}>To obtain information or make a complaint:</Text>
          <Text style={styles.text}>You may call Acceleration Aviation Underwriters, Inc. at the following number:</Text>
          <Text style={[styles.centeredBold]}>1-214-556-6848</Text>
          <Text style={styles.text}>You may also write to the Acceleration Aviation Underwriters, Inc.:</Text>
          <Text style={[styles.centeredBold]}>
            Acceleration Aviation Underwriters, Inc.{'\n'}
            P. O. Box 262329{'\n'}
            Plano, TX 75026
          </Text>
          <Text style={styles.text}>
            You may contact the Texas Department of Insurance to obtain information on companies, coverages, rights, or complaints at:
          </Text>
          <Text style={[styles.centeredBold]}>1-800-252-3439</Text>
          <Text style={styles.text}>You may write the Texas Department of Insurance at:</Text>
          <Text style={[styles.text, {textAlign: 'center'}]}>
            P. O. Box 149104{'\n'}
            Austin, Texas 78714-9104{'\n'}
            Fax: (512) 475-1771{'\n'}
            Web: http://www.tdi.state.tx.us{'\n'}
            E-mail: ConsumerProtection@tdi.state.tx.us
          </Text>
          <Text style={styles.sectionTitle}>PREMIUM OR CLAIM DISPUTES</Text>
          <Text style={styles.text}>
            Should you have a dispute concerning your premium or about a claim, you should contact Acceleration Aviation Underwriters, Inc.
            first. If the dispute is not resolved, you may contact the Texas Department of Insurance.
          </Text>
          <Text style={styles.sectionTitle}>ATTACH THIS NOTICE TO YOUR POLICY</Text>
          <Text style={styles.text}>
            This notice is for information only and does not become a part or condition of the attached document.
          </Text>
        </View>
        <View style={styles.column}>
          <Text style={styles.sectionTitle}>AVISO IMPORTANTE</Text>
          <Text style={styles.text}>Para obtener información o para someter una queja:</Text>
          <Text style={styles.text}>
            Usted puede llamar al número de teléfono de Acceleration Aviation Underwriters, Inc. para información o para someter una queja
            al:
          </Text>
          <Text style={styles.centeredBold}>1-214-556-6848</Text>
          <Text style={styles.text}>Usted también puede escribir a Acceleration Aviation Underwriters, Inc.:</Text>
          <Text style={styles.centeredBold}>
            Acceleration Aviation Underwriters, Inc.{'\n'}
            P. O. Box 262329{'\n'}
            Plano, TX 75026
          </Text>
          <Text style={styles.text}>
            Puede comunicarse con el Departamento de Seguros de Texas para obtener información acerca de compañías, coberturas, derechos o
            quejas al:
          </Text>
          <Text style={styles.centeredBold}>1-800-252-3439</Text>
          <Text style={styles.text}>Puede escribir al Departamento de Seguros de Texas:</Text>
          <Text style={[styles.text, {textAlign: 'center'}]}>
            P. O. Box 149104{'\n'}
            Austin, Texas 78714-9104{'\n'}
            Fax: (512) 475-1771{'\n'}
            Web: http://www.tdi.state.tx.us{'\n'}
            E-mail: ConsumerProtection@tdi.state.tx.us
          </Text>
          <Text style={styles.sectionTitle}>DISPUTAS SOBRE PRIMAS O RECLAMOS</Text>
          <Text style={styles.text}>
            Si tiene una disputa concerniente a su prima o a un reclamo, debe comunicarse con el Acceleration Aviation Underwriters, Inc.
            primero. Si no se resuelve la disputa, puede entonces comunicarse con el Departamento de Seguros de Texas (TDI).
          </Text>
          <Text style={styles.sectionTitle}>UNA ESTE AVISO A SU PÓLIZA</Text>
          <Text style={styles.text}>
            Este aviso es solo propósito de información y no se convierte en parte o condición del documento adjunto.
          </Text>
        </View>
      </View>
      <FooterPDF sub={true} page={1} endPage={1} form={formNumber}/>
    </Page>
  </>
);

export default TexasInformationandComplaintNotice;
