import { Document, Page, View, Text } from '@react-pdf/renderer';
import InsurancePolicyPDF from './InsurancePolicy';
import CoverPage from './CoverPage';
import LiabilityCoverage from './LiabilityCoverage';
import RenterPilotEndorsement from './RenterPilot';
import CoverageIdentificationPage from './CoverageIdentificationPage';
import LienHolderInterestPDF from './LienHolderInterestPDF';
import SpecialCoverPilotEndorsement from './SpecialCoverPilotEndorsement';
import TurbineEndorsement from './TurbineEndorsement';
import DamagedAircraft from './DamagedAircraft';
import NonOwnershipEndorsement from './NonOwnershipEndorsement';
// import SpecialEndorsement from './SpecialEndorsement';
import FloodStormDeductible from './FloodStormDetuctible';
import HeliocopterEndorsement from './HeliocopterEndorsement';
import TerrorismCoverage from './Terrorism';
import FinancedEndorsement from './FinancedEndorsement';
import LeasedAircraftEndorsement from './LeasedAircraftEndorsement';
import SeaPlaneEndorsement from './SeaPlaneEndorsement';
import WarConfiscationEndorsement from './WarConfiscation';
import CanopyEndorsement from './CanopyEndorsement';
import PassengerSettlementPDF from './PassengerSettlementPdf';
import PilotRequirementPDF from './PilotRequirementPDF';
import StateEndorsement from './StatesEndorsement';
import GeneralEndorsement from './GeneralEndorsement';
import { stateEndorsementDic } from 'utils/states';
import GearUpEndorsement from './GearUpEndorsement';

const findId = (array, title) => {
  if (array?.length === 0) {
    return '';
  }
  if (title !== '') {
    const response = array?.find((item) => item?.formTitle === title)?.formCode || '';
    return response !== '' ? response : '';
  } else {
    return '';
  }
};

const findSelection = (array, title) => {
  return array?.find((item) => item?.selectionName === title) || false;
};

const findAllPdfFormNums = (selections, aircrafts, policyForms) => {
  const policyFormIds = [];
  const aircraft = aircrafts[0]?.Aircraft;
  const state = stateEndorsementDic.find((item) => item.code === aircraft?.stateCode);

  // Get ids from selections
  if (selections?.length > 0) {
    const selectedSelections = selections.filter((item) => item.selected);
    policyFormIds.push(...selectedSelections.map((item) => findId(policyForms, item.selectionName)).flat(1));
  }

  if (aircrafts?.length > 0) {
    if (aircrafts.filter((item) => item.Aircraft.isLeased)?.length > 0) {
      policyFormIds.push(findId(policyForms, 'Leased Aircraft'));
    }
    policyFormIds.push(...[findId(policyForms, state.state), 'state2' in state ? findId(policyForms, state.state2) : null].filter(Boolean));
    if (aircrafts[0]?.AircraftDetailsLienholder?.length > 0) {
      policyFormIds.push(
        findId(
          policyForms,
          aircraft?.stateCode === 'LA'
            ? 'Leinholders Interest Endorsement - Louisiana'
            : aircraft?.stateCode === 'NH'
            ? 'Leinholders Interest Endorsement - New Hampshire'
            : 'Lienholder Interest Endorsement'
        )
      );
    }
  }
  policyFormIds.unshift(findId(policyForms, 'Insurance Policy'));
  console.log(policyFormIds);
  
  return policyFormIds;
};

// eslint-disable-next-line react/prop-types, no-unused-vars
const PDFContent = ({ policy = {}, policyForms = [], aircrafts = [], selections = [] }) => {
  let aircraft = {};
  let state = {};
  // eslint-disable-next-line no-constant-condition
  if (policy && aircrafts?.length > 0 && policyForms?.length > 0) {
    aircraft = aircrafts[0]?.Aircraft;
    state = stateEndorsementDic.find((item) => item.code === aircraft?.stateCode);
    return (
      <Document>
        <CoverPage />
        <CoverageIdentificationPage
          policy={policy}
          formNumber={findId(policyForms, 'Coverage Identification Page')}
          formIds={findAllPdfFormNums(selections, aircrafts, policyForms)}
          aircraft={aircrafts[0]}
        />
        <PilotRequirementPDF
          requirements={policy?.pilotRequirementDescription}
          rental={true}
          formNumber={findId(policyForms, 'Pilot Requirements')}
        />
        {findSelection(selections, 'Liability Coverage Extension - General')?.selected && (
          <LiabilityCoverage
            aircraft={findSelection(selections, 'Liability Coverage Extension - General').Aircraft}
            type="GENERAL"
            formNumber={findId(policyForms, 'Liability Coverage Extension - General') || ''}
          />
        )}
        {findSelection(selections, 'Liability Coverage Extension & Waiver')?.selected && (
          <LiabilityCoverage
            aircraft={findSelection(selections, 'Liability Coverage Extension & Waiver').Aircraft}
            type=""
            formNumber={findId(policyForms, 'Liability Coverage Extension & Waiver') || ''}
          />
        )}
        {findSelection(selections, 'Liability Coverage Extension - FLIGHT INSTRUCTION')?.selected && (
          <LiabilityCoverage
            aircraft={findSelection(selections, 'Liability Coverage Extension - FLIGHT INSTRUCTION').Aircraft}
            type="FLIGHT INSTRUCTION OR PROFICIENCY TRAINING"
            formNumber={findId(policyForms, 'Liability Coverage Extension & Waiver') || ''}
          />
        )}
        {findSelection(selections, 'Passenger Settlement Endorsement')?.selected && (
          <PassengerSettlementPDF formNumber={findId(policyForms, 'Passenger Settlement Endorsement') || ''} />
        )}
        {aircrafts[0]?.AircraftDetailsLienholder?.length > 0 && (
          <LienHolderInterestPDF
            state={aircraft?.stateCode === 'LA' ? 'Louisiana' : aircraft?.stateCode === 'NH' ? 'New Hampshire' : ''}
            formNumber={findId(
              policyForms,
              aircraft?.stateCode === 'LA'
                ? 'Leinholders Interest Endorsement - Louisiana'
                : aircraft?.stateCode === 'NH'
                ? 'Leinholders Interest Endorsement - New Hampshire'
                : 'Lienholder Interest Endorsement'
            )}
          />
        )}
        {findSelection(selections, 'General Change Endorsement')?.selected && (
          <GeneralEndorsement
            aircraft={findSelection(selections, 'General Change Endorsement').Aircraft}
            formNumber={findId(policyForms, 'General Change Endorsement')}
          />
        )}
        {findSelection(selections, 'Canopy Deductible Endorsement')?.selected && (
          <CanopyEndorsement
            aircraft={findSelection(selections, 'Canopy Deductible Endorsement').Aircraft}
            aircrafts={aircrafts}
            formNumber={findId(policyForms, 'Canopy Deductible Endorsement') || ''}
          />
        )}
        {/* {findSelection(selections, 'Gear Up / Gear Collapse Endorsement')??.selected && <GearUpEndorsement />} */}
        {findSelection(selections, 'Financed Endorsement')?.selected && (
          <FinancedEndorsement formNumber={findId(policyForms, 'Financed Premium') || ''} />
        )}
        {aircrafts.filter((item) => item.Aircraft.isLeased).length > 0 && (
          <LeasedAircraftEndorsement aircrafts={aircrafts} formNumber={findId(policyForms, 'Leased Aircraft') || ''} />
        )}
        {findSelection(selections, 'Seaplane and Amphibian Endorsement I')?.selected && (
          <SeaPlaneEndorsement
            aircraft={findSelection(selections, 'Seaplane and Amphibian Endorsement I').Aircraft}
            formNumber={findId(policyForms, 'Seaplane 1') || ''}
          />
        )}
        {findSelection(selections, 'War-Confiscation Coverage')?.selected && (
          <WarConfiscationEndorsement
            aircraft={findSelection(selections, 'War-Confiscation Coverage').Aircraft}
            formNumber={findId(policyForms, 'War-Confiscation Coverage') || ''}
          />
        )}
        {findSelection(selections, 'Helicopter Endorsement')?.selected && (
          <HeliocopterEndorsement
            aircraft={findSelection(selections, 'Helicopter Endorsement').Aircraft}
            formNumber={findId(policyForms, 'Helicopter Endorsement')}
          />
        )}
        {/* <SpecialEndorsement title="Aerial Photography I" /> */}
        {findSelection(selections, 'Named Storm, Flooding or Hurricane Deductible')?.selected && (
          <FloodStormDeductible
            aircraft={findSelection(selections, 'Named Storm, Flooding or Hurricane Deductible').Aircraft}
            formNumber={findId(policyForms, 'Named Storm, Flooding or Hurricane Deductible') || ''}
          />
        )}
        {findSelection(selections, 'Damaged Aircraft Endorsement')?.selected && (
          <DamagedAircraft
            aircraft={findSelection(selections, 'Damaged Aircraft Endorsement').Aircraft}
            formNumber={findId(policyForms, 'Damaged Aircraft Endorsement') || ''}
          />
        )}
        {findSelection(selections, 'Non-Ownership Liability Coverage')?.selected && (
          <NonOwnershipEndorsement
            aircraft={findSelection(selections, 'Non-Ownership Liability Coverage').Aircraft}
            formNumber={findId(policyForms, 'Non-Ownership Liability Coverage') || ''}
          />
        )}
        {findSelection(selections, 'Turbine Aircraft Endorsement')?.selected && (
          <TurbineEndorsement aircraft={findSelection(selections, 'Turbine Aircraft Endorsement')?.Aircraft} />
        )}
        {findSelection(selections, 'Gear Up / Gear Collapse Endorsement')?.selected && (
          <GearUpEndorsement aircraft={findSelection('Gear Up / Gear Collapse Endorsement')?.Aircraft} />
        )}
        {findSelection(selections, 'Special Use, Coverage and Pilots Limitations Endorsement')?.selected && (
          <SpecialCoverPilotEndorsement
            aircraft={findSelection(selections, 'Special Use, Coverage and Pilots Limitations Endorsement').Aircraft}
            formNumber={findId(policyForms, 'Special Use, Coverage and Pilots Limitations Endorsement') || ''}
          />
        )}
        {findSelection(selections, 'Renter Pilot Liability Coverage Extension')?.selected && (
          <RenterPilotEndorsement
            aircraft={findSelection(selections, 'Renter Pilot Liability Coverage Extension').Aircraft}
            formNumber={findId(policyForms, 'Renter Pilot Liability Coverage Extension')}
          />
        )}
        {stateEndorsementDic.filter((item) => item.code === aircraft?.stateCode)?.length === 1 && (
          <StateEndorsement
            state={aircraft?.stateCode}
            formNumber={findId(policyForms, state.state)}
            formNumber2={'state2' in state && findId(policyForms, state.state2)}
          />
        )}
        {findSelection(selections, 'Cap on Losses from Certified Acts of Terrorism')?.selected && (
          <TerrorismCoverage formNumber={findId(policyForms, 'Cap on Losses from Certified Acts of Terrorism') || ''} />
        )}
        {findSelection(selections, 'Exclusion of Certified Acts of Terrorism')?.selected && (
          <TerrorismCoverage exclusion={true} formNumber={findId(policyForms, 'Exclusion of Certified Acts of Terrorism') || ''} />
        )}
        <InsurancePolicyPDF formNumber={findId(policyForms, 'Insurance Policy')} />
      </Document>
    );
  } else {
    return (
      <Document>
        <Page size="LETTER">
          <View>
            <Text>Sorry something went wrong when creating your Document</Text>
          </View>
        </Page>
      </Document>
    );
  }
};

export default PDFContent;
