import React, { useEffect, useState } from 'react';

import { Tooltip, IconButton } from '@mui/material';
import CustomTable from 'component/CustomTable';
import LoadingOverlay from 'component/LoadingOverlay';
import { useParams } from 'react-router';

import initializeAxios from 'utils/axios';
import useAuth from 'hooks/useAuth';

import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import PolicyOptionAircraftDialog from 'component/PolicyOptionAircraftDialog';

const columns = [
  {
    id: 'shortCode',
    label: 'Short Code'
  },
  {
    id: 'description',
    label: 'Description'
  }
];

const PolicyOptionAircraftComponent = ({ option }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const { companyId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [aircraftOptions, setAircraftOptions] = useState([]);
  const [aircraftOption, setAircraftOption] = useState({});
  const [actionType, setActionType] = useState(null);
  const [aircraftOptionId, setAircraftOptionId] = useState(null);

  const [open, setOpen] = useState(false);

  const handleClickOpenDialog = () => {
    setActionType('Create');
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setAircraftOption({});
  };

  const handleSave = async (dialog) => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/poa${option}/companyId/${companyId}`, {
          ...dialog
        });
        setAircraftOptions([...aircraftOptions, response.data]);
        break;
      }
      case 'Edit': {
        const response = await axios.put(`/api/poa${option}/${aircraftOptionId}/companyId/${companyId}`, {
          ...dialog
        });
        const tempArray = [...aircraftOptions];
        const index = tempArray.findIndex((e) => e.id === aircraftOptionId);
        tempArray[index] = {
          ...tempArray[index],
          ...response.data
        };
        setAircraftOptions(tempArray);

        break;
      }

      case 'Delete': {
        await axios.delete(`/api/poa${option}/${aircraftOptionId}/companyId/${companyId}`);
        const tempArray = [...aircraftOptions];
        const index = tempArray.findIndex((e) => e.id === aircraftOptionId);
        tempArray.splice(index, 1);
        setAircraftOptions(tempArray);
        break;
      }
    }

    handleClosedialog();
  };

  const handleEdit = (editOption) => {
    setActionType('Edit');
    setAircraftOption({
      shortCode: editOption?.shortCode,
      perPerson: editOption?.perPerson,
      perPassenger: editOption?.perPassenger,
      perOccurence: editOption?.perOccurence,
      description: editOption?.description
    });
    setAircraftOptionId(editOption.id);
    setOpen(true);
  };

  const handleDelete = (deleteOption) => {
    setActionType('Delete');
    setAircraftOptionId(deleteOption.id);
    setOpen(true);
  };

  useEffect(() => {
    const getPolicyOptionAircraftOption = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/poa${option}/companyId/${companyId}`);
      setAircraftOptions([...response.data]);
      setIsLoading(false);
    };

    getPolicyOptionAircraftOption();
  }, []);
  return (
    <>
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        hasCreateButton={true}
        handleOpenCreate={handleClickOpenDialog}
        isDisabled={!auth.writePrivledge(auth.user, 'PolicyOptionAircraft_Adm')}
        data={aircraftOptions}
        columns={columns}
        isLoading={isLoading}
        title={`Policy Option Aircraft ${option}`}
      >
        {(row, index) => (
          <>
            <Tooltip title={`Edit Aircraft ${option}`} placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'PolicyOptionAircraft_Adm')}
                  onClick={() => handleEdit(aircraftOptions[index])}
                >
                  <EditTwoToneIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={`Delete Aircraft ${option}`} placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'PolicyOptionAircraft_Adm')}
                  onClick={() => handleDelete(aircraftOptions[index])}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </CustomTable>
      <PolicyOptionAircraftDialog
        open={open}
        title={`Policy Option Aircraft ${option}`}
        handleClosedialog={handleClosedialog}
        handleClickOpenDialog={handleClickOpenDialog}
        handlePolicyOptionAircraftSave={handleSave}
        policyOptionAircraft={aircraftOption}
        actionType={actionType}
      />
    </>
  );
};

export default PolicyOptionAircraftComponent;
