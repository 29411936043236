import { Page, View, Text, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import { PageFooter } from '../../PageFooter';
import { styles } from '../../Styles';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' }
  ]
});

export const Page1 = ({formNumber}) => (
  <Page size="letter" style={styles.page}>
    <View style={styles.content}>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            14.{'\u00A0'}
            {'\u00A0'}Concealment or Misrepresentation
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> do not provide coverage for <Text style={styles.boldText}>you</Text> or{' '}
            <Text style={styles.boldText}>someone we protect</Text> if <Text style={styles.boldText}>you</Text> or{' '}
            <Text style={styles.boldText}>someone we protect</Text> has concealed or misrepresented any material fact or circumstance
            relating to the policy either before or after an <Text style={styles.boldText}>accident</Text> or{' '}
            <Text style={styles.boldText}>occurrence.</Text>
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            15.{'\u00A0'}
            {'\u00A0'}Inspection and Audit
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> will have the right, but shall have no obligation, to inspect the{' '}
            <Text style={styles.boldText}>aircraft</Text> and records during and up to 1 year after the policy period.
          </Text>
        </View>
      </View>
    </View>
    <View style={styles.header}>
      <Text>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE</Text>
    </View>
    <View style={styles.contentDivider} />
    <View style={styles.content}>
      <View>
        <Text>
          Review Item 5 of <Text style={styles.boldText}>your</Text> Coverage Identification Page to confirm the Aircraft Physical Damage
          coverage that has been issued to <Text style={styles.boldText}>you.</Text>
          Please note also the <Text style={styles.boldText}>agreed value</Text> of the <Text style={styles.boldText}>aircraft</Text> and
          the amount for which <Text style={styles.boldText}>you</Text> are responsible (deductible). This coverage is for
          <Text style={styles.boldText}>your</Text> benefit and not for the benefit of <Text style={styles.boldText}>anyone</Text> else in
          possession of the <Text style={styles.boldText}>aircraft.</Text>
        </Text>
      </View>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            1.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}What <Text style={styles.boldText}>We</Text> Cover
          </Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Coverage F covers direct physical loss of or damage to the <Text style={styles.boldText}>aircraft</Text> caused by an{' '}
                <Text style={styles.boldText}>accident</Text> while the <Text style={styles.boldText}>aircraft</Text> is not{' '}
                <Text style={styles.boldText}>in motion;</Text> and
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Coverage G covers direct physical loss of or damage to the <Text style={styles.boldText}>aircraft</Text> caused by an{' '}
                <Text style={styles.boldText}>accident</Text> while the <Text style={styles.boldText}>aircraft</Text> is{' '}
                <Text style={styles.boldText}>in motion.</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            2.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}What <Text style={styles.boldText}>You</Text> Must Pay or Bear (Deductible)
          </Text>
          <Text style={styles.listItemParagraph}>
            When <Text style={styles.boldText}>we</Text> pay for loss of or damage to the <Text style={styles.boldText}>aircraft, you</Text>{' '}
            must first pay or bear one of the following amounts unless no deductible applies:
          </Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Not <Text style={styles.boldText}>In Motion</Text> Deductible. The amount shown in Item 5F of{' '}
                <Text style={styles.boldText}>your</Text> Coverage Identification Page must be paid or borne by{' '}
                <Text style={styles.boldText}>you</Text> when loss or damage occurs under Coverage F.{' '}
                <Text style={styles.boldText}>We</Text> will not apply a deductible amount if the loss or damage results from a fire caused
                by a combustion source external to the <Text style={styles.boldText}>aircraft,</Text> lightning, explosion, theft or
                vandalism
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>In Motion</Text> Deductible. The amount shown in Item 5G of{' '}
                <Text style={styles.boldText}>your</Text> Coverage Identification Page must be paid or borne by{' '}
                <Text style={styles.boldText}>you</Text> when loss or damage occurs under Coverage G.
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            3.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}What <Text style={styles.boldText}>We</Text> Will Pay (Less Deductible)
          </Text>
          <Text style={styles.listItemParagraph}>
            When <Text style={styles.boldText}>we</Text> pay for loss of or damage to the <Text style={styles.boldText}>aircraft, you</Text>{' '}
            must first pay or bear one of the following amounts unless no deductible applies:
          </Text>
          <View style={styles.nestedListContainer}>
            <View style={{ gap: 5 }}>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Destroyed <Text style={styles.boldText}>Aircraft.</Text> If the cost of repair when added to the value of the{' '}
                <Text style={styles.boldText}>aircraft</Text> after it is damaged and prior to repairs equals or exceeds the{' '}
                <Text style={styles.boldText}>agreed value,</Text> it is a destroyed <Text style={styles.boldText}>aircraft.</Text>
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                If the <Text style={styles.boldText}>aircraft</Text> is destroyed, <Text style={styles.boldText}>we</Text> will pay the{' '}
                <Text style={styles.boldText}>agreed value</Text> of the <Text style={styles.boldText}>aircraft,</Text> less the applicable
                deductible. <Text style={styles.boldText}>We</Text> will be entitled to ownership of the{' '}
                <Text style={styles.boldText}>aircraft</Text> upon payment, free and clear of any encumbrances on the{' '}
                <Text style={styles.boldText}>aircraft.</Text>
              </Text>
            </View>
            <View style={{ gap: 5 }}>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Damaged <Text style={styles.boldText}>Aircraft.</Text> If the <Text style={styles.boldText}>aircraft</Text> is damaged and
                not destroyed, <Text style={styles.boldText}>we</Text> will pay the reasonable cost of repair after the{' '}
                <Text style={styles.boldText}>aircraft</Text>
                has been repaired, but <Text style={styles.boldText}>we</Text> will not pay more than the{' '}
                <Text style={styles.boldText}>agreed value,</Text> less the applicable deductible.
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                If the <Text style={styles.boldText}>aircraft</Text> is damaged by hail, <Text style={styles.boldText}>we</Text> will pay
                the reasonable cost of repair of the hail damage that affects the airworthiness of the{' '}
                <Text style={styles.boldText}>aircraft</Text> after the <Text style={styles.boldText}>aircraft</Text> has been repaired.{' '}
                <Text style={styles.boldText}>We</Text> will pay an amount not exceeding 10% of the{' '}
                <Text style={styles.boldText}>agreed value</Text> for hail damage that does not affect the airworthiness of the aircraft,
                less the applicable deductible. Hail damage that does not result in an immediate grounding of the{' '}
                <Text style={styles.boldText}>aircraft</Text> will be deemed to be damage not affecting the airworthiness of the{' '}
                <Text style={styles.boldText}>aircraft.</Text>
              </Text>
            </View>
          </View>
          <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
            Cost of repair includes necessary labor at straight time rates, parts and materials of similar kind and quality and the least
            expensive transportation charges necessary to repair the <Text style={styles.boldText}>aircraft</Text> and return it to the
            place where the damage occurred or its home airport, whichever is nearer. If <Text style={styles.boldText}>you</Text> are
            authorized by the <Text style={styles.boldText}>FAA</Text> to perform and <Text style={styles.boldText}>you</Text> elect to
            perform <Text style={styles.boldText}>your</Text> own repairs, <Text style={styles.boldText}>you</Text>
            agree to supply materials, parts and labor at <Text style={styles.boldText}>your</Text> cost, excluding overtime payments.{' '}
            <Text style={styles.boldText}>We</Text> agree to increase the allowance for your labor costs by 50% of the gross amount paid in
            wages or compensation to help you defray your cost of overhead and supervision. Repairs performed by any organization in which{' '}
            <Text style={styles.boldText}>you</Text> have any ownership interest shall be deemed to be repairs performed by{' '}
            <Text style={styles.boldText}>you.</Text>
          </Text>
        </View>
        <View style={[styles.listItem, {marginBottom: '-20px'}]}>
          <Text style={styles.listItemHeading}>
            4.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}What <Text style={styles.boldText}>We</Text> Will Not Pay
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> will not pay for physical loss of or damage to the{' '}
            <Text style={styles.boldText}>aircraft:</Text>
          </Text>
        </View>
      </View>
    </View>
    <PageFooter form={formNumber} number={6} />
  </Page>
);
