import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import BeginningComponent from '../Components/BeginingComponent';
import EndingComponent from '../Components/EndingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const MissouriEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <BeginningComponent state="Missouri" />
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any time for nonpayment of premium by mailing or delivering a notice of cancellation to
                    the first Named Insured with at least 10 days before the effective date of the cancellation.
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any time by mailing or delivering a notice of cancellation to the first Named Insured
                    with at least 30 days notice for the reasons listed below before the effective date of the cancellation.
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        This policy was issued because of material misrepresentation affecting the policy or in the presentation of a claim
                        or a violation of any of the terms or conditions of the policy;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Changes in conditions after the effective date of the policy which have materially increased the hazards originally
                        insured;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        <Text style={styles.boldText}>Our</Text> insolvency;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        <Text style={styles.boldText}>We</Text> involuntarily lose reinsurance for the policy.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any time by mailing or delivering a notice of cancellation to the first Named Insured
                    with at least 60 days notice for any other reason before the effective date of the cancellation.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> cancel <Text style={styles.boldText}>your</Text> policy,{' '}
                <Text style={styles.boldText}>we</Text> will return any premium <Text style={styles.boldText}>you</Text> have paid that{' '}
                <Text style={styles.boldText}>we</Text> have not earned within a reasonable period of time after the canceallation unless an
                audit or a rate investigation is required in which case the premium adjustment shall be made as soon as practicable, but
                making a refund is not a condition of cancellation.
              </Text>
            </View>
            <EndingComponent extra={false} />
            <View style={styles.listItemParagraph}>
              <Text>
                No notice of non-renewal of this policy shall be effective unless mailed or delivered by{' '}
                <Text style={styles.boldText}>us</Text> at least 60 days prior to the effective date of the non-renewal. Such notice shall
                state <Text style={styles.boldText}>our</Text> actual reason for the proposing the action, the statement of reason to be
                sufficiently clear in specifics so that <Text style={styles.boldText}>you</Text> can identify the basis of the decision
                without further inquiry. <Text style={styles.boldText}>You</Text>
                have the right to recceieve, within 30 days of a written request to <Text style={styles.boldText}>us</Text>, a statement of
                claims history for this policy.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If there is a material change in any policy condition, or limit of coverage, or if the renewal premium for the same
                coverage, limits and exposures will increase more than 25% above the expiring premium,{' '}
                <Text style={styles.boldText}>we</Text> or <Text style={styles.boldText}>Our Aviation Managers</Text> will mail written
                notice to <Text style={styles.boldText}>you</Text> at least 60 days prior to the expiration date of this policy.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Any notice will be mailed or delivered to the first Named Insured`s last known mailing address known to{' '}
                <Text style={styles.boldText}>us</Text>. If notice is mailed, proof of mailing will be sufficient proof of notice.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={3} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS,</Text> Paragraph 16,
            Missouri Property and Casualty Insurance Guaranty Association Coverage Limitations, is added to of the policy as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>16. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Missouri Property and Casualty Insurance Guaranty Association Coverage Limitations</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Subject to the provisions of the Missouri Property Insurance Guaranty Association Act (to be referred to as the Act), if{' '}
                <Text style={styles.boldText}>we</Text> are a member of the Missouri Property and Casualty Insurance Guaranty Association
                (to be referred to as the Association), the Association will pay claims covered under the Act if{' '}
                <Text style={styles.boldText}>we</Text> become insolvent. The Act contains various exclusions, conditions and limitations
                that govern a claimat`s eligibility to collect payment from the Association and affect the amount of any payment. The
                following limitations apply subject to all other provisions of the Act.
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>1. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    Under Section 275.772.2(2), Claims coered by the Association do not include a claim by or against an insured of an
                    insolvent insurer, if that insured has a net worth of more than $25 million, as defined by Section 375.772 RSMo., on the
                    date the insurer becomes insolvent.
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>2. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    Under Section 375.775.1(1) the Association`s obligation includes only the amount of each covered claim which is less
                    than $300,000. However, the Association will not:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        be obligated to an insured or claimant in excess of the limits of liability of the policy from which the claim
                        arises; or
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>return to the insured any unearned premium in excess of $25,000</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                These limitations have not effect on the coverage <Text style={styles.boldText}>we</Text> will provide under this policy
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE,</Text> Paragraph 5.b, if the{' '}
            <Text style={styles.boldText}>aircraft</Text> is damaged, what <Text style={styles.boldText}>You</Text> Must Do,{' '}
            <Text style={{ fontStyle: 'italic' }}>Proof of Loss</Text>, is replaced by the following provision:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>5. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                What <Text style={styles.boldText}>You</Text> Must Do
              </Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <View style={[styles.listItemParagraph, { marginBottom: '-5px' }]}>
                  <Text style={{ fontStyle: 'italic' }}>Proof of Loss</Text>
                </View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    Give us a sworn Proof of Loss statement after <Text style={styles.boldText}>our</Text> request.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE,</Text> Paragraph 6, When{' '}
            <Text style={styles.boldText}>We</Text> Pay, is replaced by the following provision:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>6. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                When <Text style={styles.boldText}>We</Text> Will Pay
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                In the event of a loss to the <Text style={styles.boldText}>aircraft</Text>, <Text style={styles.boldText}>we</Text> will
                give <Text style={styles.boldText}>you</Text> notice within 15 working days after <Text style={styles.boldText}>we</Text>{' '}
                receieve a properly executed proof of loss that <Text style={styles.boldText}>we</Text>:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    Accept <Text style={styles.boldText}>your</Text> claim;
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    Deny <Text style={styles.boldText}>your</Text> claim; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    Need more time to determine whether <Text style={styles.boldText}>your</Text> claim should be accepted or denied.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> deny <Text style={styles.boldText}>your</Text> claim, such notice will be in
                writing, and will state any policy provision, condition or exclusion used as a basis for the denial.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> need more time to determine whether <Text style={styles.boldText}>your</Text>{' '}
                claim should be accepted or denied, the written notice will state the reason(s) why more time is needed. If{' '}
                <Text style={styles.boldText}>We</Text> have not completed <Text style={styles.boldText}>our</Text> investigation,{' '}
                <Text style={styles.boldText}>we</Text> will notify <Text style={styles.boldText}>you</Text> again in writing, within 45
                days after the date the inital notice is sent as provided, and thereafter every 45 days. The written notice shall state why
                more time is needed to investigate <Text style={styles.boldText}>your</Text> claim.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will pay for loss of or damage to the <Text style={styles.boldText}>aircraft</Text>{' '}
                covered by <Text style={styles.boldText}>your</Text> poloicy:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <View style={[styles.listItemParagraph, { marginBottom: '-5px' }]}>
                  <Text style={{ fontStyle: 'italic' }}>Damaged Aircraft</Text>
                </View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    within 15 days after <Text style={styles.boldText}>you</Text> have given <Text style={styles.boldText}>us</Text> a sworn
                    Proof of Loss statement and <Text style={styles.boldText}>you</Text> and <Text style={styles.boldText}>we</Text> agree
                    on the amount; or
                  </Text>
                </View>
              </View>
              <View>
                <View style={[styles.listItemParagraph, { marginBottom: '-5px' }]}>
                  <Text style={{ fontStyle: 'italic' }}>Destroyed Aircraft</Text>
                </View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    within 15 days after <Text style={styles.boldText}>you</Text> have given <Text style={styles.boldText}>us</Text> a sworn
                    Proof of Loss statement and <Text style={styles.boldText}>you</Text> have delivered the{' '}
                    <Text style={styles.boldText}>aircraft</Text> log books, airworthiness certificate and a bill of sale transferring clear
                    title to the <Text style={styles.boldText}>aircraft</Text>.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE</Text> to add Paragraph 10,
            Appraisal for <Text style={styles.boldText}>Aircraft</Text> Physical Damage Losses to <Text style={styles.boldText}>your</Text>{' '}
            policy as follows:
          </Text>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={3} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>10. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                Appraisal for <Text style={styles.boldText}>Aircraft</Text> Physical Damage Losses
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> and <Text style={styles.boldText}>you</Text> disagree on the amount of loss,
                either may make written demand for an appraisal of the loss. In this event, each party will select a competent and impartial
                appraiser and notify the other of the appraiser selected within 20 days of the written demand for appraisal. The two
                appraisers will select an umpire. If they cannot agree upon an umpire within 15 days,{' '}
                <Text style={styles.boldText}>we</Text> or <Text style={styles.boldText}>you</Text> may request that selection be made by a
                judge of a court having jurisdiction. The appraisers will state separately the value of the property and amount of loss. If
                they fail to agree, they will submit their differences to the umpire. The umpire shall make an award within 30 days after
                the umpiure receives the appraisers`s submissions of their difference. A decision agreed to by any two will be binding.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>Each party will:</Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Pay its chosen appraiser; and</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Bear the other expenses of the appraisal and umpire equally.</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement changes Paragraph 4.d., &quot;Intentional Acts,&quot; of{' '}
            <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS,</Text> to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>d. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Intentional Acts</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>Bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> that is
                intentionally caused by <Text style={styles.boldText}>you</Text> or by{' '}
                <Text style={styles.boldText}>someone we protect</Text> unless the intentional act occurs in a domestic abuse situation or
                done while in flight to prevent dangerous interference with the operation of the aicraft.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={3} endPage={3} form={formNumber}/>
    </Page>
  </>
);

export default MissouriEndorsement;
