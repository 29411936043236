import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import {
  Grid,
  Typography,
  IconButton,
  Button,
  TextField,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tooltip,
  MenuItem
} from '@mui/material';

// project import
import Breadcrumb from 'component/Breadcrumb';
import { gridSpacing } from 'config.js';
import initializeAxios from 'utils/axios';

// assets
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import UserIcon from '@mui/icons-material/Person';
import '../../../assets/scss/actions.scss';
import useAuth from 'hooks/useAuth';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import { states } from 'utils/states';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ==============================|| CONTACT LIST ||============================== //
const columns = [
  {
    id: 'id',
    label: 'Id'
  },
  { id: 'name', label: 'Company Name' }
];

const Company = () => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };
  const handleClosedialog = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post('/api/company', { company });
        setCompanies([...companies, response.data]);
        break;
      }
      case 'Edit': {
        const response = await axios.put(`/api/company/${companyId}`, { company });
        const tempArray = [...companies];
        const index = tempArray.findIndex((e) => e.id === companyId);
        tempArray[index] = {
          ...response.data
        };
        setCompanies(tempArray);
        break;
      }

      case 'Delete': {
        await axios.delete(`/api/company/${companyId}`);
        const tempArray = [...companies];
        const index = tempArray.findIndex((e) => e.id === companyId);
        tempArray.splice(index, 1);
        setCompanies(tempArray);
        break;
      }
    }

    setOpen(false);
  };

  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState({});
  const [companyId, setCompanyId] = useState(null);
  const [actionType, setActionType] = useState('');
  const navigate = useNavigate();

  const handleEdit = (editCompany) => {
    setCompany({
      name: editCompany?.name,
      address1: editCompany?.address1,
      address2: editCompany?.address2,
      city: editCompany?.city,
      state: editCompany?.state,
      zip: editCompany?.zip
    });
    setCompanyId(editCompany.id);
    setActionType('Edit');
    setOpen(true);
  };

  const handleDelete = (deleteItem) => {
    setCompanyId(deleteItem.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleUser = (row) => {
    navigate(`/admin/company/${row.id}/users`);
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setCompany({
      ...company,
      [name]: value
    });
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get('/api/company')
      .then((response) => {
        setCompanies(response.data);
      })
      .then(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Breadcrumb title="List of Companies" sx={{ mt: { xs: 2, lg: 0 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Companies {'( Admin )'}
        </Typography>
      </Breadcrumb>
      <Grid container wrap="nowrap" justifyContent="flex-end" sx={{ mb: 3 }} spacing={gridSpacing}>
        <Grid item>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClosedialog}
            sx={{
              '& .MuiDialog-container': {
                justifyContent: 'flex-end',
                '& .MuiDialog-paper': {
                  m: 0,
                  borderRadius: 0,
                  maxWidth: 450,
                  maxHeight: '100%',
                  height: '100vh',
                  width: { xs: '100vw' }
                }
              }
            }}
          >
            <DialogTitle>
              {actionType === 'Create'
                ? 'New Company'
                : actionType === 'Edit'
                ? 'Edit Company'
                : actionType === 'Delete'
                ? 'Delete Company'
                : ''}
            </DialogTitle>
            <DialogContent>
              {actionType !== 'Delete' ? (
                <>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Company Name"
                    name="name"
                    variant="outlined"
                    error={!company.name}
                    helperText={!company.name ? 'Company Name can not be empty' : ''}
                    value={company.name || ''}
                    onChange={handleChange}
                  />
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Address 1"
                    name="address1"
                    variant="outlined"
                    value={company.address1 || ''}
                    onChange={handleChange}
                  />
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Address 2"
                    name="address2"
                    variant="outlined"
                    value={company.address2 || ''}
                    onChange={handleChange}
                  />
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="City"
                    name="city"
                    variant="outlined"
                    value={company.city || ''}
                    onChange={handleChange}
                  />
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    select
                    label="State"
                    name="state"
                    variant="outlined"
                    value={company.state || ''}
                    onChange={handleChange}
                  >
                    {states.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Zip"
                    name="zip"
                    variant="outlined"
                    value={company.zip || ''}
                    onChange={handleChange}
                  />
                </>
              ) : (
                <DialogContentText>Confirm if you want to delete this company</DialogContentText>
              )}
            </DialogContent>
            <DialogActions className="actions">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                startIcon={actionType !== 'Delete' ? <AddIcon /> : ''}
                disabled={actionType !== 'Delete' ? !company.name : null}
              >
                {actionType !== 'Delete' ? 'Save' : 'Delete'}
              </Button>
              <Button variant="text" onClick={handleClosedialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        title="Companies"
        data={companies}
        columns={columns}
        isLoading={isLoading}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <>
            <Tooltip title="Edit Company">
              <span>
                <IconButton color="primary" aria-label="Edit" size="large" disabled={row.id === 1} onClick={() => handleEdit(row)}>
                  <EditTwoToneIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Company">
              <span>
                <IconButton color="secondary" aria-label="Edit" size="large" disabled={row.id === 1} onClick={() => handleDelete(row)}>
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Company Users">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleUser(row)}>
                <UserIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default Company;
