// third-party
// import { FormattedMessage } from 'react-intl';

// assets
import NavigationOutlinedIcon from '@mui/icons-material/NavigationOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FormatUnderlinedOutlinedIcon from '@mui/icons-material/FormatUnderlinedOutlined';
import GamesOutlinedIcon from '@mui/icons-material/GamesOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import CallToActionOutlinedIcon from '@mui/icons-material/CallToActionOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import FilterVintageOutlinedIcon from '@mui/icons-material/FilterVintageOutlined';
import FormatColorTextOutlinedIcon from '@mui/icons-material/FormatColorTextOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import EditAttributesOutlinedIcon from '@mui/icons-material/EditAttributesOutlined';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import EventNoteIcon from '@mui/icons-material/EventNote';
import UserIcon from '@mui/icons-material/Person';
import CompanyIcon from '@mui/icons-material/Business';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PilotIcon from '@mui/icons-material/SupportAgent';
import OptionsIcon from '@mui/icons-material/Category';
import AircraftIcon from '@mui/icons-material/FlightTakeoff';
import PolicyIcon from '@mui/icons-material/Policy';
import InsuranceIcon from '@mui/icons-material/GppGood';
import PolicyOptionIcon from '@mui/icons-material/List';
import LeinholderIcon from '@mui/icons-material/AccountBalance';
import StoreTwoToneIcon from '@mui/icons-material/StoreTwoTone';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import { LocalAirportTwoTone } from '@mui/icons-material';

const icons = {
  NavigationOutlinedIcon: NavigationOutlinedIcon,
  HomeOutlinedIcon: HomeOutlinedIcon,
  FormatUnderlinedOutlinedIcon: FormatUnderlinedOutlinedIcon,
  GamesOutlinedIcon: GamesOutlinedIcon,
  LoyaltyOutlinedIcon: LoyaltyOutlinedIcon,
  ForumOutlinedIcon: ForumOutlinedIcon,
  DescriptionOutlinedIcon: DescriptionOutlinedIcon,
  GridOnOutlinedIcon: GridOnOutlinedIcon,
  TableChartOutlinedIcon: TableChartOutlinedIcon,
  AccountTreeOutlinedIcon: AccountTreeOutlinedIcon,
  CallToActionOutlinedIcon: CallToActionOutlinedIcon,
  NotificationsNoneOutlinedIcon: NotificationsNoneOutlinedIcon,
  AppsOutlinedIcon: AppsOutlinedIcon,
  SmsOutlinedIcon: SmsOutlinedIcon,
  FilterVintageOutlinedIcon: FilterVintageOutlinedIcon,
  FormatColorTextOutlinedIcon: FormatColorTextOutlinedIcon,
  ContactSupportOutlinedIcon: ContactSupportOutlinedIcon,
  LayersOutlinedIcon: LayersOutlinedIcon,
  BlockOutlinedIcon: BlockOutlinedIcon,
  ChromeReaderModeOutlinedIcon: ChromeReaderModeOutlinedIcon,
  FiberManualRecordOutlinedIcon: FiberManualRecordOutlinedIcon,
  EditAttributesOutlinedIcon: EditAttributesOutlinedIcon,
  FaceOutlinedIcon: FaceOutlinedIcon,
  RadioButtonUncheckedOutlinedIcon: RadioButtonUncheckedOutlinedIcon,
  QuestionAnswerOutlinedIcon: QuestionAnswerOutlinedIcon,
  AccountCircleOutlinedIcon: AccountCircleOutlinedIcon,
  ShoppingCartOutlinedIcon: ShoppingCartOutlinedIcon,
  MailOutlineRoundedIcon: MailOutlineRoundedIcon,
  SpeakerNotesOutlinedIcon: SpeakerNotesOutlinedIcon,
  CardGiftcardOutlinedIcon: CardGiftcardOutlinedIcon,
  ErrorOutlineRoundedIcon: ErrorOutlineRoundedIcon,
  HourglassEmptyRoundedIcon: HourglassEmptyRoundedIcon,
  MonetizationOnOutlinedIcon: MonetizationOnOutlinedIcon,
  AssignmentIndOutlinedIcon: AssignmentIndOutlinedIcon,
  SecurityOutlinedIcon: SecurityOutlinedIcon,
  HelpOutlineOutlinedIcon: HelpOutlineOutlinedIcon,
  TranslateIcon: TranslateIcon,
  FormatListNumberedRtlIcon: FormatListNumberedRtlIcon,
  EventNoteIcon: EventNoteIcon,
  UserIcon: UserIcon,
  CompanyIcon: CompanyIcon,
  ContactPhoneIcon: ContactPhoneIcon,
  PilotIcon: PilotIcon,
  OptionsIcon: OptionsIcon,
  AircraftIcon: AircraftIcon,
  PolicyIcon: PolicyIcon,
  InsuranceIcon: InsuranceIcon,
  PolicyOptionIcon: PolicyOptionIcon,
  LeinholderIcon: LeinholderIcon,
  StoreTwoToneIcon: StoreTwoToneIcon,
  AirportIcon: LocalAirportTwoTone,
  PolicyFormIcon: Grid3x3Icon
};

// eslint-disable-next-line
export default {
  items: [
    {
      id: 'workflow',
      title: 'Quotation',
      type: 'group',
      icon: icons['NavigationOutlinedIcon'],
      children: [
        {
          id: 'aircraftQuote',
          title: 'Aircraft Quote Request',
          type: 'aircraftQuote'
        },
        {
          id: 'airportQuote',
          title: 'Airport Quote',
          type: 'airportQuote'
        }
      ]
    },
    {
      id: 'pilot-aircraft-policy',
      title: 'Pilots, Aircrafts, and Policies',
      type: 'group',
      icon: icons['NavigationOutlinedIcon'],
      children: [
        {
          id: 'pilots',
          title: 'Pilots',
          type: 'pilot',
          icon: icons['PilotIcon']
        },
        {
          id: 'aircrafts',
          title: 'Aircrafts',
          type: 'aircraft',
          icon: icons['AircraftIcon']
        },
        {
          id: 'policy',
          title: 'Policies',
          type: 'policy',
          icon: icons['PolicyIcon']
        }
      ]
    },
    {
      id: 'customer-org',
      title: 'Insured and Agencies',
      type: 'group',
      icon: icons['NavigationOutlinedIcon'],
      children: [
        {
          id: 'customer-organizations',
          title: 'Named Insured',
          type: 'cust-org-admin',
          icon: icons['ContactPhoneIcon']
        },
        {
          id: 'agency',
          title: 'Agencies',
          type: 'agency',
          icon: icons['StoreTwoToneIcon']
        },
        {
          id: 'airport',
          title: 'Airports',
          type: 'airport',
          icon: icons['AirportIcon']
        }
      ]
    },
    {
      id: 'company',
      title: 'Company Settings',
      type: 'group',
      icon: icons['NavigationOutlinedIcon'],
      children: [
        {
          id: 'list-of-company',
          title: 'List of Companies',
          type: 'company-super',
          icon: icons['CompanyIcon'],
          url: '/admin/company'
        },
        {
          id: 'policy-forms',
          title: 'Policy Form Ids',
          type: 'policy-form-super',
          icon: icons['PolicyFormIcon'],
          url: '/admin/policyForms'
        },
        {
          id: 'options',
          title: 'Options',
          type: 'company-super',
          icon: icons['OptionsIcon'],
          url: '/admin/options'
        },
        {
          id: 'company-users',
          title: 'Company Users',
          type: 'company-users-admin',
          icon: icons['UserIcon'],
          url: '/company/users'
        },
        {
          id: 'company-users-super',
          title: 'Super Admin Company Users',
          type: 'company-users-super',
          caption: 'TBD name',
          icon: icons['UserIcon']
        },
        {
          id: 'insurance',
          title: 'Insurance',
          type: 'insurance',
          icon: icons['InsuranceIcon']
        },
        {
          id: 'policyOptions',
          title: 'Policy Options Aircraft',
          type: 'policyOption',
          icon: icons['PolicyOptionIcon']
        },
        {
          id: 'leinholder',
          title: 'Other Interests',
          type: 'leinholder',
          icon: icons['LeinholderIcon']
        }
      ]
    },
  ]
};
