import { View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';

const styles = StyleSheet.create({
  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' } // Regular font
  ]
});
const EndingComponent = ({ extra = true, proof = true }) => (
  <>
    {extra && (
      <View style={styles.listItemParagraph}>
        <Text>
          Any notice will be mailed or delivered to the first Named Insured at the last known address known to{' '}
          <Text style={styles.boldText}>us.</Text> If notice is mailed, proof of mailing will be sufficient proof of notice.
        </Text>
      </View>
    )}
    {proof && (
      <View style={styles.listItemParagraph}>
        <Text>
          Proof of mailing or delivering of the notice is sufficient proof of notice. <Text style={styles.boldText}>We</Text> will compute
          the premium earned by us based on the percentage of the original policy period that <Text style={styles.boldText}>we</Text>{' '}
          provided coverage.
        </Text>
      </View>
    )}
    <View style={styles.listItemParagraph}>
      <Text>
        We will return to <Text style={styles.boldText}>you</Text> any premium that <Text style={styles.boldText}>you</Text> have paid that{' '}
        <Text style={styles.boldText}>we</Text> have not earned, but making the refund is not a condition of cancellation
      </Text>
    </View>
    <View style={styles.listItemParagraph}>
      <Text>
        However, if <Text style={styles.boldText}>we</Text> pay or have paid the <Text style={styles.boldText}>agreed value</Text> of the{' '}
        <Text style={styles.boldText}>aircraft,</Text> less any applicable deductible under the Aircraft Physical Damage coverage,{' '}
        <Text style={styles.boldText}>we</Text> are entitled to the total Aircraft Physical Damage premium shown on the Coverage
        Identification Page for the
        <Text style={styles.boldText}>aircraft</Text> on which <Text style={styles.boldText}>we</Text> made or make payment.{' '}
        <Text style={styles.boldText}>You</Text> agree to pay any premium that may be due or permit <Text style={styles.boldText}>us</Text>{' '}
        to deduct such premium from our loss payment.
      </Text>
    </View>
  </>
);

export default EndingComponent;
