import { Page, View, Text, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import { PageFooter } from '../../PageFooter';
import { styles } from '../../Styles';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

export const Page2 = ({formNumber}) => (
  <Page size="letter" style={styles.page}>
    <View style={styles.content}>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            3.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}Who is Not Protected
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>Your bodily injury</Text> and <Text style={styles.boldText}>property damage</Text> coverage does
            not protect:
          </Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Other Aviation Business Activities</Text>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Any persons or organizations (other than <Text style={styles.listItemParagraph}>you</Text> and{' '}
                <Text style={styles.boldText}>your</Text> employees as defined above), or employees or agents thereof, that make, sell,
                rent, repair or service aircraft or components, operate an airport facility, or provide instruction, pilot or flight
                service, where an <Text style={styles.boldText}>occurrence</Text> arises out of any of these activities; and
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Renter Pilots</Text>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                A <Text style={styles.boldText}>renter pilot</Text> with respect to any <Text style={styles.boldText}>occurrence</Text>{' '}
                arising out of the operation of the <Text style={styles.boldText}>aircraft</Text> by a{' '}
                <Text style={styles.boldText}>renter pilot.</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            4.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}What Is Not Covered
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> do not cover any:
          </Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Pilots and Use</Text>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> unless the
                requirements of the Coverage Identification Page regarding Pilots (Item 9) and Use (Item 10) are met;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Bodily Injury to Employees</Text>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Bodily injury</Text> to any employee or co-employee while acting in the course and scope of
                employment by <Text style={styles.boldText}>you</Text> or by <Text style={styles.boldText}>someone we protect</Text> for any
                claim against <Text style={styles.boldText}>you,</Text> against <Text style={styles.boldText}>someone we protect,</Text> or
                against a fellow employee. For purposes of this provision, the term “employee” means any person who receives compensation
                for services performed for <Text style={styles.boldText}>you</Text> or{' '}
                <Text style={styles.boldText}>domeone we protect</Text> who would be deemed an “employee” for worker’s compensation or
                unemployment benefits compensation purposes under applicable state law. The term “employee” also includes any person defined
                by the policy to be an employee in Paragraph 2 of this Part;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Property</Text>
              <Text style={styles.listItemHeading}>
                c.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Property damage</Text> to property which <Text style={styles.boldText}>you</Text> or{' '}
                <Text style={styles.boldText}>someone we protect</Text> owns, has charge of, or transports in the{' '}
                <Text style={styles.boldText}>aircraft;</Text>
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Intentional Acts</Text>
              <Text style={styles.listItemHeading}>
                d.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> that is
                intentionally caused by <Text style={styles.boldText}>you</Text> or by{' '}
                <Text style={styles.boldText}>someone we protect</Text> unless done while <Text style={styles.boldText}>in flight</Text> to
                prevent dangerous interference with the operation of the <Text style={styles.boldText}>aircraft;</Text>
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Injury to You</Text>
              <Text style={styles.listItemHeading}>
                e.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Bodily injury</Text> sustained by <Text style={styles.boldText}>you</Text> or damages claimed
                as a consequence of such <Text style={styles.boldText}>bodily injury.</Text>
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Student Pilots</Text>
              <Text style={styles.listItemHeading}>
                f.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Property damage</Text> or <Text style={styles.boldText}>bodily injury</Text> if the{' '}
                <Text style={styles.boldText}>aircraft</Text> is being operated <Text style={styles.boldText}>in flight</Text> by a Student
                Pilot with <Text style={styles.boldText}>passengers</Text> unless a<Text style={styles.boldText}>passenger</Text> is a pilot
                acting as pilot in command with the minimum requirements stated in Item 9 of <Text style={styles.boldText}>your</Text>{' '}
                Coverage Identification Page;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Assumed Liability</Text>
              <Text style={styles.listItemHeading}>
                g.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> that{' '}
                <Text style={styles.boldText}>you</Text> or <Text style={styles.boldText}>someone we protect</Text> has agreed to assume;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Ownership and Other Interests</Text>
              <Text style={styles.listItemHeading}>
                h.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> liability if{' '}
                <Text style={styles.boldText}>you</Text> lease, sell or mortgage all or some of <Text style={styles.boldText}>your</Text>{' '}
                interest in the <Text style={styles.boldText}>aircraft</Text>
                unless all interests of <Text style={styles.boldText}>others</Text> are stated in Item 1 or Item 11 of the Coverage
                Identification Page or in an Endorsement;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Radiation</Text>
              <Text style={styles.listItemHeading}>
                i.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> that is directly
                or indirectly caused by or arises out of ionizing radiation or contamination by radioactivity from any source; or
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Noise, Pollution, Electrical or Other Interference</Text>
              <Text style={styles.listItemHeading}>
                j.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> that is directly
                or indirectly caused by or arises out of:
              </Text>
              <View style={[styles.nestedListContainer, {marginTop: '-10px'}]}>
                <View style={{marginBottom: '-5px'}}>
                  <Text style={styles.listItemHeading}>
                    i.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>Noise, vibration or sonic boom;</Text>
                </View>
                <View style={{marginBottom: '-5px'}}>
                  <Text style={styles.listItemHeading}>
                    ii.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    Pollution and/or contamination of any kind;
                  </Text>
                </View>
                <View style={{marginBottom: '-5px'}}>
                  <Text style={styles.listItemHeading}>
                    iii.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    Electrical and/or magnetic interference; or
                  </Text>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>
                    iv.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>Interference with the use of property; </Text>
                </View>
              </View>
              <Text style={[styles.listItemParagraph, {marginTop: '-10px'}]}>
                unless caused by or resulting from an <Text style={styles.boldText}>occurrence.</Text>
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
    <PageFooter form={formNumber} number={9} />
  </Page>
);
