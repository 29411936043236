import { Page, View, Text, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import { PageFooter } from '../../PageFooter';
import { styles } from '../../Styles';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

export const Page1 = ({formNumber}) => (
  <Page size="letter" style={styles.page}>
    <View style={styles.content}>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            7.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}Theft
          </Text>
          <Text style={styles.listItemParagraph}>
            If the <Text style={styles.boldText}>aircraft</Text> or any part of it is stolen and recovered before{' '}
            <Text style={styles.boldText}>we</Text> have paid for it, <Text style={styles.boldText}>we</Text> may return it to{' '}
            <Text style={styles.boldText}>you</Text> along with payment for any physical damage to it.
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            8.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}Disappearance
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> will consider <Text style={styles.boldText}>your aircraft</Text> to be lost in flight if
            it disappears and it cannot be located within 60 days of its disappearance.
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            9.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}Reinstatement of Coverage
          </Text>
          <Text style={styles.listItemParagraph}>
            If <Text style={styles.boldText}>your aircraft</Text> is damaged, the amount of coverage for the{' '}
            <Text style={styles.boldText}>aircraft</Text> will be reduced by the amount of the damage. The coverage will be automatically
            increased, at no additional premium, by the cost of the repairs completed until the{' '}
            <Text style={styles.boldText}>agreed value</Text> has been restored or the policy expires. If, however,{' '}
            <Text style={styles.boldText}>we</Text> pay as much as the <Text style={styles.boldText}>agreed value,</Text> less the
            applicable deductible,
            <Text style={styles.boldText}>we</Text> are entitled to the total Aircraft Physical Damage premium for the{' '}
            <Text style={styles.boldText}>aircraft</Text> on which <Text style={styles.boldText}>we</Text> made or make the payment.
          </Text>
        </View>
      </View>
    </View>
    <View style={styles.header}>
      <Text>Part Three. LIABILITY TO OTHERS</Text>
    </View>
    <View style={styles.contentDivider} />
    <View style={styles.content}>
      <View>
        <Text>
          Review Item 6 of <Text style={styles.boldText}>your</Text> Coverage Identification Page to confirm the particular liability
          coverage and limits issued to <Text style={styles.boldText}>you.</Text>
        </Text>
      </View>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            1.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}What <Text style={styles.boldText}>We</Text> Cover
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> will pay for the damages that <Text style={styles.boldText}>you,</Text> or{' '}
            <Text style={styles.boldText}>someone we protect,</Text> are legally required to pay for{' '}
            <Text style={styles.boldText}>bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> to others caused by
            an <Text style={styles.boldText}>occurrence.</Text>
          </Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Coverage D covers <Text style={styles.boldText}>bodily injury and property damage</Text> in a combined limit of liability
                for each <Text style={styles.boldText}>occurrence.</Text> Where the word “<Text style={styles.boldText}>IN</Text>cluding” is
                shown in Item 6D, the most <Text style={styles.boldText}>we</Text> will pay for{' '}
                <Text style={styles.boldText}>bodily injury</Text> to all <Text style={styles.boldText}>passengers</Text> and all{' '}
                <Text style={styles.boldText}>others</Text> and <Text style={styles.boldText}>property damage</Text>
                is shown under Item 6D opposite “each occurrence.” Where the word “<Text style={styles.boldText}>EX</Text>cluding” is shown
                in Item 6D, the most <Text style={styles.boldText}>we</Text> will pay for
                <Text style={styles.boldText}>bodily injury</Text> to all <Text style={styles.boldText}>persons</Text> and{' '}
                <Text style={styles.boldText}>property damage</Text> is shown under Item 6D opposite “each occurrence,” but{' '}
                <Text style={styles.boldText}>we</Text> will not pay for
                <Text style={styles.boldText}>bodily injury</Text> to <Text style={styles.boldText}>passengers.</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Coverage DL covers <Text style={styles.boldText}>bodily injury</Text> to <Text style={styles.boldText}>passengers</Text> and{' '}
                <Text style={styles.boldText}>others</Text> and <Text style={styles.boldText}>property damage</Text> in a combined single
                limit of liability for each <Text style={styles.boldText}>occurrence</Text> which includes a lower limit of coverage for
                each <Text style={styles.boldText}>passenger.</Text> The most <Text style={styles.boldText}>we</Text> will pay for{' '}
                <Text style={styles.boldText}>bodily injury</Text> to each
                <Text style={styles.boldText}>passenger</Text> is shown in Item 6DL opposite “each person.” The most{' '}
                <Text style={styles.boldText}>we</Text> will pay for all <Text style={styles.boldText}>bodily injury</Text> and{' '}
                <Text style={styles.boldText}>property damage</Text> is shown in Item 6DL opposite “each{' '}
                <Text style={styles.boldText}>occurrence.</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            2.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}Who Is Protected (<Text style={styles.boldText}>You</Text> and <Text style={styles.boldText}>Someone We Protect</Text>
            )
          </Text>
          <Text style={styles.listItemParagraph}>
            Except for those entities and persons described in Paragraph 3 below, <Text style={styles.boldText}>your bodily injury</Text>{' '}
            and <Text style={styles.boldText}>property damage</Text> liability coverage protects <Text style={styles.boldText}>you</Text>{' '}
            and <Text style={styles.boldText}>someone we protect.</Text> The term <Text style={styles.boldText}>someone we protect</Text>{' '}
            means any organization or person <Text style={styles.boldText}>you</Text> permit to operate the
            <Text style={styles.boldText}>aircraft.</Text> The term <Text style={styles.boldText}>someone we protect</Text> also includes
            the following:
          </Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                any person riding in the <Text style={styles.boldText}>aircraft</Text> and any person or organization legally responsible
                for the use of the <Text style={styles.boldText}>aircraft</Text> provided such use is with{' '}
                <Text style={styles.boldText}>your</Text> express permission; and
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                any employee while acting within the scope of his or her employment by <Text style={styles.boldText}>you</Text> or{' '}
                <Text style={styles.boldText}>someone we protect.</Text> Provided, however, no employee is{' '}
                <Text style={styles.boldText}>someone we protect</Text> with respect to:
              </Text>
              <View style={[styles.nestedListContainer, { marginTop: '-10px' }]}>
                <View>
                  <Text style={styles.listItemHeading}>
                    i.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.listItemParagraph, styles.nestedListItemParagraph]}>
                    <Text style={styles.boldText}>Bodily injury</Text> to a co-employee while in the course or scope of his or her
                    employment; or
                  </Text>
                </View>
                <View style={{ marginBottom: 5 }}>
                  <Text style={styles.listItemHeading}>
                    ii.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.listItemParagraph, styles.nestedListItemParagraph]}>
                    <Text style={styles.boldText}>Property damage</Text> to property owned, occupied or rented by, or loaned to that
                    employee or to any of <Text style={styles.boldText}>your</Text> other employees or the employees of{' '}
                    <Text style={styles.boldText}>someone we protect.</Text>
                  </Text>
                </View>
              </View>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                If <Text style={styles.boldText}>instruction and rental, charter/air taxi</Text> or other uses involving a charge made to{' '}
                <Text style={styles.boldText}>others</Text> are permitted by <Text style={styles.boldText}>your</Text> Policy, any person
                who receives compensation for providing flight instruction or pilot services (including flight checkouts, flight reviews,
                practical tests for license or <Text style={styles.boldText}>aircraft</Text> rating purposes, or other pilot services
                permitted by <Text style={styles.boldText}>your</Text> policy) in the
                <Text style={styles.boldText}>aircraft</Text> for <Text style={styles.boldText}>your</Text> benefit or on{' '}
                <Text style={styles.boldText}>your</Text> behalf shall be considered to be <Text style={styles.boldText}>your</Text>{' '}
                employee acting within the course and scope of employment with respect to an <Text style={styles.boldText}>occurrence</Text>{' '}
                that arises out of such activities, regardless of whether <Text style={styles.boldText}>you</Text> deem that person to be
                your employee or an independent contractor for any other purposes under any state or federal employment-related ordinance,
                statute or regulation.
              </Text>
            </View>
          </View>
          <Text style={[styles.listItemParagraph, { marginBottom: '-5px' }]}>
            <Text style={styles.boldText}>You</Text> and <Text style={styles.boldText}>someone we protect</Text> are protected separately.
            However, none of the provisions, terms, conditions and exclusions of the policy change by virtue of this protection and the
            limits of coverage shown in Item 6 of the Coverage Identification Page do not increase regardless of (a) the number of the
            entities or persons protected, or (b) the number of <Text style={styles.boldText}>aircraft</Text> involved in the
            <Text style={styles.boldText}>occurrence.</Text>
          </Text>
        </View>
      </View>
    </View>
    <PageFooter form={formNumber} number={8} />
  </Page>
);
