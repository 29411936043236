import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import BeginningComponent from '../Components/BeginingComponent';
import EndingComponent from '../Components/EndingComponent';
import TexasInformationandComplaintNotice from './InformationNotice';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const TexasEndorsement = ({ formNumber, formNumber2}) => {
  return (
    <>
      <Page size="LETTER" style={styles.page}>
        <BeginningComponent state="Texas" />
        <View style={styles.content}>
          <View style={styles.listContainer}>
            <View style={styles.listItem}>
              <View style={styles.nestedListContainer}>
                <View>
                  <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>
                      This policy can be canceled at any time for nonpayment of premium by mailing or delivering a notice of cancellation to
                      the first Named Insured at least 10 days before the effective date of the cancellation;
                    </Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>
                      Pursuant to the provisions of the Texas Insurance Code, <Text style={styles.boldText}>we</Text> will not cancel or
                      elect to non-renew this policy based solely on the fact that <Text style={styles.boldText}>you</Text> are an elected
                      official.
                    </Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>
                      If this policy has been in effect less than 60 days <Text style={styles.boldText}>we</Text> may cancel at any time by
                      mailing or delivering a notice of cancellation to the first Named Insured at least 30 days before the effective date
                      of cancellation; or
                    </Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>d. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>
                      If this policy has been in effect for 60 days or more, <Text style={styles.boldText}>we</Text> may cancel at any time
                      by mailing or delivering a notice of cancellation to the first Named Insured at least 30 days before the effective
                      date of cancellation, but only for one or more of the following reasons:
                    </Text>
                  </View>
                  <View style={[styles.nestedListContainer, { gap: 1 }]}>
                    <View>
                      <Text style={styles.listItemHeading}>(1). &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                        <Text>Fraud in obtaining coverage;</Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>(2). &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                        <Text>
                          An increase in hazard within <Text style={styles.boldText}>your</Text> control which would produce an increase in
                          rate;
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>(3). &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                        <Text>
                          Loss of <Text style={styles.boldText}>our</Text> reinsurance coverage all or a part of the risk covered by the
                          policy; or
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>(4). &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                        <Text>
                          If <Text style={styles.boldText}>we</Text> have been placed in supervision, conservatorship or receivership and
                          the cancellation is approved or directed by the supervisor, conservator or receiver.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <EndingComponent extra={false} />
              <View style={styles.listItemParagraph}>
                <Text>
                  If we elect not to renew this policy <Text style={styles.boldText}>we</Text> will mail or deliver written notice of non
                  renewal to the first Named Insured at least 60 days before the expiration date. If notice is mailed or delivered less than
                  60 days before the expiration date, this policy will remain in effect until the Gist day after the date on which the
                  notice is mailed or delivered. Earned premium for any period of coverage that extends beyond the expiration date will be
                  computed pro rata based on the previous year`s premium.
                </Text>
              </View>
              <View style={styles.listItemParagraph}>
                <Text>
                  Pursuant to the provisions of the Texas Insurance Code, <Text style={styles.boldText}>we</Text> will not cancel or elect
                  to non-renew this policy based solely on the fact that you are an elected official.
                </Text>
              </View>
            </View>
          </View>
        </View>
        <FooterPDF page={1} endPage={2} form={formNumber} />
      </Page>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.content}>
          <View style={styles.listContainer}>
            <View style={styles.listItem}>
              <View style={styles.listItemParagraph}>
                <Text>
                  <Text style={styles.boldText}>We</Text> will mail or deliver any notice to the first Named Insured at the last known
                  mailing address as shown in Item 2 of the Coverage Identification Page of this policy. Any notice of cancellation shall
                  state the actual reason for the action taken. If notice is mailed, proof of mailing is sufficient proof of notice. This
                  endorsement changes <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS</Text>, Item 5, Additional Protection,
                  a. Defend Claims to read as follows:
                </Text>
              </View>
            </View>
            <View style={styles.listItem}>
              <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
                <Text>Defend Claims</Text>
              </View>
              <View style={styles.listItemParagraph}>
                <Text>
                  <Text style={styles.boldText}>We</Text> have the right and duty to defend any suit against{' '}
                  <Text style={styles.boldText}>you</Text> or <Text style={styles.boldText}>someone we protect</Text> seeking damages
                  because of <Text style={styles.boldText}>bodily injury</Text> or <Text style={styles.boldText}>property damage</Text>{' '}
                  covered by this policy, even if any of the allegations of the suit are groundless, false or fraudulent.{' '}
                  <Text style={styles.boldText}>We</Text> may make any investigation as we deem expedient. We will not settle any claim
                  without your consent. We will notify the first Named Insured in writing of an initial offer to compromise or settle a
                  claim made or suit brought against <Text style={styles.boldText}>you</Text> or{' '}
                  <Text style={styles.boldText}>someone we protect</Text> under this coverage. The notice will be given not later than the
                  10th day after the date on which the offer is made. Any settlement of a claim made or suit brought against the insured
                  under this coverage. The notice will be given not later than the 30th day after the date of the settlement.
                </Text>
              </View>
              <View style={styles.listItemParagraph}>
                <Text>
                  If <Text style={styles.boldText}>we</Text> recommend a settlement to <Text style={styles.boldText}>you</Text> which is
                  acceptable to the claimant, and <Text style={styles.boldText}>you</Text> do not agree,{' '}
                  <Text style={styles.boldText}>our</Text> liability is limited to the total of the amount for which the claim could have
                  been settled plus the amount of defense expenses up to the time <Text style={styles.boldText}>we</Text> made the
                  recommendation. <Text style={styles.boldText}>Our</Text> payment of the limit of liability ends our duty to defend or
                  settle. <Text style={styles.boldText}>We</Text> have no duty to defend any claims not covered by this policy.{' '}
                  <Text style={styles.boldText}>We</Text> will pay defense expenses.
                </Text>
              </View>
              <View style={styles.listItemParagraph}>
                <Text>
                  <Text style={styles.boldText}>We</Text> will not be obligated to pay any expenses, claims or judgments or to defend any
                  suit after the applicable limit of coverage has been exhausted by the payment of judgments or settlements, or if any
                  deductible payable by <Text style={styles.boldText}>you</Text> under which a claim is made is not paid by{' '}
                  <Text style={styles.boldText}>you</Text> upon <Text style={styles.boldText}>our</Text> request.
                </Text>
              </View>
            </View>
          </View>
        </View>
        <FooterPDF sub={true} page={2} endPage={2} form={formNumber} />
      </Page>
      <TexasInformationandComplaintNotice formNumber={formNumber2} />
    </>
  );
};

export default TexasEndorsement;
