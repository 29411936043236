import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import BeginningComponent from '../Components/BeginingComponent';
import EndingComponent from '../Components/EndingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const SouthCarolinaEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <BeginningComponent state="South Carolina" />
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any time for nonpayment of premium by mailing or delivering a notice of cancellation to
                    the first Named Insured at least 10 days before the effective date of the cancellation;
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect less than 90 days <Text style={styles.boldText}>we</Text> may cancel at any time by
                    mailing or delivering a notice of cancellation to the first Named Insured at least 30 days before the effective date of
                    cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect for 90 days or more, <Text style={styles.boldText}>we</Text> may cancel at any time by
                    mailing or delivering a notice of cancellation to the first Named Insured at least 30 days before the effective date of
                    the cancellation for only one or more of the following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1). &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Nonpayment of premium</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2). &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Material misrepresentation of fact which, if known to us, would have caused us not to issue the policy;</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3). &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Substantial change in the risk assumed, except to the extent that we should reasonably have foreseen the change or
                        contemplated the risk in writing the policy;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(4). &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Substantial breaches of contractual duties, conditions or warranties;</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(5). &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        If <Text style={styles.boldText}>we</Text> loose <Text style={styles.boldText}>our</Text> reinsurance for the risk;
                        or
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(6). &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Loss of our reinsurance covering all or a significant portion of the particular policy insured, or where
                        continuation of the policy would imperil our solvency or place us in violation of the insurance laws of the State of
                        South Carolina.
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    Prior to cancellation for reasons permitted in this Item (5) or (6), <Text style={styles.boldText}>we</Text> will notify
                    the Commissioner, in writing, at least sixty days prior to such cancellation and the Commissioner will, within thirty
                    days of such notification, approve or disapprove such action.
                  </Text>
                </View>
              </View>
            </View>
            <EndingComponent extra={false} />
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> decide not to renew this policy, <Text style={styles.boldText}>we</Text> will
                mail or deliver written notice of nonrenewal to the first Named Insured and agent, if any, at least 60 days prior to the
                expiration of the policy if the policy expiration date is between November 1st and May 31st or 90 days if the expiration
                date is between June 1st and October31st.
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>The expiration date of this policy, if the policy is written for a term of one year or less; or</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    An anniversary date of this policy, if the policy is written for a term of more than one year or for an indefinite term.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                However, <Text style={styles.boldText}>we</Text> will not refuse to renew a policy issued for a term of more than one year,
                until expiration of its full term, if anniversary renewal has been guaranteed by additional premium consideration.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.listItemParagraph}>
              <Text>
                Any notice of nonrenewal will be mailed or delivered to the first Named lnsured`s and agent`s last known addresses. If
                notice is mailed, proof of mailing will be sufficient proof of notice.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>Any notice of non renewal will state the precise reason for non renewal.</Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} form={formNumber}/>
    </Page>
  </>
);

export default SouthCarolinaEndorsement;
