import { Page, View, Text, Font, StyleSheet } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  container: {
    fontFamily: 'OpenSans',
    display: 'flex' /* Enables flexbox */,
    justifyContent: 'center' /* Centers the box horizontally */,
    alignItems: 'center',
    gap: 10
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  table: {
    display: 'table',
    width: 'auto'
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableCol: {
    width: '33.33%', // Three equal columns
    padding: '0 10px 10px 10px'
  },
  tableHeader: {
    width: '33.33%',
    fontFamily: 'OpenSans',
    padding: 10,
    borderBottomWidth: 1, // Border only at the bottom
    borderColor: '#000',
    fontWeight: 'bold'
  },
  tableCell: {
    width: '33.33%',
    padding: 10
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' }
  ]
});

const LeasedAircraftEndorsement = ({ aircrafts, formNumber }) => (
  <Page size="letter" style={styles.page}>
    <View style={[styles.header, { fontSize: 8 }]}>
      <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
    </View>
    <View style={styles.header}>
      <Text style={styles.boldText}>Leased Aircraft Endorsement</Text>
    </View>
    <View style={styles.content}>
      <View>
        <Text>
          Various provisions of this endorsement extend or restrict coverage. Read <Text style={styles.boldText}>your</Text> entire policy
          to determine <Text style={styles.boldText}>your</Text> rights and obligations and what is and is not covered. This endorsement
          changes the provisions of <Text style={styles.boldText}>your</Text> policy under the following coverage parts:
        </Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE</Text>
        <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS</Text>
      </View>
      <View>
        <Text>
          This endorsement extends coverage on the <Text style={styles.boldText}>aircraft</Text> described below and in Item 5 of the
          Coverage Identification Page which is leased by <Text style={styles.boldText}>you</Text> in accordance with the terms of a current
          written lease agreement between <Text style={styles.boldText}>you</Text> and the Lessor who is the owner(s) of the{' '}
          <Text style={styles.boldText}>aircraft.</Text> However, there is no coverage under <Text style={styles.boldText}>your</Text>{' '}
          policy for a leased <Text style={styles.boldText}>aircraft</Text> if (a) the lease agreement expires or is terminated by{' '}
          <Text style={styles.boldText}>you</Text> or the Lessor, or (b) the <Text style={styles.boldText}>aircraft</Text> is sold or
          otherwise disposed of in any manner.
        </Text>
      </View>
      <View style={styles.header}>
        <Text>DESCRIPTION OF LEASED AIRCRAFT</Text>
      </View>
      <View style={styles.table}>
        {/* Table Header */}
        <View style={styles.tableRow}>
          <Text style={styles.tableHeader}>FAA Number</Text>
          <Text style={styles.tableHeader}>Year, Make & Model Aircraft</Text>
          <Text style={styles.tableHeader}>Name and Address of Lessor</Text>
        </View>
        {/* Table Body */}
        {aircrafts?.filter((item) => item?.Aircraft?.isLeased)?.length > 0 ? (
          aircrafts
            ?.filter((item) => item.isLeased)
            ?.map((item) => (
              <View key={item.id} style={styles.tableRow}>
                <Text style={styles.tableCell}>{item?.Aircraft?.registrationNo}</Text>
                <Text style={styles.tableCell}>{`${item?.Aircraft?.year} ${item?.Aircraft?.make} ${item?.Aircraft?.model}`}</Text>
                <Text style={styles.tableCell}>Data 3</Text>
              </View>
            ))
        ) : (
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
          </View>
        )}
      </View>
      <View>
        <Text>
          The following provisions will be applicable to the leased <Text style={styles.boldText}>aircraft</Text> identified above:
        </Text>
      </View>
      <View>
        <Text>
          Under <Text style={styles.boldText}>Part Two AIRCRAFT PHYSICAL DAMAGE COVERAGE:</Text>
        </Text>
      </View>
      <View style={styles.listContainer}>
        <View>
          <Text>
            Loss or damage to a leased <Text style={styles.boldText}>aircraft,</Text> if any, will be adjusted and settled with{' '}
            <Text style={styles.boldText}>you</Text> on the same basis as if <Text style={styles.boldText}>you</Text> owned the
            <Text style={styles.boldText}>aircraft,</Text> and will be payable to <Text style={styles.boldText}>you,</Text> the Lessor and
            the Lienholder, if any, shown in Item 11 of the Coverage Identification Page or in an endorsement{' '}
            <Text style={styles.boldText}>we</Text> issue.
          </Text>
        </View>
      </View>
      <View>
        <Text>Under Part Three. LIABILITY TO OTHERS:</Text>
      </View>
      <View style={[styles.listContainer, { gap: 10 }]}>
        <View>
          <Text>
            Your bodily injury and property damage coverage and the definition of you under your policy is extended to include the Lessor of
            the leased aircraft with respect to bodily injury or property damage liability caused by an occurrence involving the leased
            aircraft owned by the Lessor. No coverage is provided by the policy with respect to bodily injury or property damage sustained
            by any person identified above as a Lessor.
          </Text>
        </View>
        <View>
          <Text>
            This extension of coverage does not apply to any Lessor that makes, sells, rents, repairs or services aircraft or components,
            operates any airport facility, or provides instruction, pilot or flight service where an occurrence arises out of these
            activities.
          </Text>
        </View>
        <View>
          <Text>
            This extension of coverage does not apply to the use of the leased aircraft by any person identified above as a Lessor, or by
            any family member thereof, unless that person is designated as an approved pilot for the leased aircraft in an endorsement to
            the policy.
          </Text>
        </View>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber} />
  </Page>
);

export default LeasedAircraftEndorsement;
