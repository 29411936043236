import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import EndorsementHeader from 'views/PDFComponents/EndorsementHeader';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const SpecialCoverPilotEndorsement = ({formNumber}) => (
  <Page size="LETTER" style={styles.page}>
    <EndorsementHeader title="Special Use, Coverage and Pilots Limitations Endorsement" />
    <View style={styles.content}>
      <View style={[styles.centeredText, { marginTop: '-10px' }]}>
        <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS</Text>
        <Text style={styles.boldText}>Part Four. MEDICAL EXPENSE</Text>
      </View>
      <View>
        <Text>
          <Text style={styles.boldText}>We</Text> agree to provide coverage for use of the <Text style={styles.boldText}>aircraft</Text> for
          the purpose(s) and by the pilot(s) described in this endorsement. However, the coverage provided by{' '}
          <Text style={styles.boldText}>your</Text> policy will be subject to the restrictions, conditions or limitations described in this
          endorsement when the <Text style={styles.boldText}>aircraft</Text> is operated in flight by the designated pilot(s) for the
          purposes described below:
        </Text>
      </View>
      <View style={styles.centeredText}>
        <Text style={styles.boldText}>Special Use Description</Text>
      </View>
      <View>
        <Text>
          <Text style={styles.boldText}>AIRSHOWS FOR HIRE.</Text> Use of the <Text style={styles.boldText}>Aircraft</Text> by{' '}
          <Text style={styles.boldText}>you</Text> or <Text style={styles.boldText}>someone we protect</Text> in airshows involving a charge
          made to others for such use.
        </Text>
      </View>
      <View style={styles.centeredText}>
        <Text style={styles.boldText}>Requirements for the Pilot Flying the Aircraft</Text>
      </View>
      <View>
        <Text>
          The <Text style={styles.boldText}>aircraft</Text> must be operated in flight only by a pilot named below having the minimum
          qualifications shown below or in an endorsement to the policy. The pilot must have a current and valid (1) medical certificate,
          (2) flight review and (3) pilot certificate with necessary ratings, each as required by the{' '}
          <Text style={styles.boldText}>FAA</Text> for each flight. There is no coverage if the pilot does not meet the qualifications or
          requirements specified below for the operation of the <Text style={styles.boldText}>aircraft</Text> in flight for the special
          purpose(s) permitted by this endorsement.
        </Text>
      </View>
      <View>
        <Text>Name</Text>
      </View>
      <View style={styles.centeredText}>
        <Text style={styles.boldText}>Other Provisions, Restrictions and Conditions</Text>
      </View>
      <View>
        <Text>
          <Text style={styles.boldText}>You</Text> and <Text style={styles.boldText}>we</Text> further agree that no coverage is provided by{' '}
          <Text style={styles.boldText}>your</Text> policy under <Text style={styles.boldText}>Part Three, LIABILITY TO OTHERS,</Text> or{' '}
          <Text style={styles.boldText}>Part Four, MEDICAL EXPENSE,</Text> for or with respect to{' '}
          <Text style={styles.boldText}>passenger bodily injury</Text> when the <Text style={styles.boldText}>aircraft</Text> is being
          operated in flight for the special purpose(s) permitted by this endorsement.
        </Text>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber}/>
  </Page>
);

export default SpecialCoverPilotEndorsement;
