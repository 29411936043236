import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 4
  },
  bullet: {
    width: 10,
    textAlign: 'center'
  },
  listText: {
    marginLeft: 4
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const WarConfiscationEndorsement = ({ aircraft = {}, formNumber }) => (
  <Page size="LETTER" style={styles.page}>
    <View style={[styles.header, { fontSize: 8 }]}>
      <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
    </View>
    <View style={styles.header}>
      <Text style={styles.boldText}>War-Confiscation Coverage</Text>
    </View>
    <View style={styles.centeredText}>
      <Text>AIRCRAFT PHYSICAL DAMAGE</Text>
    </View>
    <View style={styles.content}>
      <View>
        <Text>
          This Endorsement only applies to the following <Text style={styles.boldText}>aircraft: {aircraft?.registrationNo}</Text>
        </Text>
      </View>
      <View style={styles.centeredText}>
        <Text style={{ fontStyle: 'italic' }}>
          If no entry is made this endorsement applies to all <Text style={styles.boldText}>aircraft</Text> covered by{' '}
          <Text style={styles.boldText}>your</Text> policy.
        </Text>
      </View>
      <View>
        <Text>
          Various provisions of this endorsement extend or restrict coverage. Read <Text style={styles.boldText}>your</Text> entire policy
          to determine <Text style={styles.boldText}>your</Text> rights and obligations and what is and is not covered. This endorsement
          changes the provisions of <Text style={styles.boldText}>your</Text> policy under the following coverage parts:
        </Text>
      </View>
      <View style={styles.centeredText}>
        <Text style={styles.boldText}>Part Two. Aircraft Physical Damage Coverage</Text>
      </View>
      <View>
        <Text>
          <Text style={styles.boldText}>Your</Text> coverage under Part Two (Aircraft Physical Damage Coverage) is extended to include
          physical loss of or damage to the <Text style={styles.boldText}>aircraft</Text>
          caused by terrorist activities or arrest, restraint, seizure, confiscation, detention by or at the direction of any government; or
          caused by declared or undeclared war.
        </Text>
      </View>
      <View>
        <Text>This extension of coverage, however, does not apply:</Text>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.bullet}>*</Text>
            <Text style={styles.listText}>
              If a state of war exists, whether or not there has been a declaration of war, between any of the following countries: The
              United States of America, the United Kingdom, France, the Commonwealth of Independent States (or the countries which were part
              of the former Soviet Republics) or the Peoples Republic of China; or,
            </Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.bullet}>*</Text>
            <Text style={styles.listText}>
              To physical loss of or damage to the <Text style={styles.boldText}>aircraft</Text> caused by the hostile detonation of any
              weapon of war employing atomic or nuclear fission and/or fusion or other similar reaction or radioactive force or matter,
              wherever and whenever such detonation shall occur.
            </Text>
          </View>
        </View>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber} />
  </Page>
);

export default WarConfiscationEndorsement;
