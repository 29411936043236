import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const WashingtonEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Washington Change Endorsement</Text>
      </View>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS</Text>, Paragraph 8,
            Canceling the Policy, to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>8. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Canceling the Policy</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>You</Text> may cancel <Text style={styles.boldText}>your</Text> policy at any time by
                notififying us through <Text style={styles.boldText}>our Aviation Managers</Text> or your agent of the date{' '}
                <Text style={styles.boldText}>you</Text> want <Text style={styles.boldText}>your</Text> coverage to end by;
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Written notice by mail, fax or email; or</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Surrender of the policy or binder; or</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Verbal notice.</Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Upon receipt of such notice <Text style={styles.boldText}>we</Text> will cancel this policy or binder, issued as evidence of
                coverage, effective on the later of the following;
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>The date on which the notice is received or the policy or binder is surrendered; or</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    The date of cancellation requested by <Text style={styles.boldText}>your</Text>.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> can cancel this policy at any time by providing written notice to{' '}
                <Text style={styles.boldText}>you</Text> and <Text style={styles.boldText}>your</Text> agent stating the reason for
                cancellation at least:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    at least 10 days before the effective date of the cancellation if <Text style={styles.boldText}>we</Text> cancel for
                    non-payment of premium; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    at least 45 days before the effective date of cancellation if <Text style={styles.boldText}>we</Text> cancel for any
                    other reason.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>You</Text> cancel the policy, <Text style={styles.boldText}>we</Text> will return 90% of
                the unearned premium <Text style={styles.boldText}>you</Text> have paid. If <Text style={styles.boldText}>we</Text> cancel
                the policy, <Text style={styles.boldText}>we</Text> will compute the premium earned by{' '}
                <Text style={styles.boldText}>us</Text> based upon the percentage of the original policy period that{' '}
                <Text style={styles.boldText}>we</Text> provided coverage. <Text style={styles.boldText}>We</Text> will return to{' '}
                <Text style={styles.boldText}>you</Text> any premium that you have paid that we have not earned, but making the refund is
                not a condition of cancellation.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> decide not to renew this policy <Text style={styles.boldText}>we</Text> will mail
                or deliver written notice of non-renewal at least 45 days before the expiration date of the policy.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>Any notice we provide will state the reason or reasons for the action taken and be mailed or delivered to:</Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Each Named Insured;</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    Anyone shown by the terms of <Text style={styles.boldText}>your</Text> policy to have an interest in{' '}
                    <Text style={styles.boldText}>your aircraft</Text>; and
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    The Named lnsured`s agent or broker at their last known mailing address known to{' '}
                    <Text style={styles.boldText}>us.</Text> If notice is mailed, proof ofmailing is sufficient proof of notice.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement also changes <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS</Text>, Paragraph 11.{' '}
            <Text style={styles.boldText}>our</Text> Right of Recovery, to add the following provision:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>11. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                <Text style={styles.boldText}>Our</Text> Right of Recovery
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>Our</Text> right of recovery with respect to any payment{' '}
                <Text style={styles.boldText}>we</Text> make for any loss or damage to the <Text style={styles.boldText}>aircraft</Text>{' '}
                under <Text style={styles.boldText}>Part Two AIRCRAFT PHYSICAL DAMAGE COVERAGE</Text> does not apply unless and until you
                have been fully reimbursed, up the <Text style={styles.boldText}>agreed value</Text>, for the loss or damage.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement also changes <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS</Text>, Paragraph 14.
            Concealment or Misrepresentation, is changed to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>14. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Concealment or Misrepresentation</Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> do not provide coverage for <Text style={styles.boldText}>you</Text> or{' '}
                <Text style={styles.boldText}>someone we protect</Text> if you or someone we protect Has deliberately concealed or
                misrepresented any material fact or circumstance relating to the policy either before or after an{' '}
                <Text style={styles.boldText}>accident</Text> or <Text style={styles.boldText}>occurrence</Text>,
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement also changes <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE</Text>, Paragraph 4.
            What we will not pay item f. War- Confiscation to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>4. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                What <Text style={styles.boldText}>We</Text> will not pay
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>War confiscation</Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>f. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    Caused by arrest, restraint, seizure, confiscation, detention of any government or caused by declared or undeclared war.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement also changes <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE</Text>, Paragraph 9.
            Reinstatement of Coverage, to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>9. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Reinstatement of Coverage</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>your aircraft</Text> is damaged, the amount of coverage for the{' '}
                <Text style={styles.boldText}>aircraft</Text> will be reduced by the amount of the damage. The coverage will be
                automatically increased, at no additional premium, by the cost of the repairs completed until the{' '}
                <Text style={styles.boldText}>agreed value</Text> has been restored or the policy expires or is canceled.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement also changes <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE</Text>, Paragraph 5.
            Reinstatement of Coverage, to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>5. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Additional Protection</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> also provide the following additional protection with each coverage under Part
                Three. <Text style={styles.boldText}>Our</Text> obligation under this section will terminate when{' '}
                <Text style={styles.boldText}>we</Text> have paid the limits of coverage that <Text style={styles.boldText}>you</Text> have
                purchased toward any judgment or settlement approved by <Text style={styles.boldText}>you.</Text>
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} form={formNumber}/>
    </Page>
  </>
);

export default WashingtonEndorsement;
