import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import EndorsementHeader from 'views/PDFComponents/EndorsementHeader';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 4
  },
  bullet: {
    width: 10,
    textAlign: 'center'
  },
  listText: {
    marginLeft: 4
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  textLeft: {
    width: '40%',
    textAlign: 'left'
  },
  centerContainer: {
    width: '60%',
    alignItems: 'center',
    marginTop: '15px',
    marginRight: '250px'
  },
  mainTextRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  numberText: {
    fontWeight: 'bold',
    marginRight: 15
  },
  duplicateTextRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 3
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const TurbineEndorsement = () => (
  <Page size="LETTER" style={styles.page}>
    <EndorsementHeader title="Turbine Aircraft Endorsement" />
    <View style={styles.content}>
      <View>
        <Text>Paragraph 4 of Part Two, AIRCRAFT PHYSICAL DAMAGE, is changed to add the following provision:</Text>
      </View>
      <View>
        <Text style={styles.boldText}>Wear and Tear to Engine(s)</Text>
      </View>
      <View>
        <Text>
          In addition to those matters not covered by this policy under Part Two, AIRCRAFT PHYSICAL DAMAGE COVERAGE,{' '}
          <Text style={styles.boldText}>we</Text> will not pay for any of the following types of loss or damage to{' '}
          <Text style={styles.boldText}>your aircraft`s</Text> engine(s) or auxiliary power units:
        </Text>
      </View>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.bullet}>*</Text>
          <Text style={styles.listText}>
            Damage caused by heat that results from the operation, attempted operation or shutdown of the engine(s);
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.bullet}>*</Text>
          <Text style={styles.listText}>Damage caused by the breakdown, failure or malfunction of any engine part or accessory; or</Text>
        </View>
      </View>
      <View>
        <Text>
          Damage caused by an object not part of the engine or its accessories will not be considered wear and tear to engine(s) and
          coverage will apply if such damage results from an <Text style={styles.boldText}>accident</Text> during ground or flight
          operations and the engine requires immediate repair in accordance with the manufacturer’s requirements. The amount{' '}
          <Text style={styles.boldText}>we</Text> will pay for such damage is, however, subject to the Engine Deductible shown below:
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.textLeft}>Engine Deductible:</Text>
        <View style={styles.centerContainer}>
          <View style={styles.mainTextRow}>
            <Text style={styles.numberText}>$20,000</Text>
            <Text>Each Engine</Text>
          </View>
          <View style={styles.duplicateTextRow}>
            <Text style={styles.numberText}>$20,000</Text>
            <Text>Each Engine</Text>
          </View>
        </View>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} />
  </Page>
);

export default TurbineEndorsement;
