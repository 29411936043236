import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15,
    marginTop: '-15px'
  },
  itemParagraph: {
    marginTop: '0px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const NonOwnershipEndorsement = ({aircraft={}, formNumber}) => (
  <Page size="LETTER" style={styles.page}>
    <View style={[styles.header, { fontSize: 8 }]}>
      <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
    </View>
    <View style={styles.header}>
      <Text style={styles.boldText}>Non-Ownership Liability Coverage</Text>
    </View>
    <View style={styles.centeredText}>
      <Text style={styles.boldText}>NON-COMMERCIAL USE INCLUDING AIRCRAFT PHYSICAL DAMAGE COVERAGE</Text>
    </View>
    <View style={styles.content}>
      <View>
        <Text>
          This Endorsement only applies to the following <Text style={styles.boldText}>aircraft: {aircraft?.registrationNo}</Text>
        </Text>
      </View>
      <View style={styles.centeredText}>
        <Text style={{ fontStyle: 'italic' }}>
          If no entry is made this endorsement applies to all <Text style={styles.boldText}>aircraft</Text> covered by{' '}
          <Text style={styles.boldText}>your</Text> policy.
        </Text>
      </View>
      <View>
        <Text>
          Various provisions of this endorsement extend or restrict coverage. Read <Text style={styles.boldText}>your</Text> entire policy
          to determine <Text style={styles.boldText}>your</Text> rights and obligations and what is and is not covered. This endorsement
          changes the provisions of <Text style={styles.boldText}>your</Text> policy under the following coverage parts:
        </Text>
      </View>
      <View style={styles.centeredText}>
        <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS</Text>
      </View>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>1. &nbsp; &nbsp; &nbsp;</Text>
          <View style={styles.listItemParagraph}>
            <Text>
              This endorsement extends <Text style={styles.boldText}>your</Text> coverage under{' '}
              <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS,</Text> to provide coverage when{' '}
              <Text style={styles.boldText}>you,</Text> or any full-time employee of <Text style={styles.boldText}>yours</Text> acting in
              the scope of his or her employment, hire or operate a non-owned <Text style={styles.boldText}>aircraft</Text> described in
              Paragraph 6 below for pleasure and business use. A “non-owned aircraft” means an <Text style={styles.boldText}>aircraft</Text>{' '}
              not owned in whole or in part by <Text style={styles.boldText}>you</Text> or <Text style={styles.boldText}>your</Text>
              employee, or leased or rented by <Text style={styles.boldText}>you</Text> or <Text style={styles.boldText}>your</Text>{' '}
              employee for any period in excess of seven (7) consecutive days. If <Text style={styles.boldText}>you</Text> are a corporation
              or limited liability organization, this endorsement also extends coverage to any officer, director or shareholder (or
              equivalent) of <Text style={styles.boldText}>yours</Text> acting within the scope of their responsibilities as such with
              respect to an <Text style={styles.boldText}>occurrence</Text> covered by this endorsement.
            </Text>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>2. &nbsp; &nbsp; &nbsp;</Text>
          <View style={styles.listItemParagraph}>
            <Text>
              This coverage is for the benefit of the persons or organizations described in Paragraph 1 above only.{' '}
              <Text style={styles.boldText}>Part Three,. LIABILITY TO OTHERS,</Text> Paragraph 2, “Who Is Protected,” does not apply to the
              coverage provided by this endorsement. There is no coverage under any Part of this policy for the owner of any non-owned
              aircraft covered by this endorsement.
            </Text>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>3. &nbsp; &nbsp; &nbsp;</Text>
          <View style={styles.listItemParagraph}>
            <Text>
              If there is other coverage to protect <Text style={styles.boldText}>you</Text> or any employee of{' '}
              <Text style={styles.boldText}>yours</Text> for an <Text style={styles.boldText}>occurence</Text> while using or operating a
              non-owned
              <Text style={styles.boldText}>aircraft</Text> described in this endorsement, the coverage provided by this endorsement shall
              be excess coverage and shall be effective only after all other coverage available for the{' '}
              <Text style={styles.boldText}>occurence</Text> has been paid.
            </Text>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>4. &nbsp; &nbsp; &nbsp;</Text>
          <View style={styles.listItemParagraph}>
            <Text>
              When <Text style={styles.boldText}>you</Text> or <Text style={styles.boldText}>your</Text> employees use or operate a
              non-owned aircraft described in this endorsement, <Text style={styles.boldText}>we</Text> will provide the same coverage and
              limit of coverage for <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS,</Text> as shown in Item 6 of your
              Coverage Identification Page. Each organization or person described in this endorsement is protected separately, but the limit
              of coverage does not increase regardless of the number of persons or organizations protected.
            </Text>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>5. &nbsp; &nbsp; &nbsp;</Text>
          <View style={styles.listItemParagraph}>
            <Text>
              This endorsement also extends <Text style={styles.boldText}>your</Text> coverage under{' '}
              <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS,</Text> to provide coverage for damage to a non-owned aircraft
              described below. Provided, the most <Text style={styles.boldText}>we</Text> will pay for{' '}
              <Text style={styles.boldText}>property damage</Text> to a non-owned aircraft covered by this endorsement is the amount shown
              in Paragraph 6 for “Each Aircraft” less the deductible amount shown for “Each Occurrence.”{' '}
              <Text style={styles.boldText}>We</Text>
              may pay the deductible amount to effect settlement of any claim or suit and upon notification{' '}
              <Text style={styles.boldText}>you</Text> agree to reimburse <Text style={styles.boldText}>us</Text>
              promptly for any deductible amount that has been paid by <Text style={styles.boldText}>us.</Text>
            </Text>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>6. &nbsp; &nbsp; &nbsp;</Text>
          <View style={styles.listItemParagraph}>
            <Text>The non-owned aircraft covered by this endorsement are described below:</Text>
          </View>
          <View style={[styles.listItemParagraph, styles.itemParagraph]}>
            <Text>
              Any standard licensed fixed wing land aircraft not owned in whole or in part by <Text style={styles.boldText}>you</Text> or{' '}
              <Text style={styles.boldText}>someone we protect</Text> provided no more than 6 total persons are on board the{' '}
              <Text style={styles.boldText}>aircraft</Text> while used for Pleasure and Business use only.
            </Text>
          </View>
          <View style={[styles.listItemParagraph, styles.itemParagraph]}>
            <Text>
              Limit of Coverage Each Aircraft: <Text style={styles.boldText}>$50,000</Text>
            </Text>
          </View>
          <View style={[styles.listItemParagraph, styles.itemParagraph]}>
            <Text>
              Deductible Amount: <Text style={styles.boldText}>$2,500</Text> Each Accident
            </Text>
          </View>
        </View>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber}/>
  </Page>
);

export default NonOwnershipEndorsement;
