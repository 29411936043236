import { View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },
  itemParagraph: {
    marginTop: '0px'
  },
  nestedListContainer: {
    paddingLeft: 15,
    gap: 10 // Indent nested items
  },
  nestedListItemParagraph: {
    marginLeft: 15,
    marginTop: '-11px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const BeginningComponent = ({ state, intro = true }) => (
  <>
    {intro && (
      <>
        <View style={[styles.header, { fontSize: 8 }]}>
          <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
        </View>
        <View style={styles.header}>
          <Text style={styles.boldText}>{state} Change Endorsement</Text>
        </View>
      </>
    )}
    <View style={styles.content}>
      <View>
        <Text>
          This endorsement adds the following to <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS,</Text> Paragraph
          8, Canceling the Policy, to read as follows.
        </Text>
      </View>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>8. &nbsp; &nbsp; &nbsp;</Text>
          <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
            <Text>Canceling the Policy</Text>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>
              <Text style={styles.boldText}>You</Text> may cancel <Text style={styles.boldText}>your</Text> policy at any time by telling{' '}
              <Text style={styles.boldText}>us</Text> in writing in advance, through{' '}
              <Text style={styles.boldText}>our Aviation Managers,</Text> of the date <Text style={styles.boldText}>you</Text>
              want <Text style={styles.boldText}>your</Text> coverage to end. If <Text style={styles.boldText}>you</Text> cancel the policy,{' '}
              <Text style={styles.boldText}>we</Text> will return 90% of the unearned premium <Text style={styles.boldText}>you</Text> have
              paid.
            </Text>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>
              <Text style={styles.boldText}>We</Text> or <Text style={styles.boldText}>our Aviation Managers</Text> can cancel this policy
              as follows:
            </Text>
          </View>
        </View>
      </View>
    </View>
  </>
);

export default BeginningComponent;
