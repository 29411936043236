import ArizonaEndorsement from './Arizona';
import ArkansasEndorsement from './Arkansas';
import ColoradoEndorsement from './Colorado';
import FloridaEndorsement from './Florida';
import GeorgiaEndorsement from './Georgia';
import IdahoEndorsement from './Idaho';
import IllinoisEndorsement from './Illinois';
import IndianaEndorsement from './Indiana';
import IowaEndorsement from './iowa';
import KansasEndorsement from './Kansas';
import KentuckyEndorsement from './Kentucky';
import LouisianaEndorsement from './Louisiana';
import MarylandEndorsement from './Maryland';
import MichiganEndorsement from './Michigan';
import MinnesotaEndorsement from './Minnesota';
import MissouriEndorsement from './Missouri';
import NewHampshireEndorsement from './NewHampshire';
import NewMexicoEndorsement from './NewMexico';
import NorthCarolinaEndorsement from './NorthCarolina';
import OhioEndorsement from './Ohio';
import OklahomaEndorsement from './Oklahoma';
import OreganEndorsement from './Oregan';
import PennsylvaniaChageEndorsement from './Pennsylvania';
import SouthCarolinaEndorsement from './SouthCarolina';
import SouthDakotaEndorsement from './SouthDakota';
import TennesseeEndorsement from './Tennessee';
import TexasEndorsement from './Texas';
import UtahEndorsement from './Utah';
import VirginiaEndorsement from './Virginia';
import WashingtonEndorsement from './Washington';
import WestVirginiaEndorsement from './West Virginia';
import WisconsinEndorsement from './Wisconsin';
import WyomingEndorsement from './Wyoming';

const statesEndorsements = [
  { state: 'AZ', element: (props) => <ArizonaEndorsement {...props} /> },
  { state: 'AR', element: (props) => <ArkansasEndorsement {...props} /> },
  { state: 'CO', element: (props) => <ColoradoEndorsement {...props} /> },
  { state: 'FL', element: (props) => <FloridaEndorsement {...props} /> },
  { state: 'GA', element: (props) => <GeorgiaEndorsement {...props} /> },
  { state: 'ID', element: (props) => <IdahoEndorsement {...props} /> },
  { state: 'IL', element: (props) => <IllinoisEndorsement {...props} /> },
  { state: 'IN', element: (props) => <IndianaEndorsement {...props} /> },
  { state: 'IA', element: (props) => <IowaEndorsement {...props} /> },
  { state: 'KS', element: (props) => <KansasEndorsement {...props} /> },
  { state: 'KY', element: (props) => <KentuckyEndorsement {...props} /> },
  { state: 'MD', element: (props) => <MarylandEndorsement {...props} /> },
  { state: 'MI', element: (props) => <MichiganEndorsement {...props} /> },
  { state: 'MN', element: (props) => <MinnesotaEndorsement {...props} /> },
  { state: 'NM', element: (props) => <NewMexicoEndorsement {...props} /> },
  { state: 'NC', element: (props) => <NorthCarolinaEndorsement {...props} /> },
  { state: 'PA', element: (props) => <PennsylvaniaChageEndorsement {...props} /> },
  { state: 'SC', element: (props) => <SouthCarolinaEndorsement {...props} /> },
  { state: 'SD', element: (props) => <SouthDakotaEndorsement {...props} /> },
  { state: 'WA', element: (props) => <WashingtonEndorsement {...props} /> },
  { state: 'TX', element: (props) => <TexasEndorsement {...props} /> },
  { state: 'UT', element: (props) => <UtahEndorsement {...props} /> },
  { state: 'WI', element: (props) => <WisconsinEndorsement {...props} /> },
  { state: 'VA', element: (props) => <VirginiaEndorsement {...props} /> },
  { state: 'TN', element: (props) => <TennesseeEndorsement {...props} /> },
  { state: 'OH', element: (props) => <OhioEndorsement {...props} /> },
  { state: 'OR', element: (props) => <OreganEndorsement {...props} /> },
  { state: 'WV', element: (props) => <WestVirginiaEndorsement {...props} /> },
  { state: 'WY', element: (props) => <WyomingEndorsement {...props} /> },
  { state: 'LA', element: (props) => <LouisianaEndorsement {...props} /> },
  { state: 'MO', element: (props) => <MissouriEndorsement {...props} /> },
  { state: 'OK', element: (props) => <OklahomaEndorsement {...props} /> },
  { state: 'NH', element: (props) => <NewHampshireEndorsement {...props} /> }
];

const StateEndorsement = ({ state, formNumber, formNumber2='' }) => {
  const endorsement = statesEndorsements.find((item) => item.state === state);
  if (!formNumber) {
    console.error('formNumber is missing or invalid');
    return null;  // Don't render anything if formNumber is missing
  }
  return endorsement ? endorsement.element({formNumber, formNumber2}) : null;
};

export default StateEndorsement;
