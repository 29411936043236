import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  container: {
    fontFamily: 'OpenSans',
    display: 'flex' /* Enables flexbox */,
    justifyContent: 'center' /* Centers the box horizontally */,
    alignItems: 'center',
    gap: 10
  },
  boxContent: {
    width: '400px' /* Width of the box */,
    height: '200px' /* Height of the box */,
    border: '4px solid black' /* Border around the box */,
    padding: '20px',
    justifyContent: 'center',
    gap: 10 /* Padding inside the box */
  },
  footer: {
    fontSize: 9,
    fontFamily: 'OpenSans',
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' }
  ]
});
export const CoverPDF = ({formNumber}) => (
  <Page size="letter" style={styles.page}>
    <View style={styles.header}>
      <Text style={[styles.boldText, { fontSize: 12 }]}>Aircraft Insurance Policy</Text>
    </View>
    <View style={styles.header}>
      <Text style={[styles.boldText, { fontSize: 24, color: 'blue' }]}>Imperium Insurance Company</Text>
    </View>
    <View style={styles.header}>
      <Text style={[styles.boldText, { fontSize: 10, color: 'blue', opacity: 0.5 }]}>A Subsidiary of Skyward Specialty Insurance</Text>
    </View>
    <View style={styles.header}>
      <Text style={[styles.boldText, { fontSize: 10 }]}>800 Gessner Rd. Suite 600, Houston, TX 77024</Text>
    </View>
    <View style={styles.content}>
      <View>
        <Text>
          This policy is written in easy to read language. The declarations, definitions, insuring agreements, conditions, and exclusions of
          your policy are set forth in detail in the Coverage Identification Page, Parts One through Five and any Endorsements we issue.
          Together, these comprise your policy.
        </Text>
      </View>
      <View>
        <Text>
          This policy may provide you with coverage for Aircraft Physical Damage, Liability to Others and Medical Expense. Be sure to review
          your Coverage Identification Page to confirm the coverage and limits issued to you. Then read each Part of the Policy and each
          Endorsement we issued. This will enable you to better understand your policy.
        </Text>
      </View>
      <View>
        <Text>This Policy is a legal contract between you and the Company; therefore,</Text>
      </View>
      <View style={styles.container}>
        <View>
          <Text style={styles.boldText}>IT IS IMPORTANT THAT YOU READ YOUR POLICY CAREFULLY.</Text>
        </View>
        <View>
          <Text>The next page provides you with a brief index to the important features of your policy</Text>
        </View>
        <View style={styles.boxContent}>
          <View style={{ textAlign: 'center', fontSize: 10 }}>
            <Text style={styles.boldText}>Warning</Text>
          </View>
          <View>
            <Text>
              If you have an accident or occurrence in Mexico, you may be jailed and your aircraft impounded unless you have aircraft
              liability coverage issued by an insurance company licensed in Mexico or otherwise comply with the laws of Mexico.
            </Text>
          </View>
          <View>
            <Text style={styles.boldText}>
              While your policy territory includes Mexico, the Company is not licensed in that country; therefore, you must make certain you
              have the coverage required by the appropriate authorities before you fly into Mexico!
            </Text>
          </View>
        </View>
      </View>
    </View>
    <View style={styles.footer}>
      <Text style={styles.boldText}>{formNumber}</Text>
    </View>
  </Page>
);
