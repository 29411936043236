import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import BeginningComponent from '../Components/BeginingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const WisconsinEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Wisconsin Change Endorsement</Text>
      </View>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS</Text>, Paragraph 3,
            Canceling the Policy, to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>your</Text> policy provides <Text style={styles.boldText}>bodily injury</Text> or{' '}
                <Text style={styles.boldText}>property damage</Text> coverage under{' '}
                <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS</Text>, <Text style={styles.boldText}>we</Text> agree that
                such coverage will not be invalidated by any use of the <Text style={styles.boldText}>aircraft</Text> in violation of{' '}
                <Text style={styles.boldText}>F.A.A.</Text> regulations or other laws. <Text style={styles.boldText}>We</Text> also agree
                that such liability coverage will not be invalidated by operation of the{' '}
                <Text style={styles.boldText}>aircraft in flight:</Text>
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    if its airworthiness certificate is not in full force and effect or if it has been converted to a restricted or
                    experimental category without notice to <Text style={styles.boldText}>us</Text>; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    by a pilot who fails to meet the minimum requirements of your policy relating to pilot or medical certification, unless
                    such violation of airworthiness, pilot or medical certification requirements contributes to the{' '}
                    <Text style={styles.boldText}>occurence</Text>.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This provision also supersedes any provision to the contrary in Forms GA102, GA104, GAl0S, GA108 or GAll0 with respect to Item 9
            of <Text style={styles.boldText}>your</Text> Coverage Identification Page.
          </Text>
        </View>
        <BeginningComponent intro={false} />
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any time for nonpayment of premium by mailing or delivering a notice of cancellation to
                    the first Named Insured at least 10 days before the effective date of the cancellation;
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect less than 60 days and it is not a renewal it may be canceled at any time by mailing or
                    delivering a notice of cancellation to the first Named Insured at least 30 days before the effective date of
                    cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect for 60 days or more, it may be canceled at any time by mailing or delivering a notice
                    of cancellation to the first Named Insured at least 30 days before the effective date of cancellation, but only for one
                    or more of the following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>The Policy was obtained by material misrepresentation;</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        There has been a substantial change in the risk <Text style={styles.boldText}>we</Text> originally assumed, except
                        to the extent that <Text style={styles.boldText}>we</Text> should have foreseen the change or considered the risk in
                        writing the policy; or
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>There have been substantial breaches of contractual duties, conditions or warranties.</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If this policy has been in effect for 60 days or more or is a renewal of a policy <Text style={styles.boldText}>we</Text>{' '}
                issued, the notice of cancellation will state the reason for cancellation.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Proof of mailing or delivery of the notice is sufficient proof of notice. <Text style={styles.boldText}>We</Text> will
                compute the premium earned by <Text style={styles.boldText}>us</Text> based on the percentage of the original policy period
                that <Text style={styles.boldText}>we</Text> provided coverage.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will return to <Text style={styles.boldText}>you</Text> any premium that{' '}
                <Text style={styles.boldText}>you</Text> have paid that <Text style={styles.boldText}>we</Text> have not earned, but making
                the refund is not a condition of cancellation.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will mail or deliver any notice to the first Named Insured at the last known mailing
                address as shown in Item 2 of the Coverage Identification Page of this policy. If notice is mailed, proof of mailing is
                sufficient proof of notice.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={3} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.listItemParagraph}>
              <Text style={styles.boldText}>Anniversary Cancellation</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If this policy is written for a term of more than one year or has no fixed expiration date,{' '}
                <Text style={styles.boldText}>we</Text> may cancel this policy for any reason by mailing or delivering to the first Named
                Insured written notice of cancelation at least 60 days before the anniversary date of this policy. Such cancellation will be
                effective on the policy`s anniversary date.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> may cancel this policy because of the termination of an insurance marketing
                intermediary`s contract with <Text style={styles.boldText}>us</Text> only if the notice of cancellation contains an offer to
                continue the policy with <Text style={styles.boldText}>us</Text> if <Text style={styles.boldText}>we</Text> receive a
                written request from the first Named Insured prior to the date of cancellation.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text style={styles.boldText}>Nonrenewal</Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If <Text style={styles.boldText}>we</Text> elect not to renew this policy <Text style={styles.boldText}>we</Text> will
                    mail or deliver written notice of nonrenewal to the first Named Insured`s last mailing address known to{' '}
                    <Text style={styles.boldText}>us. We</Text> may elect not to renew for any reason; the notice will state the reason for
                    nonrenewal. <Text style={styles.boldText}>We</Text> will mail or deliver the notice at least 60 days before the
                    expiration date of this policy.
                  </Text>
                </View>
                <View style={{ margin: '5px 0 5px 15px' }}>
                  <Text>
                    <Text style={styles.boldText}>We</Text> need not mail or deliver the notice if:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        <Text style={styles.boldText}>You</Text> have insured elsewhere;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        <Text style={styles.boldText}>You</Text> have accepted replacement coverage;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        <Text style={styles.boldText}>You</Text> have requested or agreed to nonrenewal of this policy; or
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>This policy is expressly designated as nonrenewable.</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>We</Text> may refuse to renew this policy because of the termination of an insurance
                    marketing intermediary`s contract with <Text style={styles.boldText}>us</Text>
                    only if the notice of nonrenewal contains an offer to renew the policy with <Text style={styles.boldText}>
                      us
                    </Text> if <Text style={styles.boldText}>we</Text> recieve a written request from the first Named Insured prior to the
                    renewal date.
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If <Text style={styles.boldText}>you</Text> fail to pay the renewal or continuation premium by the premium due date,
                    this policy will terminate on the policy expiration or anniversary date, if <Text style={styles.boldText}>we</Text>{' '}
                    have:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Given <Text style={styles.boldText}>you</Text> written notice of the renewal or continuation premium not more than
                        75 days nor less than 10 days prior to the due date of the premium; and
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Stated clearly in the notice the effect of nonpayment of premium by the due date.</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text style={styles.boldText}>Anniversary alteration</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If this policy is written for a term of more than one year or has no fixed expiration date,{' '}
                <Text style={styles.boldText}>we</Text> may alter the terms or premiums of this policy by mailing or delivering written
                notice of less favorable terms or premiums to the first Named Insured`s last mailing address known to{' '}
                <Text style={styles.boldText}>us. We</Text> will mail, by first class mail, or deliver this notice at least 60 days prior to
                the anniversary date.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> notify the first Named Insured within 60 days prior to the anniversary date, the
                new terms or premiums will not take effect until 60 days after the notice was mailed or delivered. The notice will include a
                statement of the first Named Insured`s right to cancel. The first Named Insured may elect to cancel the policy at any time
                during the 60-day-period. If the first Named Insured elects to cancel the policy during the 60-day-period, return premiums
                or additional premium charges will be calculated proportionally on the basis of the old premiums.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text style={styles.boldText}>Renewal With Altered Terms</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> elect to renew this policy but on less favorable terms or at higher premiums,{' '}
                <Text style={styles.boldText}>we</Text> will mail or deliver written notice of the new terms or premiums to the first Named
                Insured`s last mailing address known to <Text style={styles.boldText}>us. We</Text> will mail, by first class mail, or
                deliver this notice at least 60 days prior to the renewal date.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> notify the first Named Insured within 60 days prior to the renewal date, the new
                terms or premiums will not take effect until 60 days after the notice was mailed or delivered. The notice will include a
                statement of the first Named Insured`s right to cancel. The first Named Insured may elect to cancel the renewal policy at
                any time during the 60-day-period. If the first Named Insured effects to cancel the renewal policy during the 60-day-period,
                return premiums or additional premium charges will be calculated proportionally on the basis of the old premiums.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> need not mail or deliver this notice if the only change adverse to{' '}
                <Text style={styles.boldText}>you</Text> is a premium increase that:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Is less than 25% and is generally applicable to the class of business to which this policy belongs; or</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    Results from a change based on <Text style={styles.boldText}>your</Text> action that alters the nature or extent of the
                    risk insured against, including but not limited to a change in the classification or the units of exposure, or increased
                    poliocy coverage.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={3} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement also changes <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS,</Text> Paragraph 9,
            Other Coverage to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>9. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, {marginTop: '-16px'}]}>
              <Text>Other Coverage</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If there is other coverage protecting <Text style={styles.boldText}>you</Text> or{' '}
                <Text style={styles.boldText}>someone we protect</Text> for an <Text style={styles.boldText}>accident</Text> or{' '}
                <Text style={styles.boldText}>occurence</Text> covered by this policy, <Text style={styles.boldText}>we</Text>
                will pay only the percentage portion that the applicable limit of coverage of this policy bears to the total of the
                applicable limits of coverage for all policies.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If there is an <Text style={styles.boldText}>accident</Text> or <Text style={styles.boldText}>occurence</Text> covered by{' '}
                <Text style={styles.boldText}>your</Text> policy involving the Temporary Use of Substitute Aircraft or Use of Another
                Aircraft, your policy will be excess over any other polict protecting <Text style={styles.boldText}>you.</Text>
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement also changes <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS,</Text> Paragraph 11,{' '}
            <Text style={styles.boldText}>Our</Text> Right of Recovery (Except{' '}
            <Text style={styles.boldText}>Part Four. MEDICAL EXPENSE</Text>), to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>11. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, {marginTop: '-16px'}]}>
              <Text>
                <Text style={styles.boldText}>Our</Text> right of Recovery (Except{' '}
                <Text style={styles.boldText}>Part Four. MEDICAL EXPENSE</Text>)
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> make any payment, <Text style={styles.boldText}>we</Text> will take over{' '}
                <Text style={styles.boldText}>your</Text> right to recover the payment from anyone who is responsible.{' '}
                <Text style={styles.boldText}>You</Text> and <Text style={styles.boldText}>someone we protect</Text> must do everythingg
                necessary to trasfer this right of recofery to us, including allowing suit to be brought in
                <Text style={styles.boldText}>your</Text> name or in the name of{' '}
                <Text style={styles.boldText}>someone we protect. You</Text> and <Text style={styles.boldText}>someone we protect</Text>{' '}
                must do nothing that will interfere with, limit or waive <Text style={styles.boldText}>our</Text> right to recover.
                Provided, however, <Text style={styles.boldText}>we</Text> also agree that <Text style={styles.boldText}>we</Text> will
                reimburse <Text style={styles.boldText}>you</Text> or <Text style={styles.boldText}>someone we protect</Text> for any
                non-reimbursed loss or damage sustained out of any amounts recovered before retaining any amounts for{' '}
                <Text style={styles.boldText}>our</Text> benefit.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement also changes <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS,</Text> Paragraph 13,
            Legal Action Against Us, to delete the provision from the policy.
          </Text>
        </View>
        <View>
          <Text>
            This endorsement also changes <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE,</Text> Paragraph 9,
            Reinstatement of Coverage to read as follow:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>9. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, {marginTop: '-16px'}]}>
              <Text>Reinstatement of Coverage</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>your aircraft</Text> is damaged, the amount of coverage for the{' '}
                <Text style={styles.boldText}>aircraft</Text> will be reduced by the amount of the damage. The coverage will be
                automatically increased, at no additional premium, by the cost of the repairs completed until the agreed value has been
                restored or the policy expires.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement also changes <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS</Text> to include the following
            provision:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <Text style={styles.listItemHeading}>
            g. &nbsp; &nbsp; &nbsp; <Text style={{ fontStyle: 'italic' }}>Bankruptcy Or Insolvency</Text>
          </Text>
          <View style={styles.listItemParagraph}>
            <Text>
              <Text style={styles.boldText}>We</Text> agree that <Text style={styles.boldText}>your</Text> bankruptcy or insolvency shall
              not reduce or diminish <Text style={styles.boldText}>our</Text> obligations in any manner under this policy.
            </Text>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={3} endPage={3} form={formNumber}/>
    </Page>
  </>
);

export default WisconsinEndorsement;
