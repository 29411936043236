import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },
  itemParagraph: {
    marginTop: '0px'
  },
  nestedListContainer: {
    paddingLeft: 15,
    gap: 10 // Indent nested items
  },
  nestedListItemParagraph: {
    marginLeft: 15,
    marginTop: '-11px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const ColoradoEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Colorado Change Endorsement</Text>
      </View>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement adds the following to <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS,</Text>{' '}
            Paragraph 8, Canceling the Policy, to read as follows.
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>8. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Canceling the Policy</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>You</Text> may cancel <Text style={styles.boldText}>your</Text> policy at ay time by telling{' '}
                <Text style={styles.boldText}>us</Text> in writing in advance, through{' '}
                <Text style={styles.boldText}>our Aviation Managers,</Text> of the date <Text style={styles.boldText}>you</Text>
                want <Text style={styles.boldText}>your</Text> coverage to end. If <Text style={styles.boldText}>you</Text> cancel the
                policy, <Text style={styles.boldText}>we</Text> will return 90% of the unearned premium{' '}
                <Text style={styles.boldText}>you</Text> have paid.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> or <Text style={styles.boldText}>our Aviation Managers</Text> can cancel this policy
                as follows:
              </Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any time for nonpayment of premium by mailing or delivering a notice of cancellation to
                    you at least 10 days before the effective date of the cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect 60 days or less then this policy can be canceled at any time by mailing or delivering
                    a notice of cancellation to you at least 45 days before the effective date of cancellation.
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If the policy has been in effect more than 60 days, or is a renewal, it may be canceled only for one of the following
                    reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1). &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Nonpayment of premium;</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2). &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        <Text style={styles.boldText}>You, your</Text> representative or{' '}
                        <Text style={styles.boldText}>someone we protect</Text> knowingly makes a false statement on the application; or
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3). &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Substantial change in the risk assumed, except to the extent that <Text style={styles.boldText}>we</Text> should
                        have reasonably foreseen the change or contemplated in the rate.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> cancel this policy based on one or more of the above reasons,{' '}
                <Text style={styles.boldText}>we</Text> will mail by first class mail to <Text style={styles.boldText}>you,</Text> and mail
                to the agent, if any, written notice of cancellation stating the reasons for cancellation.{' '}
                <Text style={styles.boldText}>We</Text> will mail this notice to the last mailing addresses known to{' '}
                <Text style={styles.boldText}>us,</Text> at least:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    10 days before the effective date of cancellation if <Text style={styles.boldText}>we</Text> cancel for nonpayment of
                    premium; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    45 days before the effective date of cancellation if <Text style={styles.boldText}>we</Text> cancel for any of the other
                    reasons.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> cancel <Text style={styles.boldText}>your</Text> policy,{' '}
                <Text style={styles.boldText}>we</Text> will return any premium <Text style={styles.boldText}>you</Text> have paid that{' '}
                <Text style={styles.boldText}>we</Text> have not earned, but making a refund is not a condition of cancellation.{' '}
                <Text style={styles.boldText}>We</Text> will compute the premium earned by <Text style={styles.boldText}>us</Text> based on
                the percentage of the original policy period that <Text style={styles.boldText}>we</Text> provided coverage.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Proof of mailing or delivery of the notice is sufficient proof of notice. <Text style={styles.boldText}>We</Text> will
                compute the premium earned by <Text style={styles.boldText}>us</Text> based on the percentage of the original policy period
                that <Text style={styles.boldText}>we</Text> provided coverage.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will return to <Text style={styles.boldText}>you</Text> any premium that{' '}
                <Text style={styles.boldText}>you</Text> have paid that <Text style={styles.boldText}>we</Text> have not earned, but making
                the refund is not a condition of cancellation.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                However, if <Text style={styles.boldText}>we</Text> pay or have paid the <Text style={styles.boldText}>agreed value</Text>{' '}
                of the <Text style={styles.boldText}>aircraft,</Text> less any applicable deductible under the Aircraft Physical Damage
                coverage, <Text style={styles.boldText}>we</Text> are entitled to the total Aircraft Physical Damage premium shown on the
                Coverage Identification Page for the <Text style={styles.boldText}>aircraft</Text> on which{' '}
                <Text style={styles.boldText}>we</Text> made or make payment. <Text style={styles.boldText}>You</Text> agree to pay any
                premium that may be due or permit <Text style={styles.boldText}>us</Text> to deduct such premium from{' '}
                <Text style={styles.boldText}>our</Text> loss payment.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> elect not to renew this policy <Text style={styles.boldText}>we</Text> will mail
                by first class mail to <Text style={styles.boldText}>you,</Text> and mail to the agent, if any, written notice of
                nonrenewal. <Text style={styles.boldText}>We</Text> will mail this notice to the last mailing addresses known to{' '}
                <Text style={styles.boldText}>us</Text> at least 45 days prior to the expiration of this policy. If notice is mailed, proof
                of mailing will be sufficient proof of notice. If either one of the following occurs,{' '}
                <Text style={styles.boldText}>we</Text> are not not required to provide written notice of nonrenewal:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>We</Text> or a company within the same insurance group has offered to issue a renewal
                    policy; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>You</Text> have obtained replacement coverage or agreed in writing to do so.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> elect to renew this policy and the renewal is subject to any of the following:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Premium increase;</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Deductiable change;</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Reduced limits; or</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>d. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Substantial reduction in coverage,</Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will mail or deliver written notice of the change(s) to{' '}
                <Text style={styles.boldText}>you,</Text> at the last mailing address known to <Text style={styles.boldText}>us,</Text> at
                least 45 days before the anniversary or expiration date of the policy.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> fail to provide notice 45 days notice before the anniversary or expiration date
                of this policy, the ppolicy will remain in force for 45 days from the date of the notice.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} form={formNumber}/>
    </Page>
  </>
);

export default ColoradoEndorsement;
