import {
  Card,
  CardHeader,
  Typography,
  Divider,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Tooltip,
  IconButton
} from '@mui/material';
import { formatDate } from 'utils/FormatUtils';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useNavigate } from 'react-router';
const DashboardTable = ({ data, title, companyId }) => {
  const navigate = useNavigate();
  const handleGoToDetail = (item) => {
    navigate(`/company/${companyId}/policy/${item.idImmutable}`);
  };
  return (
    <Card>
      <CardHeader
        title={
          <Typography component="div" className="card-header">
            {title}
          </Typography>
        }
      />
      <Divider />
      <CardContent className="p-0">
        <TableContainer component={Paper} style={{ maxHeight: 300, minHeight: 300 }}>
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Client Name</TableCell>
                {title !== 'My Quote Requests' && title !== 'My Active Qutotes' ? (
                  <>
                    <TableCell>Policy Number</TableCell>
                    <TableCell>Begin Date</TableCell>
                    <TableCell>End Date</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>Date Added</TableCell>
                  </>
                )}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row?.clientNameInsured || row?.CustomerOrganization.name}</TableCell>
                    {title !== 'My Quote Requests' && title !== 'My Active Qutotes' ? (
                      <>
                        <TableCell>{row?.policyNumber}</TableCell>
                        <TableCell>{row?.beginDate ? formatDate(row?.beginDate).format('MM/DD/YYYY') : null}</TableCell>
                        <TableCell>{row?.endDate ? formatDate(row?.endDate).format('MM/DD/YYYY') : null}</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>{formatDate(row?.dateAdded).format('MM/DD/YYYY')}</TableCell>
                      </>
                    )}
                    <TableCell>
                      <Tooltip title="Details">
                        <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(row)}>
                          <TextSnippetIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>{`${title} table is empty`}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default DashboardTable;
