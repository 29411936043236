import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Divider,
  Typography,
  Tooltip,
  IconButton
} from '@mui/material';
import { gridSpacing } from 'config.js';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import initializeAxios from 'utils/axios';
import DashboardTable from './DashboardTable';
import { formatDate } from 'utils/FormatUtils';
import LoadingOverlay from 'component/LoadingOverlay';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useNavigate } from 'react-router';

const Dashboard = () => {
  const auth = useAuth();
  const companyId = auth?.user?.idCompany || auth?.user?.company?.id;
  const axios = initializeAxios(auth);
  const navigate = useNavigate();

  // States
  const [quotes, setQuotes] = useState([]);
  const [quoteRequests, setQuoteRequests] = useState([])
  const [expiring, setExpiring] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [claims, setClaims] = useState([]);

  const [isLoading, setIsLoading] = useState(false);


  const handleGoToDetail = (row) => {
    navigate(`/company/${companyId}/policy/${row.Policy.idImmutable}/claim/${row.id}`);
  };

  useEffect(() => {
    const getPolicies = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/policy/companyId/${companyId}/user`);
      setQuotes([...response.data.policies.filter((item) => item?.['Options_Policy_idPolicyStatusToOptions']?.val === 'Quote')]);
      setPolicies([...response.data.policies.filter((item) => item?.['Options_Policy_idPolicyStatusToOptions']?.val === 'Policy')]);
      setClaims([
        ...response.data.policies
          .map((item) => item.Claim)
          .flat()
          .filter((item) => item.idClaimStatus === 29)
      ]);
      setIsLoading(false);
    };

    const getExpiringPolicies = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/policy/companyId/${companyId}`, {
        params: {
          filter: 'Expire'
        }
      });
      setExpiring([...response.data.policies]);
      setIsLoading(false);
    };

    const getQuoteRequests = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/policy/companyId/${companyId}`, {
        params: {
          filter: 'Quote Request'
        }
      });
      setQuoteRequests([...response.data.policies]);
      setIsLoading(false);
    };

    getPolicies();
    getExpiringPolicies();
    getQuoteRequests();
  }, []);

  return (
    <>
      <LoadingOverlay loading={isLoading} />
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing} sx={{ display: { sm: 'block', lg: 'flex' }, mt: { xs: 1, lg: 0 } }}>
          <Grid item lg={6} xs={12}>
              <DashboardTable data={quoteRequests} title="My Quote Requests" companyId={companyId} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <DashboardTable data={quotes} title="My Active Qutotes" companyId={companyId} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing} sx={{ display: { sm: 'block', lg: 'flex' } }}>
            <Grid item lg={6} xs={12}>
              <DashboardTable data={expiring} title="Expiring Policies" companyId={companyId} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <Card>
                <CardHeader
                  title={
                    <Typography component="div" className="card-header">
                      My Open Claims
                    </Typography>
                  }
                />
                <Divider />
                <CardContent className="p-0">
                  <TableContainer component={Paper} style={{ maxHeight: 300, minHeight: 300 }}>
                    <Table aria-label="simple table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Policy Number</TableCell>
                          <TableCell>Customer Contact</TableCell>
                          <TableCell>Claim Date</TableCell>
                          <TableCell>Claim Status</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {claims.length > 0 ? (
                          claims.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row?.Policy?.policyNumber}</TableCell>
                              <TableCell>{row?.CustomerContact?.name}</TableCell>
                              <TableCell>{row?.claimDate ? formatDate(row?.claimDate).format('MM/DD/YYY') : null}</TableCell>
                              <TableCell>{row?.Options.val}</TableCell>
                              <TableCell>
                                <Tooltip title="Details">
                                  <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(row)}>
                                    <TextSnippetIcon fontSize='large'/>
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell>{`Claims table is empty`}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DashboardTable data={policies} title="My Active Policies" companyId={companyId} />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
