import { View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';

const styles = StyleSheet.create({
    boldText: {
        fontFamily: 'OpenSans',
        fontWeight: 'bold'
      },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 10
  },
  leftItem: {
    textAlign: 'left',
    fontSize: 9,
    marginLeft: 50, // margin of 50px from the left
    flex: 1 // Takes up 1/3rd of the space
  },
  centerItem: {
    textAlign: 'center',
    marginRight: 50,
    flex: 1 // Takes up 1/3rd of the space
  },
  spacer: {
    flex: 1 // This spacer will take up 1/3rd of the space and help center the centerItem
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' }
  ]
});

export const PageFooter = ({form, number}) => (
  <View style={styles.footer}>
    <Text style={[styles.boldText, styles.leftItem]}>Form {form}</Text>
    <Text style={[styles.boldText, styles.centerItem, { fontSize: 8.5 }]}>page {number}</Text>
    <View style={styles.spacer} />
  </View>
);
