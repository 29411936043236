import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },
  itemParagraph: {
    marginTop: '0px'
  },
  nestedListContainer: {
    paddingLeft: 15,
    gap: 10 // Indent nested items
  },
  nestedListItemParagraph: {
    marginLeft: 15,
    marginTop: '-11px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const ArkansasEndorsement = ({ formNumber }) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Arkansas Change Endorsement</Text>
      </View>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement adds the following to <Text style={styles.boldText}>Part V. CONDITIONS,</Text> Paragraph 11,{' '}
            <Text style={styles.boldText}>Our</Text> right of recovery (Except part FOUR. Medical expense coverage).
          </Text>
        </View>
        <View>
          <Text>
            <Text style={styles.boldText}>We</Text> will be entitled to recovery only after you have been fully compensated for the loss or
            damage sustained.
          </Text>
        </View>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS,</Text> Paragraph 8,
            Cancelling the Policy, to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>8. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Cancellation or Nonrenewal of the Policy.</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text style={styles.boldText}>Cancellation</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>You</Text> may cancel <Text style={styles.boldText}>your</Text> policy at any time by telling{' '}
                <Text style={styles.boldText}>us</Text> in writing in advance, through{' '}
                <Text style={styles.boldText}>our Aviation Managers,</Text> of the date <Text style={styles.boldText}>you</Text>
                want <Text style={styles.boldText}>your</Text> coverage to end. IF <Text style={styles.boldText}>you</Text> cancel the
                policy, <Text style={styles.boldText}>we</Text> will return 90% of the unearned premium{' '}
                <Text style={styles.boldText}>you</Text> have paid.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> or <Text style={styles.boldText}>Our Aviation Managers</Text> can cancel this policy
                as follows:
              </Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    The policy can be canceled at any time for nonpayment of premium by mailing or delivering a notice of cancellation to
                    the first Named Insured at least 10 days before the effective date of the cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If the policy has been in effect less than 60 days, it may be canceled at any time by mailing or delivering a notice of
                    cancellation to the first Named Insured at least 30 days before the effective date of cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    The cancellation of this policy that has been in force over (60) days or after the effective date of a renewal policy or
                    an annual anniversary date unless the cancellation is based upon one of the following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1, marginTop: 5 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Non Payment of premium;</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Fraud or material misrepresentation made by <Text style={styles.boldText}>you</Text> or with{' '}
                        <Text style={styles.boldText}>your</Text> knowledge in obtaining the policy, continuing the policy or in presenting
                        a claim under the policy.
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Substanial change in any hazard insured against.</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Violation of any local fire, health, safety code which substantially increases any hazard insured against under the
                        policy.
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(5) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Nonpayment of membership dues in those cases where out bylaws, agreements or{' '}
                        <Text style={styles.boldText}>our</Text> instruments require payment as a condition of the issuance and maintenance
                        of the policy.
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(6) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>A material violation of a material provision of the policy</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Cancellation of the policy shall only be effective when notice of cancellation is mailed or delivered by us to the first
                Named Insured, and to any lienholder or loss payee named in the policy, to the last known address as shown in item 22 of
                <Text style={styles.boldText}>your</Text> Coverage Identification Page, at least (30) days prior to the effective date of
                cancellation. However, where cancellation is for nonpayment of premium, at least (10) days` notice of cancellation
                accompanied by the reason for cancellation shall be given
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Proof of mailing or delivery of the notice is sufficient proof of notice. <Text style={styles.boldText}>We</Text> will
                compute the premium earned by <Text style={styles.boldText}>us</Text> based on the percentage of the original policy period
                that <Text style={styles.boldText}>we</Text> provided coverage. <Text style={styles.boldText}>We</Text> will return to{' '}
                <Text style={styles.boldText}>you</Text> any premium that <Text style={styles.boldText}>you</Text> have paid that{' '}
                <Text style={styles.boldText}>we</Text> have not earned, but making the refund is not a condition of cancellation.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                However, if <Text style={styles.boldText}>we</Text> pay or have paid the <Text style={styles.boldText}>agreed value</Text>{' '}
                of the <Text style={styles.boldText}>aircraft,</Text> less any applicable deductible under the Aircraft Physical Damage
                coverage, <Text style={styles.boldText}>we</Text> are entitled to the total Aircraft Physical Damage premium shown on the
                Coverage Identification Page for the <Text style={styles.boldText}>aircraft</Text> on which{' '}
                <Text style={styles.boldText}>we</Text> made or make payment. <Text style={styles.boldText}>You</Text> agree to pay any
                premium that may be due or permit <Text style={styles.boldText}>us</Text> to deduct such premium from{' '}
                <Text style={styles.boldText}>our</Text> loss payment.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF form={formNumber} page={1} endPage={2} />
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.listItemParagraph}>
              <Text style={styles.boldText}>Nonrenewal</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> decide not to renew this policy, <Text style={styles.boldText}>we</Text> will
                mail to the first Named Insured shown in the Coverage Indetification Page written notice of nonrenewal at least 60 days
                before:
              </Text>
              <View style={[styles.nestedListContainer, { gap: 1 }]}>
                <View>
                  <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>its expiration date; or</Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>
                      its anniversary date, if it is a policy written for a term of more than one year and with no fixed expiration date.
                    </Text>
                  </View>
                </View>
                <View style={{ paddingTop: 15, paddingBottom: 5 }}>
                  <Text style={styles.listItemHeading} />
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>
                      However, <Text style={styles.boldText}>we</Text> are not required to send this notice if nonrenewal is due to{' '}
                      <Text style={styles.boldText}>your</Text> failure to pay any premium required for renewal
                    </Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>
                      <Text style={styles.boldText}>We</Text> will mail <Text style={styles.boldText}>our</Text> notice to the first Named
                      Insured and to any lienholder or loss payee named in the policy to the last mailing address known to{' '}
                      <Text style={styles.boldText}>us.</Text> If notice is mailed, proof of mailing will be sufficient proof of notice.
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text style={styles.boldText}>Renewal Provisions</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> elect to renew this policy and the renewal is subject to any of the following:
              </Text>
              <View style={[styles.nestedListContainer, { gap: 1 }]}>
                <View>
                  <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>Increase in premium of (25%)</Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>Issued for a term of less than (12) months;</Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>Change in deductible;</Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>d. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>Reduction in limits of insurance; or</Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>Substanial reduction in coverage;</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will mail or deliver written notice of the charge(s) to{' '}
                <Text style={styles.boldText}>you, your</Text> agent, and any lienholder or loss payee at the last mailing address known to{' '}
                <Text style={styles.boldText}>us,</Text> at least (30) days before the anniversary or expiration date of the policy. The
                notice will specifically state our intention to increase the premium by an amount equal to or greater than (25%) on any
                renewal of the existing policy.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If renewal is subject to any condition described in (a) above, and <Text style={styles.boldText}>we</Text> fail to provide
                notice 30 days before the anniversary or expiration date of this policy, The premium for the policy as extended in such
                circumstances shall be no more than the pro rata premium of the existing policy.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} form={formNumber} page={2} endPage={2} />
    </Page>
  </>
);

export default ArkansasEndorsement;
