import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import EndingComponent from '../Components/EndingComponent';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },
  itemParagraph: {
    marginTop: '0px'
  },
  nestedListContainer: {
    paddingLeft: 15,
    gap: 10 // Indent nested items
  },
  nestedListItemParagraph: {
    marginLeft: 15,
    marginTop: '-11px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const CancellationReasons = () => (
  <View style={[styles.nestedListContainer, { gap: 1 }]}>
    <View>
      <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
        <Text>Fraud or material misrepresentation.</Text>
      </View>
    </View>
    <View>
      <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
        <Text>There has been a substantial change in the scale of the risk covered by this policy.</Text>
      </View>
    </View>
    <View>
      <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
        <Text>Reinsurance of the risk associated with this policy has been canceled.</Text>
      </View>
    </View>
    <View>
      <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
        <Text>
          If the insured does not comply with <Text style={styles.boldText}>our</Text> safety recommendations.
        </Text>
      </View>
    </View>
  </View>
);

const IndianaEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Indiana Change Endorsement</Text>
      </View>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement adds the following to <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS,</Text>{' '}
            Paragraph 8, Canceling the Policy, to read as follows.
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>8. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Canceling the Policy</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>You</Text> may cancel <Text style={styles.boldText}>your</Text> policy at any time by telling{' '}
                <Text style={styles.boldText}>us</Text> in writing in advance, through{' '}
                <Text style={styles.boldText}>our Aviation Managers,</Text> of the date <Text style={styles.boldText}>you</Text>
                want <Text style={styles.boldText}>your</Text> coverage to end. If <Text style={styles.boldText}>you</Text> cancel the
                policy, <Text style={styles.boldText}>we</Text> will return 90% of the unearned premium{' '}
                <Text style={styles.boldText}>you</Text> have paid.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> or <Text style={styles.boldText}>our Aviation Managers</Text> can cancel this policy
                as follows:
              </Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any tome for nonpayment of premium by mailing or delivering a notice of cancellation to
                    the first Named Insured at least 10 days before the effective date of the cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect 90 days or less then this policy can be canceled at any time by mailing or delivering
                    a notice of cancellation to the first Named Insured at least 10 days notice for nonpayment of premium, 30 days notice
                    for fraud or misrepresentation, or 30 days notice for reasons 2, 3, & 4 before the effective date of cancellation;
                  </Text>
                </View>
                <CancellationReasons />
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If the policy has been in effect more than 90 days, or is a renewal, it may be canceled by mailing or delivering a
                    notice of cancellation to the first Named Insured at least 10 days for nonpayment of premium, 30 days for fraud or
                    misrepresentation, or 45 days for reasons 2, 3, & 4 before the effective date of cancellation:
                  </Text>
                </View>
                <CancellationReasons />
              </View>
            </View>
            <EndingComponent />
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> elect not to renew this policy <Text style={styles.boldText}>we</Text> will mail
                or deliver written notice of non-renewal, stating the reasons for non-renewal, to the first Named insured at least 45 days
                prior to:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>The exipiration date of this policy, if the policy is written for a term of one year or less; or</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>The anniversary date of this policy, if the policy is written for a term of more than one year.</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.listItemParagraph}>
              <Text>
                A nonrenewal notice is not required if the insured`s coverage is being transferred to an affiliated insureer as the result
                of a merger acquisition, or restructuring in the incumbent insurance company if coverage remains the same and the unsured
                approves the transfer.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Any notice will be mailed or delivered to the first Named Insured`s last know mailing address known to{' '}
                <Text style={styles.boldText}>us.</Text> If notice is mailed, proof of mailing will be sufficient proof of notice.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} form={formNumber}/>
    </Page>
  </>
);

export default IndianaEndorsement;
