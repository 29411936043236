import { Page, View, Text, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import { PageFooter } from '../../PageFooter';
import { styles } from '../../Styles';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' }
  ]
});

export const Page3 = ({formNumber}) => (
  <Page size="letter" style={styles.page}>
    <View style={styles.content}>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            7.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'} Changing the Policy
          </Text>
          <Text style={styles.listItemParagraph}>
            If <Text style={styles.boldText}>you</Text> wish to change anything in <Text style={styles.boldText}>your</Text> policy,{' '}
            <Text style={styles.boldText}>you</Text> or <Text style={styles.boldText}>your</Text> representative should contact{' '}
            <Text style={styles.boldText}>us</Text> through <Text style={styles.boldText}>our Aviation Managers,</Text> but no change occurs
            until <Text style={styles.boldText}>you</Text> or <Text style={styles.boldText}>your</Text> representative is notified in
            writing by <Text style={styles.boldText}>our Aviation Managers</Text> of <Text style={styles.boldText}>our</Text> agreement to
            change the policy.
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            8.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'} Canceling the Policy
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>You</Text> may cancel the policy at any time by telling <Text style={styles.boldText}>us</Text> in
            writing and in advance, through <Text style={styles.boldText}>our Aviation Managers,</Text> of the date you want the coverage to
            end. If <Text style={styles.boldText}>you</Text> cancel the policy, <Text style={styles.boldText}>we</Text> will refund 90% of
            the unearned premium <Text style={styles.boldText}>you</Text> have paid.
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> or <Text style={styles.boldText}>our Aviation Managers</Text> may cancel this policy at
            any time by mailing or delivering a notice of cancellation to <Text style={styles.boldText}>you</Text> at the address shown in
            Item 2 of the Coverage Identification Page at least:
          </Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                10 days before the effective date of cancellation if <Text style={styles.boldText}>we</Text> cancel for nonpayment of
                premium; or
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                30 days before the effective date of cancellation if <Text style={styles.boldText}>we</Text> cancel for any other reason.
              </Text>
            </View>
          </View>
          <Text style={styles.listItemParagraph}>
            Proof of mailing or delivery of the notice is sufficient proof of notice. <Text style={styles.boldText}>We</Text> will compute
            the premium earned by us based on the percentage of the original policy period that <Text style={styles.boldText}>we</Text>{' '}
            provided coverage
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> will return to <Text style={styles.boldText}>you</Text> any premium that{' '}
            <Text style={styles.boldText}>you</Text> have paid that <Text style={styles.boldText}>we</Text> have not earned, but making the
            refund is not a condition of cancellation.
          </Text>
          <Text style={styles.listItemParagraph}>
            However, if <Text style={styles.boldText}>we</Text> pay or have paid the <Text style={styles.boldText}>agreed value</Text> of
            the <Text style={styles.boldText}>aircraft,</Text> less any applicable deductible under the Aircraft Physical Damage coverage,{' '}
            <Text style={styles.boldText}>we</Text> are entitled to the total Aircraft Physical Damage premium shown on the Coverage
            Identification Page for the
            <Text style={styles.boldText}>aircraft</Text> for which <Text style={styles.boldText}>we</Text> made or make payment.{' '}
            <Text style={styles.boldText}>You</Text> agree to pay any premium that may be due or permit{' '}
            <Text style={styles.boldText}>us</Text> to deduct such premium from <Text style={styles.boldText}>our</Text> loss payment.
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            9.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'} Other Coverage
          </Text>
          <Text style={styles.listItemParagraph}>
            If there is other coverage protecting <Text style={styles.boldText}>you</Text> or{' '}
            <Text style={styles.boldText}>someone we protect</Text> for an <Text style={styles.boldText}>accident</Text> or{' '}
            <Text style={styles.boldText}>occurence</Text> covered by this policy, <Text style={styles.boldText}>we</Text> will pay only the
            percentage portion that the applicable limit of coverage of this policy bears to the total of the applicable limits of coverage
            for all policies.
          </Text>
          <Text style={styles.listItemParagraph}>
            If there is an <Text style={styles.boldText}>accident</Text> or <Text style={styles.boldText}>occurrence</Text> covered by{' '}
            <Text style={styles.boldText}>your</Text> policy involving the “Temporary Use of Substitute Aircraft” or “Use of Another
            Aircraft”, <Text style={styles.boldText}>your</Text> policy will be excess over any other policy protecting{' '}
            <Text style={styles.boldText}>you.</Text>
          </Text>
          <Text style={styles.listItemParagraph}>
            If there is other insurance covering the <Text style={styles.boldText}>accident</Text> or{' '}
            <Text style={styles.boldText}>occurrence</Text>occurrence issued through{' '}
            <Text style={styles.boldText}>our Aviation Managers, we</Text> will not pay more than the limits of coverage of the policy
            having the greatest limits.
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            10.{'\u00A0'}
            {'\u00A0'}Transfer of Interest in <Text style={styles.boldText}>Your</Text> Policy
          </Text>
          <Text style={styles.listItemParagraph}>
            Neither <Text style={styles.boldText}>you</Text> nor <Text style={styles.boldText}>someone we protect</Text> can transfer an
            interest in this policy without our written consent through <Text style={styles.boldText}>our Aviation Managers.</Text> If you
            die during the policy period, your legal representative will have all of <Text style={styles.boldText}>your</Text> rights and
            duties under the policy while settling <Text style={styles.boldText}>your</Text> estate if{' '}
            <Text style={styles.boldText}>we</Text> are notified through <Text style={styles.boldText}>our Aviation Managers</Text> within
            60 days of <Text style={styles.boldText}>your</Text> death.
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            11.{'\u00A0'}
            {'\u00A0'}Our Right of Recovery (Except PART FOUR. Medical Expense Coverage)
          </Text>
          <Text style={styles.listItemParagraph}>
            If <Text style={styles.boldText}>we</Text> make any payment, <Text style={styles.boldText}>we</Text> will take over{' '}
            <Text style={styles.boldText}>your</Text> right to recover the payment from <Text style={styles.boldText}>anyone</Text> who is
            responsible. <Text style={styles.boldText}>You</Text> and <Text style={styles.boldText}>someone we protect</Text> must do
            everything necessary to transfer this right of recovery to <Text style={styles.boldText}>us,</Text> including allowing suit to
            be brought in <Text style={styles.boldText}>your</Text>
            name or in the name of <Text style={styles.boldText}>someone we protect. You</Text> and{' '}
            <Text style={styles.boldText}>someone we protect</Text> must do nothing that will interfere with, limit or waive
            <Text style={styles.boldText}>our</Text> right to recover.
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            12.{'\u00A0'}
            {'\u00A0'}State Statutes
          </Text>
          <Text style={styles.listItemParagraph}>
            Any statement or provision of this policy which conflicts with the laws of the state shown in Item 4 of the Coverage
            Identification Page is hereby amended by <Text style={styles.boldText}>us</Text> to conform to the laws of such state.
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            13.{'\u00A0'}
            {'\u00A0'}Legal Action Against <Text style={styles.boldText}>Us</Text>
          </Text>
          <Text style={styles.listItemParagraph}>
            No legal action shall be brought against <Text style={styles.boldText}>us</Text> until the policy provisions have been complied
            with fully. No one shall have the right to:
          </Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                join <Text style={styles.boldText}>us</Text> as a party to any legal action brought against{' '}
                <Text style={styles.boldText}>you</Text> or <Text style={styles.boldText}>someone we protect;</Text> or
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                bring <Text style={styles.boldText}>us</Text> into any legal action to determine <Text style={styles.boldText}>your</Text>{' '}
                liability or the liability of <Text style={styles.boldText}>someone we protect.</Text>
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
    <PageFooter form={formNumber} number={5} />
  </Page>
);
