import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import EndingComponent from '../Components/EndingComponent';
import BeginningComponent from '../Components/BeginingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const WyomingEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <BeginningComponent state="Wyoming" />
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any time for nonpayment of premium by mailing or delivering a notice of cancellation to
                    the first Named Insured at least 10 days before the effective date of the cancellation.
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this plicy has been in effect less than 60 days, it may be canceled at any time by mailing or delivering a notice of
                    cancellation to the first Named Insured at least 30 days before the effective date of cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect for 60 days or more, it may be canceled at any time by mailing or delivering a notice
                    of cancellation to the first Named Insured at least 45 days before the effective date of cancellation, but only for one
                    or more of the following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Material misrepresentation of fact which, if known to <Text style={styles.boldText}>us,</Text> would have caused{' '}
                        <Text style={styles.boldText}>us</Text> not to issue the policy;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Substantial change in the risk assumed, except to the extent that <Text style={styles.boldText}>we</Text> should
                        reasonably have foreseen the change or contemplated the risk in writing this policy; or
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Substantial breaches of contractual duties, conditions or warranties.</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <EndingComponent extra={false} />
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> decide not to renew this policy or renew at less favorable terms,{' '}
                <Text style={styles.boldText}>we</Text> will mail or deliver written notice of non-renewal, stating the reasons for
                nonrenewal, to the first Named Insured and agent, if any, at least 45 days before the expiration of the policy.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> intended to renew this policy, but on less favorable terms or a higher rates,{' '}
                <Text style={styles.boldText}>we</Text> will provide <Text style={styles.boldText}>you</Text> with the renewal terms and a
                statement of the premium due at least 45 days before the policy`s expiration date.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Any notice will be mailed or delivered to the first Named Insured`s last known mailing known to{' '}
                <Text style={styles.boldText}>us.</Text> If notice is mailed, proof of mailing is sufficient proof of notice.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement also changes <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS,</Text> Paragraph 5.a Additional
            Protection, to read as follows:
          </Text>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text style={{ fontStyle: 'italic' }}>Defend Claims.</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Defend at our expense with attorneys <Text style={styles.boldText}>we</Text> choose, any claim or legal action against{' '}
                <Text style={styles.boldText}>you</Text> or <Text style={styles.boldText}>someone we protect</Text> with respect to any
                claims for <Text style={styles.boldText}>bodily injury</Text> or <Text style={styles.boldText}>property damage</Text>{' '}
                resulting from an <Text style={styles.boldText}>occurence we</Text> cover until <Text style={styles.boldText}>we</Text> have
                paid the limit of coverage you have purchased. <Text style={styles.boldText}>We</Text> also may investigate, negotiate, or
                settle any claim or legal action as we elect. The offer to pay, or tendering of, the limits of coverage before the judgment
                or settlement does not relieve us of our obligation to provide you with this defense.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} form={formNumber}/>
    </Page>
  </>
);

export default WyomingEndorsement;
