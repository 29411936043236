import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import BeginningComponent from '../Components/BeginingComponent';
import EndingComponent from '../Components/EndingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const NewHampshireEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <BeginningComponent state="New Hampshire" />
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any time for nonpayment of premium or substantial increase in hazard by mailing or
                    delivering a notice of cancellation to the Named Insured at least 10 days before the effective date of the cancellation;
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect less than 60 days <Text style={styles.boldText}>we</Text> may cancel at any time by
                    mailing or delivering a notice of cancellation to the Named Insured at least 60 days before the effective date of
                    cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If the policy has been in effect for 60 days or more, or if this is a renewal of a policy{' '}
                    <Text style={styles.boldText}>we</Text> issued, <Text style={styles.boldText}>we</Text> may cancel by mailing or
                    delivering a notice of cancellation to the Named Insured at least 60 days before the effective date of cancellation and
                    only for one or more of the following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Fraud or material misrepresentation affecting the policy or in the presentation of a claim;</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>If the insured violates of any the terms or conditions of the policy; or</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Substantial increase in hazard; provided that cancellation for this reason shall be effective only after prior
                        approval of the Commissioner of Insurance of the State of New Hampshire.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View>
              <View style={styles.listItemParagraph}>
                <Text>
                  <Text style={styles.boldText}>We</Text> will mail or physically deliver <Text style={styles.boldText}>our</Text> notice to{' '}
                  <Text style={styles.boldText}>your</Text> last mailing address known to <Text style={styles.boldText}>us</Text>. If notice
                  is mailed, it will be by:
                </Text>
              </View>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Certified mail or certificate of mailing if cancellation is for nonpayment of premium.</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Certified mail if cancellation is for any other reason.</Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>Proof that the notice was mailed in accordance with Paragraph .a. or. b. will be sufficient proof of notice.</Text>
            </View>
            <EndingComponent extra={false} proof={false} />
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> decide not to renew this policy <Text style={styles.boldText}>we</Text> will mail
                or deliver written notice of non-renewal, stating the reasons for nonrenewal, to the Named Insured at least 60 days prior to
                the expiration of the policy. <Text style={styles.boldText}>We</Text> will not mail or deliver this notice if:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>We</Text> have offered to issue a renewal policy; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>We</Text> refuse to renew due to nonpayment of premium; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>You</Text> do not pay any advance premium required by{' '}
                    <Text style={styles.boldText}>us</Text> for renewal; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>d. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Any property covered in this policy is insured under any other insurance policy.</Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> increase the premium of 25% or more, climate coverage, reduce coverage, change
                conditions or definitions, <Text style={styles.boldText}>we</Text> will notify <Text style={styles.boldText}>you</Text> by
                written notice at least 60 days prior to the expiration of the policy.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.listItemParagraph}>
              <Text>
                Any notice will be mailed or delivered to the Named Insured`s last known mailing address known to{' '}
                <Text style={styles.boldText}>us</Text>. If notice is mailed, proof of mailing will be sufficient proof of notice for
                non-renewal. If notice is mailed for cancellation, the notice will be sent by certified mail or certificate of mailing if
                for non-payment of premium or by certified mail if for any other reasons.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS,</Text> Paragraph 14, Concealment or Misrepresentation, to read as
            follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>14. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Concealment ot Misrepresentation</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> do not provide coverage to <Text style={styles.boldText}>you</Text> or{' '}
                <Text style={styles.boldText}>someone we protect</Text> who, at any time:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>1. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Intentionally concealed or misrepresentation a material fact;</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>2. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Engaged in fraudulent conduct; or</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>3. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>Made a false statement relating to this insurance.</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement also changes <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE,</Text> Paragraph 6. When{' '}
            <Text style={styles.boldText}>We</Text> Will Pay, to read as follows:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>6. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                When <Text style={styles.boldText}>We</Text> Will Pay
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will pay for loss of or damage to the <Text style={styles.boldText}>aircraft</Text>{' '}
                covered by <Text style={styles.boldText}>your</Text> policy.
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <View style={styles.listItemParagraph}>
                  <Text style={{ fontStyle: 'italic' }}>Damaged Aircraft</Text>
                </View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    within 5 days after <Text style={styles.boldText}>you</Text> have given <Text style={styles.boldText}>us</Text> a sworn
                    Proof of Loss statement and <Text style={styles.boldText}>you</Text> and <Text style={styles.boldText}>we</Text> agree
                    on the amount; or
                  </Text>
                </View>
              </View>
              <View>
                <View style={styles.listItemParagraph}>
                  <Text style={{ fontStyle: 'italic' }}>Destroyed Aircraft</Text>
                </View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    within 5 days after <Text style={styles.boldText}>you</Text> have given <Text style={styles.boldText}>us</Text> a sworn
                    Proof of Loss statement and <Text style={styles.boldText}>you</Text> have delivered the{' '}
                    <Text style={styles.boldText}>aircraft</Text> log books, airworthiness certificate and a bill of sale transferring clear
                    title to the <Text style={styles.boldText}>aircraft</Text>.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} form={formNumber}/>
    </Page>
  </>
);

export default NewHampshireEndorsement;
