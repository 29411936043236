import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Box, Typography, Stepper, Step, StepLabel, Button, useTheme, useMediaQuery, MobileStepper } from '@mui/material';
import Breadcrumb from 'component/Breadcrumb';
import AgentQuote from '../../../component/AgentQuote';
import AgencyQuote from '../../../component/AgencyQuote';
import AirportQuoteCustomerOrg from '../../../component/CustomerOrgQuote';
import AirportCustomerContact from '../../../component/CustomerContactQuote';
import AirportQuoteComponent from '../Airport';
import AirportQuotePolicy from 'component/PolicyQuote';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';

const steps = ['Agency', 'Agent', 'Named Insured', 'Customer Contact', 'Airport', 'Policy'];
const AirportQuoteStepper = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const { companyId, quoteId } = useParams();

  const [selectedAgency, setSelectedAgency] = useState({});
  const [selectedAgent, setSelectedAgent] = useState({});
  const [selectedCustomerOrg, setSelectedCustomerOrg] = useState({});
  const [selectedCustomerContact, setSelectedCustomerContact] = useState({});
  const [selectedAirport, setSelectedAirport] = useState([]);

  const handleFinish = (row) => {
    navigate(`/company/${companyId}/policy/${row.id}`);
  };

  const handleFinishSaveQuote = async () => {
    await axios.delete(`/api/quotes/${quoteId}/companyId/${companyId}`);
  };

  const [disableNext, setDisableNext] = useState([
    { id: 0, val: false },
    { id: 1, val: false },
    { id: 2, val: false },
    { id: 3, val: false },
    { id: 4, val: false }
  ]);

  const handleSelect = (emitActiveStep, row) => {
    console.log('Handle Select', emitActiveStep, row);
    const tempArray = [...disableNext];
    switch (emitActiveStep) {
      case 0: {
        setSelectedAgency(row);
        handleSelectSave(row.id, 'idAgency');
        tempArray[emitActiveStep].val = true;
        break;
      }
      case 1: {
        setSelectedAgent(row);
        handleSelectSave(row.id, 'idAgent');
        tempArray[emitActiveStep].val = true;
        break;
      }
      case 2: {
        setSelectedCustomerOrg(row);
        handleSelectSave(row.id, 'idCustomerOrg');
        tempArray[emitActiveStep].val = true;
        break;
      }
      case 3: {
        setSelectedCustomerContact(row);
        handleSelectSave(row.id, 'idCustomerContact');
        tempArray[emitActiveStep].val = true;
        break;
      }
      case 4: {
        setSelectedAirport(row);
        handleSelectSave(row.id, 'idAirport');
        tempArray[emitActiveStep].val = true;
        break;
      }
      case 5: {
        handleFinish(row);
        handleFinishSaveQuote();
        break;
      }
    }
    handleNext();
    setDisableNext(tempArray);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSelectSave = async (value, item) => {
    await axios.put(`/api/quotes/${quoteId}/companyId/${companyId}`, {
      quote: {
        [item]: value
      }
    });
  };

  useEffect(() => {
    const getSavedAirportQuote = async () => {
      const response = await axios.get(`/api/quotes/${quoteId}/companyId/${companyId}`);
      const { Agency, Agent, CustomerOrganization, CustomerContact, Airport } = response.data;
      const tempNext = [...disableNext];
      if (Agency) {
        setSelectedAgency({ ...Agency });
        tempNext[0].val = true;
      }
      if (Agent) {
        setSelectedAgent({ ...Agent });
        tempNext[1].val = true;
      }
      if (CustomerOrganization) {
        setSelectedCustomerOrg({ ...CustomerOrganization });
        tempNext[2].val = true;
      }
      if (CustomerContact) {
        setSelectedCustomerContact({ ...CustomerContact });
        tempNext[3].val = true;
      }
      if (Airport) {
        setSelectedAirport({ ...Airport });
        tempNext[4].val = true;
      }

      setDisableNext(tempNext);
    };

    getSavedAirportQuote();
  }, []);

  return (
    <>
      <Breadcrumb sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography
          component={Link}
          to={`/company/${companyId}/workflow/airport-quote/`}
          variant="subtitle2"
          color="inherit"
          className="link-breadcrumb"
        >
          Airport Quote
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Quote workflow
        </Typography>
      </Breadcrumb>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h4" sx={{ mb: 2, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          Airport Quote
        </Typography>
        {!isMobile && (
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ mt: 4 }}>
              {activeStep === 0 && (
                <AgencyQuote
                  companyId={companyId}
                  selectedAgency={selectedAgency}
                  activeStep={activeStep}
                  emitSelectedAgency={handleSelect}
                />
              )}
              {activeStep === 1 && (
                <AgentQuote
                  companyId={companyId}
                  selectedAgency={selectedAgency}
                  selectedAgent={selectedAgent}
                  activeStep={activeStep}
                  emitSelectedAgent={handleSelect}
                />
              )}
              {activeStep === 2 && (
                <AirportQuoteCustomerOrg
                  companyId={companyId}
                  selectedCustomerOrg={selectedCustomerOrg}
                  activeStep={activeStep}
                  emitSelectedCustomerOrg={handleSelect}
                />
              )}
              {activeStep === 3 && (
                <AirportCustomerContact
                  companyId={companyId}
                  selectedCustomerContact={selectedCustomerContact}
                  selectedCustomerOrg={selectedCustomerOrg}
                  activeStep={activeStep}
                  emitSelectedCustomerContact={handleSelect}
                />
              )}
              {activeStep === 4 && (
                <AirportQuoteComponent
                  selectedAgency={selectedAgency}
                  selectedAgent={selectedAgent}
                  selectedAirport={selectedAirport}
                  activeStep={activeStep}
                  emitAirportSelect={handleSelect}
                />
              )}
              {activeStep === 5 && (
                <AirportQuotePolicy
                  companyId={companyId}
                  selectedAgency={selectedAgency}
                  selectedAgent={selectedAgent}
                  selectedContact={selectedCustomerContact}
                  selectedCustomerOrg={selectedCustomerOrg}
                  selectedAirport={selectedAirport}
                  handleNext={handleNext}
                  emitSelectedPolicy={handleSelect}
                  activeStep={activeStep}
                  isAirport={true}
                />
              )}
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              {isMobile ? (
                <MobileStepper
                  variant="progress"
                  steps={6}
                  position="static"
                  activeStep={activeStep}
                  sx={{ maxWidth: 400, flexGrow: 1 }}
                  nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
                      Next
                      {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                  }
                  backButton={
                    <Button size="small" disabled={activeStep === 0} onClick={handleBack}>
                      {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      Back
                    </Button>
                  }
                />
              ) : (
                <>
                  <Button
                    size="large"
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ width: '200px', height: '60px', fontSize: '1.5rem' }}
                  >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight fontSize="large" /> : <KeyboardArrowLeft fontSize="large" />}
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep !== 5 && (
                    <Button
                      size="large"
                      sx={{ width: '200px', height: '60px', fontSize: '1.5rem' }}
                      onClick={handleNext}
                      disabled={!disableNext.find((item) => item.id === activeStep).val}
                    >
                      Next {theme.direction === 'rtl' ? <KeyboardArrowLeft fontSize="large" /> : <KeyboardArrowRight fontSize="large" />}
                    </Button>
                  )}
                </>
              )}
            </Box>
          </React.Fragment>
        )}
      </Box>
    </>
  );
};

export default AirportQuoteStepper;
