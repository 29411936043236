import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import BeginningComponent from '../Components/BeginingComponent';
import EndingComponent from '../Components/EndingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const PennsylvaniaChageEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <BeginningComponent state="Pennsylvania" />
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any time for nonpayment of premium by mailing or delivering a notice of cancellation to
                    the first Named Insured at least 30 days before the effective date of the cancellation;
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect less than 60 days <Text style={styles.boldText}>we</Text> may cancel at any time by
                    mailing or delivering a notice of cancellation to the first Named Insured at least 30 days before the effective date of
                    cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect for 60 days or more, <Text style={styles.boldText}>we</Text> may cancel at any time by
                    mailing or delivering a notice of cancellation to the first Named Insured at least 60 days before the effective date of
                    the cancellation for only one or more of the following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1). &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        <Text style={styles.boldText}>You</Text> have made a material misrepresentation which affects the insurability of
                        the risk. Notice of cancellation will be mailed or delivered at least 15 days before the effective date of
                        cancellation.
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2). &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        <Text style={styles.boldText}>You</Text> have failed to pay a premium when due, whether the premium is payable
                        directly to <Text style={styles.boldText}>us</Text> or <Text style={styles.boldText}>our</Text> agents or indirectly
                        under a premium finance plan or extension of credit. Notice of cancellation will be mailed at least 15 days before
                        the effective date of cancellation.
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3). &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Substantial change in the risk insured.</Text>
                    </View>
                  </View>
                  <View>
                    <View>
                      <Text style={styles.listItemHeading}>(4). &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                        <Text>
                          A condition, factor or loss experience material to insurability has changed substantially or a substantial
                          condition, factor or loss experience material to insurability has become known during the policy period. Notice of
                          cancellation will be mailed or delivered at least 60 days before the effective date of cancellation.
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>(5). &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                        <Text>
                          Loss of reinsurance or a substantial decrease in reinsurance has occurred, which loss or decrease, at the time of
                          cancellation, shall be certified to the Insurance Commissioner as directly affecting in-force policies. Notice of
                          cancellation will be mailed or delivered at least 60 days before the effective date of cancellation.
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>(6). &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                        <Text>
                          Material failure to comply with policy terms, conditions or contractual duties. Notice of cancellation will be
                          mailed or delivered at least 60 days before the effective date of cancellation.
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>(7). &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                        <Text>
                          Other reasons that the Insurance Commissioner may approve. Notice of cancellation will be mailed or delivered at
                          least 60 days before the effective date of cancellation.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <EndingComponent extra={false} />
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> decide not to renew this policy, <Text style={styles.boldText}>we</Text> will
                mail or deliver written notice of nonrenewal, stating the specific reasons for non renewal, to the first Named Insured at
                least 60 days before the expiration date of the policy. If <Text style={styles.boldText}>we</Text> increase your renewal
                premium, we will mail or deliver to the first Named Insured written notice of our intent to increase the premium at least 30
                days before the effective date of the premium increase. Any notice of nonrenewal or renewal premium increase will be mailed
                or delivered to the first Named lnsured`s last known address. If notice is mailed, it will be by registered or first class
                mail. Proof of mailing will be sufficient proof of notice.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} form={formNumber}/>
    </Page>
  </>
);

export default PennsylvaniaChageEndorsement;
