import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const LiabilityCoverage = ({ aircraft, type, formNumber }) => (
  <Page size="letter" style={styles.page}>
    <View style={[styles.header, { fontSize: 8 }]}>
      <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
    </View>
    <View style={styles.header}>
      <Text style={styles.boldText}>{`Liability Coverage Extension ${type === 'GENERAL' ? '' : '& Waiver'}`}</Text>
    </View>
    <View style={styles.centeredText}>
      <Text>{type}</Text>
    </View>
    <View style={styles.content}>
      <View>
        <Text>
          This Endorsement only applies to the following <Text style={styles.boldText}>aircraft: {aircraft?.registrationNo}</Text>
        </Text>
      </View>
      <View style={styles.centeredText}>
        <Text style={{ fontStyle: 'italic' }}>
          If no entry is made this endorsement applies to all <Text style={styles.boldText}>aircraft</Text> covered by{' '}
          <Text style={styles.boldText}>your</Text> policy.
        </Text>
      </View>
      <View>
        <Text>
          Various provisions of this endorsement extend or restrict coverage. Read <Text style={styles.boldText}>your</Text> entire policy
          to determine your rights and obligations and what is and is not covered. This endorsement changes the provisions of your policy
          under the following coverage parts:
        </Text>
      </View>
      <View>
        <Text style={[styles.boldText, { fontSize: 8 }]}>Part Three. LIABILITY TO OTHERS</Text>
      </View>
      <View>
        <Text>
          <Text style={styles.boldText}>Your</Text> coverage under <Text style={styles.boldText}>Part Three LIABILITY TO OTHERS</Text> is
          extended to include within the definition of <Text style={styles.boldText}>someone we protect</Text> any person(s) or
          organization(s) designated below with respect to an <Text style={styles.boldText}>occurrence</Text> arising out the use of the{' '}
          <Text style={styles.boldText}>aircraft</Text> by <Text style={styles.boldText}>you</Text> or{' '}
          <Text style={styles.boldText}>someone we protect</Text> as permitted by <Text style={styles.boldText}>your</Text> policy, but the
          limit of coverage shown in Item 6 of <Text style={styles.boldText}>your</Text> Coverage Identification Page does not increase
          regardless of the number of persons or organizations protected.
        </Text>
      </View>
      {type === 'FLIGHT INSTRUCTION OR PROFICIENCY TRAINING' && (
        <View>
          <Text>
            As used in this endorsement, the term “flight instruction or proficiency training” means flight instruction or proficiency
            training provided by or under the supervision of an <Text style={styles.boldText}>FAA</Text> Certified Flight Instructor.
          </Text>
        </View>
      )}
      <View>
        <Text>
          This extension of coverage includes the employees, officers or directors of any designated organization as{' '}
          <Text style={styles.boldText}>someone we protect,</Text>
          while acting within the scope of their duties as such, with respect to any <Text style={styles.boldText}>occurrence</Text> covered
          by the provisions of this endorsement. This coverage extension applies to the following:
        </Text>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber}/>
  </Page>
);

export default LiabilityCoverage;
