import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 9,
    gap: 10
  },
  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },

  nestedListContainer: {
    paddingLeft: 15,
    gap: '-6px' // Indent nested items
  },
  nestedListItemParagraph: {
    top: '-12px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' }
  ]
});

const FinancedEndorsement = ({formNumber}) => (
  <Page size="letter" style={styles.page}>
    <View style={[styles.header, { fontSize: 8 }]}>
      <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
    </View>
    <View style={styles.header}>
      <Text style={styles.boldText}>Financed Premium Endorsement</Text>
    </View>
    <View style={styles.content}>
      <View>
        <Text>NAME AND ADDRESS OF LENDER:</Text>
      </View>
      <View>
        <Text>IPFS Corporation</Text>
        <Text>3 Hutton Centre Drive, Suite 630</Text>
        <Text>Santa Ana, CA 92707</Text>
      </View>
      <View style={{marginTop: '10px'}}>
        <Text>
          The premium for <Text style={styles.boldText}>your</Text> policy has been financed in whole or in part through the premium
          financer (“Lender”) identified above under an agreement assigning certain rights of <Text style={styles.boldText}>yours</Text> to
          the Lender. <Text style={styles.boldText}>You</Text> and <Text style={styles.boldText}>we</Text> agree as follows:
        </Text>
        <View style={[styles.listContainer, { marginTop: '10px' }]}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>
              1. &nbsp; &nbsp; The Lender will have the right to cancel this policy in <Text style={styles.boldText}>your</Text> name, and
            </Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>
              2. &nbsp; &nbsp; Loss, if any, under Coverage F or G will be adjusted with you and payable to{' '}
              <Text style={styles.boldText}>you</Text> and the Lender (and other interests, if any), as the respective interests may appear;
              and
            </Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>
              3. &nbsp; &nbsp; If <Text style={styles.boldText}>we</Text> cancel <Text style={styles.boldText}>your</Text> policy for any
              reason other than non-payment of premium, <Text style={styles.boldText}>we</Text> will send thirty (30) days prior written
              notice of cancellation to the Lender at the address shown in this endorsement.
            </Text>
          </View>
        </View>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber}/>
  </Page>
);

export default FinancedEndorsement;
