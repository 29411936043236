import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const HeliocopterEndorsement = ({ aircraft, formNumber }) => (
  <Page size="LETTER" style={styles.page}>
    <View style={[styles.header, { fontSize: 8 }]}>
      <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
    </View>
    <View style={styles.header}>
      <Text style={styles.boldText}>Helicopter Endorsement</Text>
    </View>
    <View style={styles.content}>
      <View>
        <Text>
          This Endorsement only applies to the following <Text style={styles.boldText}>aircraft: {aircraft?.registrationNo}</Text>
        </Text>
      </View>
      <View style={styles.header}>
        <Text style={{ fontStyle: 'italic' }}>
          If no entry is made this endorsement applies to all <Text style={styles.boldText}>aircraft</Text> covered by{' '}
          <Text style={styles.boldText}>your</Text> policy.
        </Text>
      </View>
      <View>
        <Text>
          Various provisions of this endorsement extend or restrict coverage. Read your entire policy to determine your rights and
          obligations and what is and is not covered. This endorsement changes the provisions of your policy under the following coverage
          parts:
        </Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE</Text>
        <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS</Text>
        <Text style={styles.boldText}>Part Four. MEDICAL EXPENSE</Text>
      </View>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>1. &nbsp; &nbsp; &nbsp;</Text>
          <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
            <Text>
              Item 5 of your Coverage Identification Page is completed or changed to reflect that the aircraft described in this endorsement
              is a helicopter.
            </Text>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>2. &nbsp; &nbsp; &nbsp;</Text>
          <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
            <Text>
              The following definitions are added to Part One GENERAL PROVISIONS AND CONDITIONS, Paragraph 1, Words and Phrases with respect
              to all coverage applicable to all aircraft covered by this endorsement.
            </Text>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>
              A helicopter is in motion and in flight when any engine in the aircraft is being started or is operating or when the aircraft
              is off any supporting surface.
            </Text>
          </View>
        </View>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber} />
  </Page>
);

export default HeliocopterEndorsement;
