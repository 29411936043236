import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import BeginningComponent from '../Components/BeginingComponent';
import EndingComponent from '../Components/EndingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const UtahEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <BeginningComponent state="Utah" />
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any time for nonp ayment of premium by mailing or delivering a notice of cancellation to
                    the first Named Insured at least 10 days before the effective date of the cancellation;
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect less than 60 days and it is not a renewal it may be canceled at any time by mailing or
                    delivering a notice of cancellation to the first Named Insured at least 30 days before the effective date of
                    cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect for 60 days or more, it may be canceled at any time by mailing or delivering a notice
                    of cancellation to the first Named Insured at least 30 days before the effective date of cancellation, but only for one
                    or more of the following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Material misrepresentation;</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Substantial change in the risk assumed unless we should reasonably have foreseen the change or contemplated the risk
                        when entering into the contract; or
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Substantial breaches of contractual duties, conditions or warranties.</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <EndingComponent extra={false} />
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> elect not to renew this policy <Text style={styles.boldText}>we</Text> will mail
                or deliver written notice of non-renewal to the first Named Insured at least 30 days before the expiration date.{' '}
                <Text style={styles.boldText}>We</Text> need not mail this notice if:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>You</Text> have accepted replacement coverage;
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>You</Text> have requested or agreed to non-renewal; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>This policy is expressly designated as non-renewable.</Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will mail or deliver any notice to the first Named Insured at the last known mailing
                address as shown in Item 2 of the Coverage Identification Page of this policy. Any notice of cancellation shall state the
                actual reason for the action taken. If notice is mailed, proof of mailing is sufficient proof of notice.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={1} form={formNumber}/>
    </Page>
  </>
);

export default UtahEndorsement;
