import React, { useEffect, useState } from 'react';
import initializeAxios from 'utils/axios';

// material-ui
import { Chip } from '@mui/material';
import useAuth from 'hooks/useAuth';

// project import

// assets
import CustomTable from 'component/CustomTable';
import LoadingOverlay from 'component/LoadingOverlay';
import AgentDialog from 'component/AgentDialog';

// ==============================|| CONTACT LIST ||============================== //

const AgentQuote = ({ companyId, selectedAgency, selectedAgent, activeStep, emitSelectedAgent }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const columns = [
    { id: 'displayName', label: 'Display Name' },
    { id: 'email', label: 'Email' }
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState([]);
  const [actionType, setActionType] = useState('');
  const [agent, setAgent] = useState({});
  const [agentId, setAgentId] = useState(null);
  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setAgent({});
  };

  const handleEdit = (event, row) => {
    event.stopPropagation();
    // eslint-disable-next-line no-unused-vars
    const { id, Company, Agency, ...rest } = row;
    setAgentId(id);
    setActionType('Edit');
    setAgent({ ...rest });
    setOpen(true);
  };


  const handleAgentSelect = (row) => {
    emitSelectedAgent(activeStep, row);
  };

  const handleSave = async (agentDialog) => {
    setIsLoading(true);
    const displayName = `${agentDialog?.firstname} ${agentDialog?.lastname}`;
    let response;
    switch (actionType) {
      case 'Create': {
        response = await axios.post(`/api/agent/companyId/${companyId}`, {
          agent: {
            ...agentDialog,
            displayName,
            idAgency: +selectedAgency.id
          }
        });
        emitSelectedAgent(activeStep, response.data);
        break;
      }
      case 'Edit': {
        response = await axios.put(`/api/agent/${agentId}/companyId/${companyId}`, {
          agent: {
            ...agentDialog,
            displayName,
            idAgency: +selectedAgency.id
          }
        });
        const tempArray = [...agents];
        const index = tempArray.findIndex((item) => item.id === agentId);
        tempArray[index] = response.data;
        setAgents(tempArray);
      }
    }
    handleClosedialog();
    setIsLoading(false);
  };

  useEffect(() => {
    const getAgents = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/agent/agencyId/${selectedAgency.id}/companyId/${companyId}`);
      setAgents([...result.data]);
      setIsLoading(false);
    };

    getAgents();
  }, [companyId, selectedAgency]);

  return (
    <>
      <LoadingOverlay loading={isLoading} />
      <AgentDialog open={open} agent={agent} handleClosedialog={handleClosedialog} handleAgentSave={handleSave} actionType={actionType} />
      <CustomTable
        isLoading={isLoading}
        title="Agents"
        data={agents}
        columns={columns}
        isSearch={true}
        selectedRows={[selectedAgent]}
        hasCreateButton={true}
        hasClickableRows={true}
        emitClickedRow={handleAgentSelect}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <>
            <Chip
              label="Select"
              color="primary"
              variant={selectedAgent.id === row.id ? 'filled' : 'outlined'}
              sx={{ color: selectedAgent.id === row.id ? '#fff' : '#000', marginRight: 1 }}
              clickable
            />
            <Chip
              label="Edit"
              color="primary"
              variant="filled"
              sx={{ color: '#fff', width: '40%' }}
              clickable
              onClick={(e) => handleEdit(e, row)}
            />
          </>
        )}
      </CustomTable>
    </>
  );
};

export default AgentQuote;
