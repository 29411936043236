import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import BeginningComponent from '../Components/BeginingComponent';
import EndingComponent from '../Components/EndingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const NewMexicoEndorsement = ({formNumber}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <BeginningComponent state="New Mexico" />
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any time for nonpayment of premium by mailing or delivering a notice of cancellation to
                    the first Named Insured at least 10 days before the effective date of the cancellatio;
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect less than 60 days <Text style={styles.boldText}>we</Text> may cancel at any time by
                    mailing or delivering a notice of cancellation to the first Named Insured at least 30 days before the effective date of
                    cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If the policy has benn effect for 60 days or more, it may be canceled by mailing or delivering a notice of cancellation
                    to the first Named Insured at least 30 days before the effective date of cancellation and only for one or more of the
                    following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        The policy was obtained through material misrepresentation, fraudulent statements, omissions or concealment of fact
                        material to the acceptance of the risk or to the hazard assumed by <Text style={styles.boldText}>us;</Text>
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Willful and negligent acts or omissions bt you have substantially increased the hazards insured against;</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        <Text style={styles.boldText}>You</Text> presented a claim based on fraud or material misrepresentation; or
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        A substantial change in the risk assumed by us, the policy may be canceled by mailing or delivering a notice of
                        cancellation to the first Named Insured at least 30 days before the effective date of cancellation.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <EndingComponent extra={false} />
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> elect not to renew this policy <Text style={styles.boldText}>we</Text> will mail
                or deliver a notice of non-renewal to the first Named Insured and your agent not less than 45 days prior to the expiration
                date of this policy.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will mail or deliver any notice to <Text style={styles.boldText}>you</Text> at{' '}
                <Text style={styles.boldText}>your</Text> last known address as shown in item 2 of the Coverage Identification Page. Any
                notice of cancellation shall state the actual reason for the action taken. Proof of mailing or delivery of any notice is
                sufficient proof of notice.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={1} form={formNumber}/>
    </Page>
  </>
);

export default NewMexicoEndorsement;
