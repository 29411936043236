import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import { FooterPDF } from 'views/PDFComponents/Footer';
const styles = StyleSheet.create({
  header: {
    fontSize: 9,
    textAlign: 'center',
    marginBottom: 10
  },
  divider: {
    borderTop: '3px solid black'
  },

  contentDivider: {
    borderTop: '1px solid black'
  },
  content: {
    fontSize: 8
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  textContent: {
    fontFamily: 'OpenSans'
  },

  listContainer: {
    paddingLeft: 10,
    marginVertical: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },

  nestedListContainer: {
    paddingLeft: 15 // Indent nested items
  },
  nestedListItemParagraph: {
    top: '-12px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' }
  ]
});

const LienHolderInterestPDF = ({ state = '', formNumber }) => (
  <>
    <Page size="letter" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>{`Lienholder's Interest Endorsement ${state !== '' ? '- ' + state : ''}`}</Text>
      </View>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement extends the coverage under Part Two (AIRCRAFT PHYSICAL DAMAGE COVERAGE) to protect the interest of the
            Lienholder with respect to the particular <Text style={styles.boldText}>aircraft</Text> identified in Item 11 of{' '}
            <Text style={styles.boldText}>your</Text> Coverage Identification Page where a “Yes” is shown under “Lienholder Interest,” or
            otherwise identified by an endorsement to this policy,{' '}
            {state === 'New Hampshire' && (
              <Text>
                even if coverage is otherwise invalidated by any act or omission of yours or someone we protect. The following provisions
                will any to the coverage provided by this endorsement and to the following aircraft only
              </Text>
            )}{' '}
            as follows:{' '}
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>
              1.{'\u00A0'}
              {'\u00A0'}
              {'\u00A0'} Adjustment and Payment
            </Text>
            <View style={styles.listItemParagraph}>
              <Text>
                Loss or damage to the <Text style={styles.boldText}>aircraft</Text> under Coverage F or G will be adjusted with{' '}
                <Text style={styles.boldText}>you</Text> and payment will be made to <Text style={styles.boldText}>you</Text> and the
                Lienholder shown in Item 11 of the Coverage Identification Page or an endorsement to the policy.
              </Text>
            </View>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>
              2.{'\u00A0'}
              {'\u00A0'}
              {'\u00A0'} Payment to Lienholder Only
            </Text>
            <View style={styles.listItemParagraph}>
              <Text>
                If this <Text>Lienholder&apos;s</Text> interest Endorsement applies, we will also pay the Lienholder shown in item 11 of the
                Coverage Identification Page or an endorsement to this policy for loss of or damage to the{' '}
                <Text style={styles.boldText}>aircraft</Text> if:
              </Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>
                  a.{'\u00A0'}
                  {'\u00A0'}
                  {'\u00A0'}
                </Text>
                <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  There otherwise would be coverage for the claim except for any act or omission by <Text style={styles.boldText}>you</Text>{' '}
                  or <Text style={styles.boldText}>anyone</Text> which results in <Text style={styles.boldText}>our</Text> denial of payment
                  to <Text style={styles.boldText}>you</Text>; and
                </Text>
              </View>
              <View>
                <Text style={styles.listItemHeading}>
                  b.{'\u00A0'}
                  {'\u00A0'}
                  {'\u00A0'}
                </Text>
                <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>The Lienholder</Text>
                <View style={styles.nestedListContainer}>
                  <View>
                    <Text style={styles.listItemHeading}>
                      (1){'\u00A0'}
                      {'\u00A0'}
                      {'\u00A0'}
                    </Text>
                    <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      has notified <Text style={styles.boldText}>us</Text> of any change of interest in or use of the{' '}
                      <Text style={styles.boldText}>aircraft</Text> of which the Lienholder was aware;
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>
                      (2){'\u00A0'}
                      {'\u00A0'}
                      {'\u00A0'}
                    </Text>
                    <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      pays any premium due under this policy on demand if <Text style={styles.boldText}>you</Text> have neglected to pay the
                      premium; and
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>
                      (3){'\u00A0'}
                      {'\u00A0'}
                      {'\u00A0'}
                    </Text>
                    <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      gives <Text style={styles.boldText}>us</Text> a sworn proof of loss within 90 days after receiving notice from{' '}
                      <Text style={styles.boldText}>us</Text> of <Text style={styles.boldText}>your</Text> failure to do so or the denial of{' '}
                      <Text style={styles.boldText}>your</Text> claim.
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={[{ top: '-10px' }, styles.listItem]}>
              <Text style={styles.listItemHeading}>
                3.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'} What <Text style={styles.boldText}>We</Text> Will Pay
              </Text>
              <View style={styles.listItemParagraph}>
                <Text>
                  Under Paragraph 2 above, <Text style={styles.boldText}>we</Text> will pay the Lienholder the lesser of the following
                  amounts:
                </Text>
              </View>
              <View style={styles.nestedListContainer}>
                <View>
                  <Text style={styles.listItemHeading}>
                    a.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    The unpaid balance of the loan on the <Text style={styles.boldText}>aircraft</Text>, less unpaid interest and
                    installments more than 30 days overdue on the date of the loss or damage; or
                  </Text>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>
                    b.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    The percentage (%) of the <Text style={styles.boldText}>agreed value</Text> of the{' '}
                    <Text style={styles.boldText}>aircraft</Text> shown in Item 11 of the Coverage Identification Page under the heading
                    “Loan Balance”; or
                  </Text>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>
                    c.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    The reasonable cost of repair of the <Text style={styles.boldText}>aircraft</Text> after the{' '}
                    <Text style={styles.boldText}>aircraft</Text> has been repaired less the applicable deductible, as provided in Part Two
                    (AIRCRAFT PHYSICAL DAMAGE COVERAGE).
                  </Text>
                </View>
              </View>
            </View>
            <View style={[{ top: '-15px' }, styles.listItem]}>
              <Text style={styles.listItemHeading}>
                4.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'} When <Text style={styles.boldText}>We</Text> Will Pay
              </Text>
              <View style={styles.listItemParagraph}>
                <Text>
                  Under Paragraph 2 above, <Text style={styles.boldText}>we</Text> will pay the Lienholder within 30 days after{' '}
                  <Text style={styles.boldText}>you</Text> have given us a sworn proof of loss statement or the Lienholder has done so
                  within 90 days after receiving notice from <Text style={styles.boldText}>us</Text> of{' '}
                  <Text style={styles.boldText}>your</Text> failure to do so or the denial of <Text style={styles.boldText}>your</Text>{' '}
                  claim.
                </Text>
              </View>
            </View>
            <View style={[{ top: '-20px', marginBottom: 0 }, styles.listItem]}>
              <Text style={styles.listItemHeading}>
                5.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'} <Text style={styles.boldText}>Our</Text> Right of Recovery
              </Text>
              <View style={styles.listItemParagraph}>
                <Text>
                  If <Text style={styles.boldText}>we</Text> pay the Lienholder for any claim and deny payment to{' '}
                  <Text style={styles.boldText}>you</Text> because of any act or omission that invalidates{' '}
                  <Text style={styles.boldText}>your</Text> coverage:
                </Text>
              </View>
              <View style={styles.nestedListContainer}>
                <View>
                  <Text style={styles.listItemHeading}>
                    a.{'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                  </Text>
                  <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text style={styles.boldText}>we</Text> will take over the rights granted under{' '}
                    <Text style={styles.boldText}>your</Text> loan agreements with the Lienholder, and any property held as security for the
                    loan to the extent of <Text style={styles.boldText}>our</Text> payment; or, at <Text style={styles.boldText}>our</Text>{' '}
                    option, <Text style={styles.boldText}>we</Text> may pay the Lienholder the total amount due or to become due from{' '}
                    <Text style={styles.boldText}>you</Text> and take a full assignment and transfer of all rights of the Lienholder against{' '}
                    <Text style={styles.boldText}>you</Text> and any property held as security for the loan; and
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} form={formNumber}/>
    </Page>
    <Page size="letter" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>
                  b.{'\u00A0'}
                  {'\u00A0'}
                  {'\u00A0'}
                </Text>
                <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text style={styles.boldText}>you</Text> must reimburse us for the full amount of the payment we make pursuant to this
                  endorsement.
                </Text>
              </View>
            </View>
          </View>
          {state !== 'New Hampshire' && (
            <View style={[{ top: '-10px' }, styles.listItem]}>
              <Text style={styles.listItemHeading}>
                6.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'} What <Text style={styles.boldText}>We</Text> Will Not Pay
              </Text>
              <View style={styles.listItemParagraph}>
                <Text>
                  <Text style={styles.boldText}>We</Text> will not pay any claim under this endorsement if{' '}
                  <Text style={styles.boldText}>you</Text> or <Text style={styles.boldText}>anyone you</Text> permit to use the{' '}
                  <Text style={styles.boldText}>aircraft</Text> embezzles, converts or secretes the{' '}
                  <Text style={styles.boldText}>aircraft.</Text>
                </Text>
              </View>
            </View>
          )}
          <View style={[{ top: '-10px' }, styles.listItem]}>
            <Text style={styles.listItemHeading}>
              {state === 'New Hampshire' ? '6.' : '7.'}
              {'\u00A0'}
              {'\u00A0'}
              {'\u00A0'} Legal Action Against <Text style={styles.boldText}>Us</Text>
            </Text>
            <View style={styles.listItemParagraph}>
              <Text>
                No legal action shall be brought against <Text style={styles.boldText}>us</Text> under this endorsement until its provisions
                have been complied with fully.
              </Text>
            </View>
          </View>
          <View style={[{ top: '-10px' }, styles.listItem]}>
            <Text style={styles.listItemHeading}>
              {state === 'New Hampshire' ? '7.' : '8.'}
              {'\u00A0'}
              {'\u00A0'}
              {'\u00A0'} Cancellation Notice to Lienholder
            </Text>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> cancel <Text style={styles.boldText}>your</Text> Aircraft Physical Damage
                coverage, we will send the Lienholder 30 days prior written notice at the address shown in Item 11 of{' '}
                <Text style={styles.boldText}></Text>your Coverage Identification Page or an endorsement to the policy.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} form={formNumber}/>
    </Page>
  </>
);

export default LienHolderInterestPDF;
