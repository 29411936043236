import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15,
    marginTop: '-15px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const DamagedAircraft = ({ aircraft, formNumber }) => (
  <Page size="LETTER" style={styles.page}>
    <View style={[styles.header, { fontSize: 8 }]}>
      <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
    </View>
    <View style={styles.header}>
      <Text style={styles.boldText}>Damaged Aircraft Endorsement</Text>
    </View>
    <View style={styles.content}>
      <View>
        <Text>
          This Endorsement only applies to the following <Text style={styles.boldText}>aircraft: {aircraft?.registrationNo}</Text>
        </Text>
      </View>
      <View style={styles.centeredText}>
        <Text style={{ fontStyle: 'italic' }}>
          If no entry is made this endorsement applies to all <Text style={styles.boldText}>aircraft</Text> covered by{' '}
          <Text style={styles.boldText}>your</Text> policy.
        </Text>
      </View>
      <View>
        <Text>
          Various provisions of this endorsement extend or restrict coverage. Read <Text style={styles.boldText}>your</Text> entire policy
          to determine <Text style={styles.boldText}>your</Text> rights and obligations and what is and is not covered. This endorsement
          changes the provisions of <Text style={styles.boldText}>your</Text> policy under the following coverage parts:
        </Text>
      </View>
      <View style={styles.centeredText}>
        <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS</Text>
      </View>
      <View>
        <Text>This endorsement amends Paragraph 1.g. of Part One (General Provisions and Conditions) to read as follows:</Text>
      </View>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>g. &nbsp; &nbsp; &nbsp;</Text>
          <View style={styles.listItemParagraph}>
            <Text>
              <Text style={styles.boldText}>Agreed value</Text> means the amount of money shown in Item 5 of the Coverage Identification
              Page reduced by the amount of the cost to repair damage to the <Text style={styles.boldText}>aircraft.</Text> The coverage
              will be automatically increased, at no additional premium, by the cost of the repairs completed until the{' '}
              <Text style={styles.boldText}>agreed value</Text> has been restored or the policy expires. This is the amount{' '}
              <Text style={styles.boldText}>you</Text> and we have agreed
              <Text style={styles.boldText}>your aircraft</Text> is worth and the maximum amount of Aircraft Physical Damage coverage{' '}
              <Text style={styles.boldText}>we</Text> provide.
            </Text>
          </View>
        </View>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber} />
  </Page>
);

export default DamagedAircraft;
