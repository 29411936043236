import { Page, View, Image, StyleSheet, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import CoverImage from 'assets/images/CoverPage.png';

const styles = StyleSheet.create({
  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5
  },
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' }
  ]
});

const CoverPage = () => (
  <Page size="letter" style={styles.page}>
    <View>
      <Image src={CoverImage} />
    </View>
  </Page>
);

export default CoverPage;
