import { Grid, Typography, Button, Card, CardContent, TextField, Divider, MenuItem, InputAdornment } from '@mui/material';

import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { gridSpacing } from 'config.js';

import React, { useEffect, useState } from 'react';

const AircraftQuotePolicy = ({
  companyId,
  selectedCustomerOrg,
  selectedAgency,
  selectedAgent,
  selectedContact,
  selectedAirport = {},
  emitSelectedPolicy,
  handleNext,
  activeStep,
  isAirport = false
}) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const [policy, setPolicy] = useState({ clientNameInsured: selectedCustomerOrg.name, idCompanyUser: auth?.user?.id });
  const [insurances, setInsurances] = useState([]);
  const [users, setUsers] = useState([]);

  const handlePolicySave = async () => {
    let airportInfo = {};

    if (isAirport && selectedAirport) {
      airportInfo = {
        idAirport: selectedAirport?.id,
        airportHullAmount: policy.airportHullAmount,
        airportHullCode: policy.airportHullCode,
        airportLiabilityAmount: policy.airportLiabilityAmount,
        airportLiabilityCode: policy.airportLiabilityCode,
        airportMedicalAmount: policy.airportMedicalAmount,
        airportMedicalCode: policy.airportMedicalCode,
        airportUseAmount: policy.airportUseAmount,
        airportUseCode: policy.airportUseCode,
        airportTerritoryAmount: policy.airportTerritoryAmount,
        airportTerritoryCode: policy.airportTerritoryCode,
        airportWarTriaAmount: policy.airportWarTriaAmount,
        airportWarTriaCode: policy.airportWarTriaCode
      };
    }

    const response = await axios.post(`/api/policy/companyId/${companyId}`, {
      policy: {
        idCustomerOrganization: selectedCustomerOrg?.id,
        idAgent: selectedAgent?.id,
        idCompanyUser: policy?.idCompanyUser,
        idAgency: selectedAgency.id,
        clientNameInsured: policy.clientNameInsured,
        idPolicyType: isAirport ? 28 : 27,
        idPolicyStatus: 42,
        trainingRequired: policy.trainingRequired,
        idParentPolicy: null,
        idInsurance: policy?.idInsurance || null,
        insuranceNotes: policy?.insuranceNotes || '',
        idCustomerContact: selectedContact?.id || undefined,
        ...airportInfo
      }
    });
    emitSelectedPolicy(activeStep, response.data);
    if (!isAirport) {
      handleNext();
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setPolicy({
      ...policy,
      [name]: name.includes('training') ? (value === 'true' ? true : false) : value
    });
  };

  useEffect(() => {
    const getInsurances = async () => {
      const result = await axios.get(`/api/insurance/companyId/${companyId}`);
      setInsurances([...result.data]);
    };
    const getCompanyUserProfiles = async () => {
      const result = await axios.get(`/api/company/${companyId}/users`);
      setUsers(result.data.UserProfile);
    };

    getInsurances();
    getCompanyUserProfiles();
  }, [companyId]);

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={gridSpacing}>
            <Grid item sm zeroMinWidth>
              <Typography component="div" variant="h5">
                Client Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <Typography variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Client Name Insured"
                      name="clientNameInsured"
                      variant="outlined"
                      onChange={handleChange}
                      value={policy?.clientNameInsured || ''}
                      error={!policy?.clientNameInsured}
                      helperText={!policy?.clientNameInsured ? 'Cannot Be Empty' : ''}
                      InputProps={{
                        readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                      }}
                    />
                  </Typography>
                </Grid>
                {users.length > 0 && (
                  <Grid item lg={6} xs={12}>
                    <Typography variant="subtitle2">
                      <TextField
                        select
                        fullWidth
                        sx={{ mt: 1 }}
                        value={policy?.idCompanyUser || ''}
                        onChange={handleChange}
                        name="idCompanyUser"
                        label="Company User"
                        error={!policy?.idCompanyUser}
                        helperText={!policy?.idCompanyUser && 'Required'}
                        InputProps={{
                          readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                        }}
                      >
                        {users.map((user) => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.displayName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={gridSpacing}>
            <Grid item sm={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="div" variant="h5">
                    Insurance Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="div" variant="subtitle1">
                    Insurance : &nbsp;
                    <Typography component="span" variant="subtitle2">
                      <TextField fullWidth select value={policy?.idInsurance || ''} name="idInsurance" onChange={handleChange}>
                        {insurances.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2} />
            <Grid item sm={4} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography component="div" variant="subtitle1">
                    Insurance Notes : &nbsp;
                    <Typography component="span" variant="subtitle2">
                      <TextField
                        sx={{ mt: 1 }}
                        fullWidth
                        name="insuranceNotes"
                        multiline
                        rows={6}
                        value={policy?.insuranceNotes || ''}
                        variant="outlined"
                        InputProps={{
                          readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                        }}
                        onChange={handleChange}
                      />
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {isAirport && (
          <CardContent>
            <Grid container spacing={gridSpacing}>
              <Grid item sm zeroMinWidth>
                <Typography component="div" variant="h5">
                  Policy Options Airport
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      sx={{ mt: 1 }}
                      value={policy.airportLiabilityCode || ''}
                      label="Airport Liability"
                      name="airportLiabilityCode"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      label="Liability Amount"
                      id="filled-start-adornment1"
                      name="airportLiabilityAmount"
                      value={policy.airportLiabilityAmount || ''}
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      sx={{ mt: 1 }}
                      value={policy.airportHullCode || ''}
                      label="Airport Hull"
                      name="airportHullCode"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      label="Hull Amount"
                      id="filled-start-adornment2"
                      name="airportHullAmount"
                      value={policy.airportHullAmount || ''}
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      sx={{ mt: 1 }}
                      value={policy.airportMedicalCode || ''}
                      label="Airport Medical"
                      name="airportMedicalCode"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      label="Medical Amount"
                      id="filled-start-adornment3"
                      name="airportMedicalAmount"
                      value={policy.airportMedicalAmount || ''}
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      sx={{ mt: 1 }}
                      value={policy.airportTerritoryCode || ''}
                      label="Airport Territory"
                      name="airportTerritoryCode"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      label="Territory Amount"
                      id="filled-start-adornment4"
                      name="airportTerritoryAmount"
                      value={policy.airportTerritoryAmount || ''}
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      sx={{ mt: 1 }}
                      value={policy.airportUseCode || ''}
                      label="Aircraft Use"
                      name="airportUseCode"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      label="Use Amount"
                      id="filled-start-adornment5"
                      name="airportUseAmount"
                      value={policy.airportUseAmount || ''}
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      sx={{ mt: 1 }}
                      value={policy.airportWarTriaCode || ''}
                      label="Airport WarTria"
                      name="airportWarTriaCode"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      label="WarTria Amount"
                      id="filled-start-adornment5"
                      name="airportWarTriaAmount"
                      value={policy.airportWarTriaAmount || ''}
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        )}
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item sm={6} md={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePolicySave}
                    disabled={!policy?.clientNameInsured || !policy?.idCompanyUser || !auth.writePrivledge(auth.user, 'Quote')}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default AircraftQuotePolicy;
