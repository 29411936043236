import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },
  itemParagraph: {
    marginTop: '0px'
  },
  nestedListContainer: {
    paddingLeft: 15,
    gap: 10 // Indent nested items
  },
  nestedListItemParagraph: {
    marginLeft: 15,
    marginTop: '-11px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const GeorgiaEndorsement = ({formNumber}) => (
  <Page size="LETTER" style={styles.page}>
    <View style={[styles.header, { fontSize: 8 }]}>
      <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
    </View>
    <View style={styles.header}>
      <Text style={styles.boldText}>Georgia Change Endorsement</Text>
    </View>
    <View style={styles.content}>
      <View>
        <Text>
          This endorsement adds the following to <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS,</Text> Paragraph
          8, Canceling the Policy, to read as follows.
        </Text>
      </View>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>8. &nbsp; &nbsp; &nbsp;</Text>
          <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
            <Text>Canceling the Policy</Text>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>
              <Text style={styles.boldText}>You</Text> may cancel <Text style={styles.boldText}>your</Text> policy at any time by telling{' '}
              <Text style={styles.boldText}>us</Text> in writing in advance, through{' '}
              <Text style={styles.boldText}>our Aviation Managers,</Text> of the date <Text style={styles.boldText}>you</Text>
              want <Text style={styles.boldText}>your</Text> coverage to end. If <Text style={styles.boldText}>you</Text> cancel the policy,{' '}
              <Text style={styles.boldText}>we</Text> will return 90% of the unearned premium <Text style={styles.boldText}>you</Text> have paid.
            </Text>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>
              <Text style={styles.boldText}>We</Text> or <Text style={styles.boldText}>our Aviation Managers</Text> can cancel this policy
              as follows:
            </Text>
          </View>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text>
                  This policy can be canceled at any time for nonpayment of premium, whether payable directly to the Company, Agent or
                  indirectly under any premium finance plan or extension of credit, by mailing or delivering a notice of cancellation to the
                  first Named Insured at least 10 days before the effective date of the cancellation; or
                </Text>
              </View>
            </View>
            <View>
              <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text>
                  If this policy has been in effect less than 60 days, <Text style={styles.boldText}>we</Text> may cancel at any time by
                  mailing or delivering a notice of cancellation to the first Named Insured at least 30 days before the effective date of
                  cancellation; or
                </Text>
              </View>
            </View>
            <View>
              <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text>
                  If the policy has been in effect for 60 days or more, <Text style={styles.boldText}>we</Text> may cancel by mailing or
                  delivering a notice of cancellation to the first Named Insured at least 45 days before the effective date of cancellation.
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.listItemParagraph}>
          <Text>
            <Text style={styles.boldText}>We</Text> will return any premium <Text style={styles.boldText}>you</Text> have paid that{' '}
            <Text style={styles.boldText}>we</Text> have not earned within 10 days of the cancellation unless an audit or rate investigation
            is required in which case premium adjustment shall be made as soon as practicable but making the refund is not a condition of
            cancellation.
          </Text>
        </View>
        <View style={styles.listItemParagraph}>
          <Text>
            Any notice will be mailed or delivered to the first Named Insured at the last known address known to{' '}
            <Text style={styles.boldText}>us.</Text> If notice is mailed, proof of mailing will be sufficient proof of notice.
          </Text>
        </View>
        <View style={styles.listItemParagraph}>
          <Text>
            Proof of mailing or delivering of the notice is sufficient proof of notice. <Text style={styles.boldText}>We</Text> will compute
            the premium earned by us based on the percentage of the original policy period that <Text style={styles.boldText}>we</Text>{' '}
            provided coverage.
          </Text>
        </View>
        <View style={styles.listItemParagraph}>
          <Text>
            We will return to <Text style={styles.boldText}>you</Text> any premium that <Text style={styles.boldText}>you</Text> have paid
            that <Text style={styles.boldText}>we</Text> have not earned, but making the refund is not a condition of cancellation
          </Text>
        </View>
        <View style={styles.listItemParagraph}>
          <Text>
            However, if <Text style={styles.boldText}>we</Text> pay or have paid the <Text style={styles.boldText}>agreed value</Text> of
            the <Text style={styles.boldText}>aircraft,</Text> less any applicable deductible under the Aircraft Physical Damage coverage,{' '}
            <Text style={styles.boldText}>we</Text> are entitled to the total Aircraft Physical Damage premium shown on the Coverage
            Identification Page for the
            <Text style={styles.boldText}>aircraft</Text> on which <Text style={styles.boldText}>we</Text> made or make payment.{' '}
            <Text style={styles.boldText}>You</Text> agree to pay any premium that may be due or permit{' '}
            <Text style={styles.boldText}>us</Text> to deduct such premium from our loss payment.
          </Text>
        </View>
      </View>
      <View>
        <Text>
          This endorsement changes <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE,</Text> Paragraph 4, What{' '}
          <Text style={styles.boldText}>We</Text> Will Not Pay - f. War-Confication, to read as follows:
        </Text>
      </View>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>
                f. &nbsp; &nbsp; &nbsp; <Text style={{ fontStyle: 'italic' }}>War-Confiscation</Text>
              </Text>
              <View style={styles.listItemParagraph}>
                <Text>
                  Caused by arrest, restraint, seizure, confiscation, detention by or at the direction of any government; or caused by
                  declared or undeclared war;
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber}/>
  </Page>
);

export default GeorgiaEndorsement;
