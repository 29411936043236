import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import { FooterPDF } from 'views/PDFComponents/Footer';
const styles = StyleSheet.create({
  header: {
    fontSize: 9,
    textAlign: 'center',
    marginBottom: 10
  },
  divider: {
    borderTop: '3px solid black'
  },

  contentDivider: {
    borderTop: '1px solid black'
  },
  content: {
    fontSize: 8
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  textContent: {
    fontFamily: 'OpenSans'
  },

  listContainer: {
    paddingLeft: 10,
    marginVertical: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '10px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },

  nestedListContainer: {
    paddingLeft: 15 // Indent nested items
  },
  nestedListItemParagraph: {
    top: '-12px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' }
  ]
});

const PassengerSettlementPDF = ({formNumber}) => (
  <>
    <Page size="letter" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Passenger Settlement Endorsement</Text>
      </View>
      <View style={styles.content}>
        <View style={{ marginBottom: 20 }}>
          <Text>
            Various provisions of this endorsement extend or restrict coverage. Read <Text style={styles.boldText}>your</Text> entire policy
            to determine <Text style={styles.boldText}>your</Text> rights and obligations and what is and is not covered. This endorsement
            changes the provisions of <Text style={styles.boldText}>your</Text> policy under the following coverage parts:
          </Text>
        </View>
        <View style={[styles.header, { fontSize: 8 }]}>
          <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS</Text>
        </View>
        <View style={{ marginBottom: 5 }}>
          <Text>
            This endorsement applies to all <Text style={styles.boldText}>aircraft</Text> under <Text style={styles.boldText}>your</Text>{' '}
            policy which have coverage for <Text style={styles.boldText}>passenger bodily injury.</Text>
          </Text>
        </View>
        <View style={{ marginBottom: 5 }}>
          <Text>
            If <Text style={styles.boldText}>your</Text> policy provides coverage for{' '}
            <Text style={styles.boldText}>passenger bodily injury</Text> liability under{' '}
            <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS,</Text> this endorsement extends that coverage to include an
            option for voluntary settlement with respect to a <Text style={styles.boldText}>passenger</Text> who sustains certain{' '}
            <Text style={styles.boldText}>bodily injury</Text> as described in this endorsement even if you are not legally required to pay
            for the <Text style={styles.boldText}>bodily injury</Text> provided such voluntary settlement is made within one (1) year from
            the date of the <Text style={styles.boldText}>occurrence</Text> giving rise to such{' '}
            <Text style={styles.boldText}>bodily injury.</Text>
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>
              1.{'\u00A0'}
              {'\u00A0'}
              {'\u00A0'} <Text style={styles.boldText}>Our</Text> Offer to Pay for <Text style={styles.boldText}>Bodily Injury</Text>
            </Text>
            <View style={styles.listItemParagraph}>
              <Text>
                If an <Text style={styles.boldText}>occurrence</Text> results in <Text style={styles.boldText}>bodily injury</Text> to a{' '}
                <Text style={styles.boldText}>passenger, we</Text> will offer to pay a sum requested by{' '}
                <Text style={styles.boldText}>you</Text> in settlement of claims for such <Text style={styles.boldText}>bodily injury</Text>{' '}
                as follows:
              </Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>
                  a.{'\u00A0'}
                  {'\u00A0'}
                  {'\u00A0'}
                </Text>
                <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  If the <Text style={styles.boldText}>occurance</Text> results in the death of a{' '}
                  <Text style={styles.boldText}>passenger,</Text> the loss of any two members, or the irrecoverable paralysis of two or more
                  limbs (diplegia), <Text style={styles.boldText}>we</Text> will offer to pay up to the “Settlement Limit for Each Person.”
                </Text>
              </View>
              <View>
                <Text style={styles.listItemHeading}>
                  b.{'\u00A0'}
                  {'\u00A0'}
                  {'\u00A0'}
                </Text>
                <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  If the <Text style={styles.boldText}>occurance</Text> results in loss of any one member by a{' '}
                  <Text style={styles.boldText}>passenger, we</Text> will offer to pay up to one-half (½) of the “Settlement Limit for Each
                  Person.”
                </Text>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                As used in this endorsement, the term “Settlement Limit for Each Person” means 75% of{' '}
                <Text style={styles.boldText}>your</Text> limit of coverage for <Text style={styles.boldText}>passenger bodily injury</Text>{' '}
                stated in Item 6 of the Coverage Identification Page as applicable to the <Text style={styles.boldText}>aircraft,</Text> or
                $150,000, whichever amount is less.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                As used in this endorsement, the term “member” means a leg, arm, hand, foot, eye, hearing or speech. “Loss” as used herein
                with reference to a hand or foot means complete severance through or above the wrist or ankle joint; as used with reference
                to an eye means the irrecoverable loss of the entire sight in the eye; as used with reference to speech and hearing means
                entire and irrecoverable loss of speech or hearing.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                As used in this endorsement, the term “death” includes the disappearance of a <Text style={styles.boldText}>passenger</Text>{' '}
                where the body cannot be found within a reasonable period of time, or a maximum period of six (6) months, unless{' '}
                <Text style={styles.boldText}>we</Text> have reasonable grounds to believe that the{' '}
                <Text style={styles.boldText}>passenger’s</Text> death did not occur or did not result from an{' '}
                <Text style={styles.boldText}>occurance</Text> after examining all available evidence.
              </Text>
            </View>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>
              2.{'\u00A0'}
              {'\u00A0'}
              {'\u00A0'} What <Text style={styles.boldText}>We</Text> Will Not Cover
            </Text>
            <View style={styles.listItemParagraph}>
              <Text>
                In addition to <Text style={styles.boldText}>bodily injury</Text> otherwise excluded from coverage under{' '}
                <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS,</Text> Paragraph 4. “What Is Not Covered,” this endorsement
                does not apply to <Text style={styles.boldText}>bodily injury</Text> to a <Text style={styles.boldText}>passenger:</Text>
              </Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>
                  a.{'\u00A0'}
                  {'\u00A0'}
                  {'\u00A0'}
                </Text>
                <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  caused by suicide, attempted suicide, intentional self-injury, a criminal act, or an act while in a state of insanity or
                  intoxication; or
                </Text>
              </View>
              <View>
                <Text style={styles.listItemHeading}>
                  b.{'\u00A0'}
                  {'\u00A0'}
                  {'\u00A0'}
                </Text>
                <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  caused by disease or natural causes, or medical or surgical treatment (except where such treatment becomes necessary
                  necessary because of <Text style={styles.boldText}>bodily injury</Text> caused by an{' '}
                  <Text style={styles.boldText}>occurance</Text> within the scope of this endorsement).
                </Text>
              </View>
            </View>
            <View style={styles.listItem}>
              <Text style={styles.listItemHeading}>
                3.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'} Maximum Offer
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} form={formNumber}/>
    </Page>
    <Page size="letter" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.listItemParagraph}>
              <Text>
                The maximum amount <Text style={styles.boldText}>we</Text> will offer to pay with respect to any one injured{' '}
                <Text style={styles.boldText}>passenger</Text> in any one occurrence will not exceed the “Settlement Limit for Each Person.”
                The maximum total amount <Text style={styles.boldText}>we</Text> will offer to pay with respect to all injured
                <Text style={styles.boldText}>passengers</Text> in any one <Text style={styles.boldText}>occurance</Text> will not exceed
                the “Each Occurrence” limit for <Text style={styles.boldText}>your passenger bodily injury</Text> coverage shown in Item 6
                of your Coverage Identification Page.
              </Text>
            </View>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>
              4.{'\u00A0'}
              {'\u00A0'}
              {'\u00A0'} Release Required for Payment
            </Text>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text>
                  <Text style={styles.boldText}>We</Text> will not make a payment to or with respect to any injured{' '}
                  <Text style={styles.boldText}>passenger</Text> unless all persons having a cause of action for the
                  <Text style={styles.boldText}>passenger’s</Text> bodily injury execute a full and final release of all claims for damages
                  covered under Coverage D or DL, except for claims that <Text style={styles.boldText}>you</Text> or{' '}
                  <Text style={styles.boldText}>your</Text> worker’s compensation insurer would be liable for under any worker’s
                  compensation act law or plan. If any such person refuses to execute and deliver a full and final release within thirty
                  (30) days of a settlement offer, or commences a suit to recover damages for{' '}
                  <Text style={styles.boldText}>bodily injury</Text> sustained by a <Text style={styles.boldText}>passenger, we</Text> may
                  withdraw the settlement offer and our obligation to offer such settlement under the terms of this endorsement will no
                  longer apply.
                </Text>
              </Text>
            </View>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>
              5.{'\u00A0'}
              {'\u00A0'}
              {'\u00A0'} Payment Reduces Remaining Settlement Limit
            </Text>
            <View style={styles.listItemParagraph}>
              <Text>
                Any payment <Text style={styles.boldText}>we</Text> make under this endorsement to or with respect to an injured{' '}
                <Text style={styles.boldText}>passenger</Text> reduces the remaining Settlement Limit with respect to such{' '}
                <Text style={styles.boldText}>passenger</Text> by the amount paid.
              </Text>
            </View>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>
              6.{'\u00A0'}
              {'\u00A0'}
              {'\u00A0'} Payment Reduces <Text style={styles.boldText}>Your</Text> Coverage for Liability to Others
            </Text>
            <View style={styles.listItemParagraph}>
              <Text>
                The coverage provided by this endorsement is a part of and is not in addition to the limit of coverage otherwise provided
                under <Text style={styles.boldText}>Part Three LIABILITY TO OTHERS</Text> of <Text style={styles.boldText}>your</Text>{' '}
                policy for <Text style={styles.boldText}>passenger bodily injury.</Text> The limit of coverage available to{' '}
                <Text style={styles.boldText}>you</Text> under <Text style={styles.boldText}>Part Three LIABILITY TO OTHERS</Text> of{' '}
                <Text style={styles.boldText}>your</Text> policy for <Text style={styles.boldText}>passenger bodily injury</Text> will be
                reduced by the amount of any payment <Text style={styles.boldText}>we</Text> make pursuant to this endorsement.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} form={formNumber}/>
    </Page>
  </>
);

export default PassengerSettlementPDF;
