import { Page, View, Text, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import BeginningComponent from '../Components/BeginingComponent';
import EndingComponent from '../Components/EndingComponent';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const OklahomaEndorsement = ({formNumber, formNumber2}) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <BeginningComponent state="Oklahoma" />
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    This policy can be canceled at any time for nonpayment of premium by mailing or delivering a notice of cancellation to
                    the first Named Insured at least 10 days before the effective date of the cancellation;
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect less than 45 business days it may be canceled at any time by mailing or delivering a
                    notice of cancellation to the first Named Insured at least 30 days before the effective date of cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If this policy has been in effect 45 business days or more, it may be canceled at any time by mailing or delivering a
                    notice of cancellation to the first Named Insured at least 30 days before the effective date of cancellation and only
                    for one or more of the following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Discovery of fraud or material misrepresentation in the procurement of the insurance or with respect to any claims
                        submitted under it;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Discover of willful or reckless acts or omissions by <Text style={styles.boldText}>you</Text> that increase any
                        hazard insured against;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        The occurrence of a change in the risk that substantially increases any hazard insured against after insurance
                        coverage has been issued or renewed;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        A violation of any local fire, health, safety, building or construction regulation or ordinance with respect to any
                        covered property or its occupancy that substantially increases any hazard insured against;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(5) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        A determination by the Insurance Commissioner of the State of Oklahoma that the continuation of the policy would
                        place us in violation of the insurance laws of the State of Oklahoma;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(6) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        <Text style={styles.boldText}>Your</Text> conviction of a crime having as one of its necessary elements an act
                        increasing any hazard insured against; or
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(7) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>Loss of or substantial changes in applicable reinsurance.</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <EndingComponent extra={false} />
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> elect not to renew this policy, or alter{' '}
                <Text style={styles.boldText}>your</Text> coverage by any premium increase, change in deductible, or reduction in limits or
                coverage, <Text style={styles.boldText}>we</Text> will mail or deliver written notice of non-renewal to the first Named
                Insured at least 45 days before:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>The expiration date of the policy; or</Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    An anniversary date of this policy, if it is written for a term longer than one year or with no fixed expiration date.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.listItemParagraph}>
              <Text>
                If such notice is not mailed or delivered at least 45 days before the expiration date or an anniversary date of this policy,
                coverage will remain in effect until 45 days after notice is given. Earned premium for such extended coverage will be
                calculated pro rata based on the rates applicable to the expiring policy.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Proof of mailing or delivery of any notice is sufficient proof of notice. <Text style={styles.boldText}>We</Text> will mail
                or deliver any notice to the first Named Insured at the last known address as shown in Item 2 of the Coverage Identification
                Page. Any notice of cancellation shall state our actual reason for the action taken.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE</Text>, to add the following
            Paragraph 10, Appraisal, to <Text style={styles.boldText}>your</Text> policy:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>10. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Appraisal</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                In the event <Text style={styles.boldText}>you</Text> and <Text style={styles.boldText}>we</Text> fail to agree as to the
                actual cash value or the amount of loss, then, on the written demand of either, <Text style={styles.boldText}>you</Text> and{' '}
                <Text style={styles.boldText}>we</Text> shall each select a competent and disinterested appraiser and notify the other of
                the appraiser selected within twenty (20) days of such demand. The appraisers shall first select a competent and
                disinterested umpire; and faili_ng for fifteen (15) days to agree on such umpire, then, on{' '}
                <Text style={styles.boldText}>your</Text> request or <Text style={styles.boldText}>our</Text> request, after notice of
                hearing to the non-requesting party by certified mall, such umpire shall be selected by a judge of the district court in the
                county where the loss occurred. The appraisers shall then appraise the loss, stating separately the actual cash value and
                loss to each item, and, failing to agree, shall submit their differences only to the umpire. An award in writing, so
                itemized, of any two when filed with us shall determine the actual amount of actual cash value and loss. Each appraiser
                shall be paid by the party selecting him and the expenses of appraisal and umpire shall be paid by{' '}
                <Text style={styles.boldText}>you</Text> and <Text style={styles.boldText}>us</Text> equally.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            This endorsement changes <Text style={styles.boldText}>Part Three. Liability to Others</Text>, to add the following to the first
            paragraph of item 5. Additional Protection:
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>5. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Additional Protection</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> also provide the following additional protection with your coverage under PART THREE
                and long as we have not paid the limits of coverage that you have purchased. Payment of these costs and expenses will not
                reduce the amount of coverage you have purchased.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} form={formNumber}/>
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Oklahoma Information Notice</Text>
      </View>
      <View style={styles.content}>
        <View style={styles.header}>
          <Text style={styles.boldText}>WARNING</Text>
        </View>
        <View>
          <Text>
            Any person who knowingly, and with intent to injure, defraud or deceive any insurer, makes any claim for the proceeds of an
            insurance policy containing any false, incomplete or misleading information is guilty of a felony.
          </Text>
        </View>
      </View>
      <FooterPDF page={1} endPage={1} form={formNumber2}/>
    </Page>
  </>
);

export default OklahomaEndorsement;
